import Grid from "@mui/material/Grid";
import { useCallback, useMemo, useState } from "react";
import Mui5 from "components/mui5";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { useTranslation } from "react-i18next";

export interface WorkoutOverviewAssignmentsLabelsProps {
  labels: string[];
  maxDisplayed: number;
}

function WorkoutOverviewAssignmentsLabels(props: WorkoutOverviewAssignmentsLabelsProps) {
  const { labels, maxDisplayed } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const visibleLabels = useMemo(() => labels.slice(0, maxDisplayed), [labels, maxDisplayed]);
  const hiddenLabels = useMemo(() => labels.slice(maxDisplayed), [labels, maxDisplayed]);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const renderLabel = useCallback((label: string) => {
    return (
      <Grid key={label} item>
        <Mui5.Chip
          size="small"
          title={label}
          label={label}
          sx={{ borderRadius: "4px", bgcolor: "grey.700", color: "white" }}
        />
      </Grid>
    );
  }, []);

  return (
    <Grid container alignItems="center" spacing={0.5}>
      {visibleLabels.map(renderLabel)}
      {Boolean(hiddenLabels.length) && (
        <Grid item pl={1}>
          <Mui5.Button size="medium" variant="text" onClick={handleClick}>
            <Box component="span" color="grey.500" fontWeight="fontWeightRegular">
              {t("global.show_more_length", { length: hiddenLabels.length })}
            </Box>
          </Mui5.Button>
          <Popover
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Box minWidth={(theme) => theme.spacing(24)} maxWidth={(theme) => theme.spacing(36)} p={2}>
              <Grid container spacing="8px">
                {hiddenLabels.map(renderLabel)}
              </Grid>
            </Box>
          </Popover>
        </Grid>
      )}
    </Grid>
  );
}

export default WorkoutOverviewAssignmentsLabels;
