import MessageCard from "components/MessageCard";
import { ImportantText } from "./SetComparisonChartNoData.components";
import { Trans, useTranslation } from "react-i18next";

interface SetComparisonChartNoDataProps {}

function SetComparisonChartNoData(props: SetComparisonChartNoDataProps) {
  const { t } = useTranslation();
  return (
    <MessageCard title={t("athlete_profile_info_card.set_comparison_title")}>
      <Trans i18nKey="charting.comparison_no_data_to_display" components={[<ImportantText />, <ImportantText />]}>
        No data to display. Please check you have selected a <ImportantText>Rep Metric</ImportantText> and the{" "}
        <ImportantText>Sets</ImportantText> you want to plot.
      </Trans>
    </MessageCard>
  );
}

export default SetComparisonChartNoData;
