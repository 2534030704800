import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import MeasureSelectors from "./MeasureSelectors";
import AggregationSelector from "./AggregationSelector";
import TimeSelector from "./TimeSelector";
import Divider from "@mui/material/Divider";
import AdvancedOptions from "./AdvancedOptions";
import { ChartControlsContainer, ChartControlsInputs } from "./ChartControls.components";
import SourceSelector from "./SourceSelector";
import useChartQueryParams from "components/charting/hooks/useChartQueryParams";
import useCanDo from "hooks/useCanDo";
import { useTranslation } from "react-i18next";

interface ChartControlsProps {}

function ChartControls(props: ChartControlsProps) {
  const canViewGroups = useCanDo()("group.view");

  useChartQueryParams({ sourceEnabled: true });

  const { t } = useTranslation();

  return (
    <ChartControlsContainer>
      <ChartControlsInputs>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <Typography variant="h4">
              {t("charting.source_selector_header", {
                context: canViewGroups ? "groups" : undefined,
              })}
            </Typography>
            <SourceSelector />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">{t("global.measurements_label")}</Typography>
            <MeasureSelectors />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {t("charting.time_selector_header")}
            </Typography>
            <TimeSelector />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom>
              {t("charting.aggregate_by_header")}
            </Typography>
            <AggregationSelector />
          </Grid>
        </Grid>
      </ChartControlsInputs>
      <Divider />
      <div>
        <AdvancedOptions />
      </div>
    </ChartControlsContainer>
  );
}

export default ChartControls;
