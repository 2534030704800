import Box from "@mui/material/Box";
import { useUnitFormatter } from "hooks";
import { Exercise, Measurement } from "types";
import * as MeasurementsTableComponents from "../../MeasurementsTable.components";
import * as Components from "./MeasurementsTableRowMetrics.components";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface MeasurementsTableRowMetricsProps {
  exercise?: Exercise;
  measurement: Measurement;
}

function MeasurementsTableRowMetrics(props: MeasurementsTableRowMetricsProps) {
  const { t } = useTranslation();
  const { exercise, measurement } = props;
  const unitFormatter = useUnitFormatter();

  return (
    <Box pt={2} pb={0}>
      <Typography variant="h4" fontWeight={500}>
        {t("measurements_table_row_metrics.title")}
      </Typography>
      <Box pb={2} />
      <Components.MetricCellPaper elevation={0}>
        <Box pt={2} pb={2} pr={3}>
          <MeasurementsTableComponents.AllMetricsGrid container alignItems="center">
            {exercise?.metrics.map((metricMetadata) => {
              const value = measurement.metrics.find(
                (metricValue) => metricValue.field === metricMetadata.field
              )?.value;

              return (
                <MeasurementsTableComponents.GridItem
                  key={metricMetadata.field}
                  label={metricMetadata.name}
                  value={unitFormatter(value, metricMetadata) || "-"}
                />
              );
            })}
            <MeasurementsTableComponents.GridSpacers />
          </MeasurementsTableComponents.AllMetricsGrid>
        </Box>
      </Components.MetricCellPaper>
    </Box>
  );
}

export default MeasurementsTableRowMetrics;
