import { firstBy } from "thenby";
import type { Exercise } from "types";

class ExerciseHelpers {
  static canConfigureReps(exercise?: Exercise) {
    const is10_5_TEST = exercise?.id?.includes("10_5");

    return Boolean(!is10_5_TEST && ExerciseHelpers.isRepExercise(exercise));
  }

  static isRepExercise(exercise?: Exercise) {
    return Boolean(exercise?.repetitions?.length) || ExerciseHelpers.hasRepCountMetric(exercise);
  }

  static isVBTExercise(exercise?: Exercise) {
    return ["Barbell Velocity", "Slams, Swings & Throws"].includes(`${exercise?.category}`);
  }

  static isOneRepMaxExercise(exercise?: Exercise) {
    return Boolean(exercise?.isOneRepMaxEnabled);
  }

  static getWeightMetric(exercise?: Exercise) {
    return exercise?.metrics.find((metric) => metric.field === "weight");
  }

  static hasWeightMetric(exercise?: Exercise) {
    return Boolean(ExerciseHelpers.getWeightMetric(exercise));
  }

  static hasRepCountMetric(exercise?: Exercise) {
    return Boolean(exercise?.metrics.find((metric) => metric.field === "repCount"));
  }

  static hasVariants(exercise?: Exercise) {
    return Boolean(exercise?.variants.length);
  }

  static isPowerMetric(field?: string): boolean {
    return Boolean(field?.toUpperCase().includes("POWER"));
  }

  static velocityTargetsCalculatedByPeakOnly(exercise: Exercise) {
    return Boolean(
      [
        "BARBELL_HANG_CLEAN",
        "BARBELL_HANG_SNATCH",
        "BARBELL_POWER_CLEAN",
        "BARBELL_POWER_SNATCH",
        "FREE_VBT_OLYMPICS",
      ].includes(exercise.id) || ["Slams, Swings & Throws"].includes(exercise.category)
    );
  }

  static generateInstructions(exercise: Exercise) {
    const rowDelimiter = exercise.instructions?.indexOf("\r\n") !== -1 ? "\r\n" : "\n";

    return (exercise.instructions || "")
      .split(rowDelimiter)
      .map((line, lineIndex, baseArray) => `${baseArray.length > 1 ? `${lineIndex + 1}. ` : ""}${line}`);
  }

  static sort(exercises: Exercise[]) {
    return [...exercises].sort(firstBy("category", { ignoreCase: true }).thenBy("name"));
  }

  /**
   * @description Method gets the metric by field name or the default metric if there is no default metric it will return the first metric
   *
   */
  static getMetricByFieldNameOrDefault(exercise: Exercise, metricField: string | undefined | null) {
    const metric =
      exercise.metrics?.find((metric) => metric.field === metricField) ||
      exercise.metrics?.find((metric) => metric.isDefault) ||
      exercise.metrics[0];

    return metric;
  }

  /**
   * @description Method filters exercises by id
   */
  static filterExercisesById(exercises: Exercise[], id: Exercise["id"]) {
    return exercises.filter((exercise) => exercise.id === id);
  }

  /**
   * @description Method filters exercises by ids
   */
  static filterExercisesByIds(exercises: Exercise[], id: Exercise["id"][]) {
    return exercises.filter((exercise) => id.includes(exercise.id));
  }

  static getBodyTarget(exerciseId: string): "UPPER" | "LOWER" | null {
    const upperBodyExerciseIds: string[] = [
      "BARBELL_BENCH_PRESS",
      "BARBELL_BENCH_PULL",
      "BARBELL_BENT_OVER_ROW",
      "BARBELL_DECLINE_BENCH_PRESS",
      "BARBELL_DOUBLE_ARM_LANDMINE_PRESS",
      "BARBELL_FLOOR_PRESS",
      "BARBELL_INCLINE_BENCH_PRESS",
      "BARBELL_LANDMINE_PRESS",
      "BARBELL_OVERHEAD_PRESS",
      "BARBELL_PENDLAY_ROW",
      "BARBELL_PUSH_PRESS",
      "DUMBBELL_BENCH_PRESS",
      "DUMBBELL_DECLINE_BENCH_PRESS",
      "DUMBBELL_INCLINE_BENCH_PRESS",
      "DUMBBELL_ROW",
      "DUMBBELL_SHOULDER_PRESS",
      "DUMBBELL_SINGLE_ARM_BENCH_PRESS",
      "DUMBBELL_SINGLE_ARM_DECLINE_BENCH_PRESS",
      "DUMBBELL_SINGLE_ARM_INCLINE_BENCH_PRESS",
      "DUMBBELL_SINGLE_ARM_SHOULDER_PRESS",
    ];

    if (upperBodyExerciseIds.includes(exerciseId)) return "UPPER";

    const lowerBodyExerciseIds: string[] = [
      "BARBELL_BACK_SQUAT",
      "BARBELL_DEADLIFT",
      "BARBELL_FRONT_SQUAT",
      "BARBELL_HIP_THRUST",
      "BARBELL_LUNGE",
      "BARBELL_RFE_SPLIT_SQUAT",
      "BARBELL_ROMANIAN_DEADLIFT",
      "BARBELL_SINGLE_LEG_HIP_THRUST",
      "BARBELL_SINGLE_LEG_ROMANIAN_DEADLIFT",
      "BARBELL_SPLIT_SQUAT",
      "BARBELL_STEP_UP",
      "BARBELL_ZERCHER_SQUAT",
      "DUMBBELL_GOBLET_SQUAT",
      "FREE_VBT_DEADLIFT",
      "SAFETY_SQUAT_BAR_SQUAT",
      "TRAP_BAR_DEADLIFT",
      "BARBELL_BOX_SQUAT",
    ];

    if (lowerBodyExerciseIds.includes(exerciseId)) return "LOWER";

    return null;
  }
}

export default ExerciseHelpers;
