import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

function InputLabelWithOptional(props) {
  const { t } = useTranslation();

  return (
    <>
      {props.label}&thinsp;
      {props.optional && (
        <Box component="span" fontWeight="fontWeightLight">
          {`(${t("global.optional")})`}
        </Box>
      )}
    </>
  );
}

InputLabelWithOptional.propTypes = {
  optional: PropTypes.bool,
  label: PropTypes.node.isRequired,
};

export default InputLabelWithOptional;
