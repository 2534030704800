import LocalStorageHelper from "helpers/LocalStorageHelper";
import { createContext, useCallback, useMemo, useState } from "react";

export interface OnBoardingType {
  getOpen: (key: keyof typeof LocalStorageHelper.keys, fallbackIsOpenValue: boolean) => boolean;
  setOpen: (key: keyof typeof LocalStorageHelper.keys, isOpen: boolean) => void;
}

export const OnBoardingContext = createContext<OnBoardingType | undefined>(undefined);
OnBoardingContext.displayName = "OnBoardingContext";

interface OnBoardingProviderProps {
  children?: React.ReactNode;
}

function OnBoardingProvider(props: OnBoardingProviderProps) {
  const [onBoardingOpen, setOnBoardingOpen] = useState<{ [key: string]: boolean | undefined }>({});

  const getOpen = useCallback(
    (key: keyof typeof LocalStorageHelper.keys, fallbackIsOpenValue: boolean) => {
      return onBoardingOpen[key] ?? Boolean(LocalStorageHelper.get(key, fallbackIsOpenValue));
    },
    [onBoardingOpen]
  );

  const setOpen = useCallback((key: keyof typeof LocalStorageHelper.keys, isOpen: boolean) => {
    LocalStorageHelper.set(key, isOpen);
    setOnBoardingOpen((prev) => ({ ...prev, [key]: isOpen }));
  }, []);

  const contextValue: OnBoardingType = useMemo(() => ({ getOpen, setOpen }), [getOpen, setOpen]);

  return <OnBoardingContext.Provider value={contextValue}>{props.children}</OnBoardingContext.Provider>;
}

export default OnBoardingProvider;
