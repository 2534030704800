import Grid from "@mui/material/Grid";
import DependantFormField from "components/Form/DependantFormField";
import FormAutoComplete from "components/Form/FormAutocomplete";
import { Formik, Form, FormikContextType, FormikHelpers } from "formik";
import { Exercise, ExerciseMetric } from "types";
import RadioGroup from "components/Form/RadioGroup";
import { useFilteredExercises } from "hooks";
import useExercises from "providers/ExercisesProvider/useExercises";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import { useCallback, useMemo } from "react";
import CustomDateRange from "components/Form/CustomDateRange";
import CustomDateRangeFullWidthFix from "components/Forms/CustomDateRangeFullWidthFix";
import Button from "components/mui5/Button";
import moment from "moment";
import SetDateRangeHelper from "helpers/SetDateRangeHelper";
import { schema } from "./GroupLeaderboardModuleForm.schema";
import { RangeType, ReportModuleType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import Mui5 from "components/mui5";
import FormText from "components/Form/FormText";
import { useTranslation } from "react-i18next";

interface GroupLeaderboardModuleFormInitialValues<T = string, U = string> {
  leaderboardName: string;
  exerciseId: null | T;
  metricField: null | (T extends string ? string : ExerciseMetric);
  rangeType: RangeType;
  startDate: undefined | U;
  endDate: undefined | U;
  moduleType: ReportModuleType.LEADERBOARD;
}

interface GroupLeaderboardModuleFormProps {
  onSubmit(values: GroupLeaderboardModuleFormInitialValues): void;
  onCancel(): void;
  initialValues?: GroupLeaderboardModuleFormInitialValues;
}

function GroupLeaderboardModuleForm(props: GroupLeaderboardModuleFormProps) {
  const { onSubmit, initialValues, onCancel } = props;

  const exercises = useExercises();
  const { exerciseMetrics } = useFilteredExercises(exercises);
  const { t } = useTranslation();

  const onMetricDependencyChange = useCallback(
    ({ setFieldValue, dependencyValue }: FormikHelpers<typeof initialValues> & { dependencyValue: Exercise }) => {
      const exercise: Exercise = dependencyValue;
      const defaultMetric = exercise?.metrics.find((metric) => metric.isDefault) || null;
      return setFieldValue("metricField", defaultMetric);
    },
    []
  );

  const onCustomDateDependencyChange = useCallback(
    ({ setFieldValue }: FormikHelpers<typeof initialValues>) => setFieldValue("rangeType", RangeType.Other),
    []
  );

  const memoizedInitialValues = useMemo(() => {
    const { leaderboardName, startDate, endDate, rangeType, exerciseId, metricField } = initialValues || {};
    const exercise = exercises.find((exercise) => exercise.id === exerciseId);

    return {
      leaderboardName: leaderboardName || "",
      exerciseId: exercise || null,
      metricField: exercise?.metrics.find((metric) => metric.field === metricField) || null,
      rangeType: rangeType ?? RangeType.Month,
      startDate: startDate ? moment(startDate).startOf("day") : undefined,
      endDate: endDate ? moment(endDate).endOf("day") : undefined,
      moduleType: ReportModuleType.LEADERBOARD as const,
    };
  }, [exercises, initialValues]);

  function handleSubmit(values: GroupLeaderboardModuleFormInitialValues<Exercise, moment.Moment>) {
    const rangeType = parseInt(`${values.rangeType}`);

    return onSubmit({
      ...values,
      ...SetDateRangeHelper.calculateDateRange({
        rangeType,
        startDate: values.startDate,
        endDate: values.endDate,
      }),
      exerciseId: values.exerciseId?.id || null,
      metricField: values.metricField?.field || null,
    });
  }

  return (
    <Formik onSubmit={handleSubmit} initialValues={memoizedInitialValues} validationSchema={schema}>
      <Form>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <FormText label={t("global.leaderboard_name_label")} name="leaderboardName" variant="standard" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <FormAutoComplete
              name="exerciseId"
              options={exercises}
              groupBy={(option: Exercise) => option.category}
              getOptionLabel={(option: Exercise) => option.name || ""}
              renderInput={(params: any) => (
                <Mui5.TextField
                  {...params}
                  label={t("global.exercise_label_text")}
                  placeholder={t("global.select_exercise_placeholder")}
                  variant="standard"
                />
              )}
              isOptionEqualToValue={(option: Exercise, value: Exercise | undefined) => option.id === value?.id}
              filterOptions={exerciseAutocompleteFilterOptions}
            />
          </Grid>

          <Grid item xs={12}>
            <DependantFormField dependencyName="exerciseId" onDependencyChange={onMetricDependencyChange}>
              {({ values }: FormikContextType<GroupLeaderboardModuleFormInitialValues<Exercise>>) => {
                const options = exerciseMetrics(values.exerciseId);

                return (
                  <FormAutoComplete
                    name="metricField"
                    noOptionsText={t("workouts.no_options_select_exercise")}
                    options={options}
                    disabled={!options.length}
                    defaultValue={null}
                    getOptionLabel={(option: ExerciseMetric) => option.name || ""}
                    renderInput={(params: any) => (
                      <Mui5.TextField
                        {...params}
                        label={t("global.metric_label")}
                        placeholder={t("global.select_metric_placeholder")}
                        variant="standard"
                      />
                    )}
                    isOptionEqualToValue={(option: ExerciseMetric, value: ExerciseMetric) =>
                      option.field === value?.field
                    }
                  />
                );
              }}
            </DependantFormField>
          </Grid>

          <Grid item xs={12}>
            <RadioGroup
              label={t("global.date_range_label")}
              name="rangeType"
              color="primary"
              options={[
                { label: t("global.time_label_today"), value: RangeType.Day },
                { label: t("global.time_label_week"), value: RangeType.Week },
                { label: t("global.time_label_month"), value: RangeType.Month },
                { label: t("global.time_label_year"), value: RangeType.Year },
                {
                  label: t("global.time_label_custom"),
                  component: (
                    <CustomDateRangeFullWidthFix>
                      <DependantFormField
                        dependencyName="startDate"
                        onDependencyChange={onCustomDateDependencyChange}
                      />
                      <DependantFormField dependencyName="endDate" onDependencyChange={onCustomDateDependencyChange} />
                      <CustomDateRange startInputName="startDate" endInputName="endDate" />
                    </CustomDateRangeFullWidthFix>
                  ),
                },
              ]}
            />
          </Grid>
          <Grid item xs={8}>
            <Button disableElevation fullWidth type="submit" variant="contained" color="primary">
              {t("global.confirm_button_text")}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={onCancel}>
              {t("global.cancel_button_text")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

export default GroupLeaderboardModuleForm;
