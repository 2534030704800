import * as Yup from "yup";

export const errorMessages = {
  name: "errors.rag_missing_template_name",
  greenThreshold: "errors.rag_missing_green_threshold",
  amberThreshold: "errors.rag_missing_amber_threshold",
  firstMeasurement: "errors.rag_missing_first_measurement",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().max(100).required(errorMessages.name),
  items: Yup.array()
    .transform(([requiredFirstItem, ...items]) => [requiredFirstItem, ...items.filter(Boolean)])
    .of(
      Yup.object()
        .test("testFirstMeasurement", errorMessages.firstMeasurement, function val(value: any) {
          if (this.path !== "items[0]") {
            return true;
          }

          return this.parent.filter(Boolean).length;
        })
        .transform((value?: { category: string; id: string }) => {
          if (!value) {
            return null;
          }

          return {
            metadataType: value.category === "Wellness" ? "Wellness" : "Exercise",
            metadataId: value?.id,
          };
        })
        .nullable()
    ),
  greenThreshold: Yup.number()
    .transform((greenThreshold) => greenThreshold || null)
    .nullable()
    .required(errorMessages.greenThreshold),
  amberThreshold: Yup.number()
    .transform((amberThreshold) => amberThreshold || null)
    .nullable()
    .required(errorMessages.amberThreshold)
    .when("greenThreshold", ([greenThreshold], schema: Yup.NumberSchema) => {
      return schema.min(greenThreshold, errorMessages.amberThreshold);
    }),
});
