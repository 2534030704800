import { GridProps } from "@mui/material/Grid";

export const tableCells: { [key in "name" | "startTime" | "rpe"]: GridProps } = {
  name: { pl: 2, pr: 0, xs: true, minWidth: 200 },
  startTime: { minWidth: 78, flex: 1, maxWidth: 200 },
  rpe: { width: 64, textAlign: "left" },
};

export const columnLabels = {
  name: "global.name_text",
  startTime: "global.date_label",
  rpe: "global.rpe_text",
  duration: null,
};
