import { Formik } from "formik";
import { LeaderboardBase } from "types";
import { useMemo } from "react";
import { LeaderboardFormValues } from "../LeaderboardEditable.types";
import { leaderboardDefaults } from "../LeaderboardEditable.config";
import useGroups from "providers/GroupsProvider/useGroups";
import LeaderboardHelpers from "features/Leaderboards/helpers/LeaderboardHelpers";
import useExercises from "providers/ExercisesProvider/useExercises";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import { useTranslation } from "react-i18next";

interface LeaderboardFormikProviderProps {
  leaderboard?: LeaderboardBase;
  onSubmit(leaderboard: LeaderboardBase): void;
  children: React.ReactNode;
}

function LeaderboardFormikProvider(props: LeaderboardFormikProviderProps) {
  const { onSubmit, children, leaderboard = leaderboardDefaults } = props;
  const exercises = useExercises();
  const groups = useGroups();
  const organisation = useOrganisation();
  const { t } = useTranslation();

  const initialValues: LeaderboardFormValues = useMemo(() => {
    const formInitialValues = LeaderboardHelpers.toForm(leaderboard, { groups, exercises });
    return {
      ...formInitialValues,
      type: { label: t(formInitialValues.type.label), value: formInitialValues.type.value },
      comparePeriodType: {
        label: t(formInitialValues.comparePeriodType.label),
        value: formInitialValues.comparePeriodType.value,
      },
    };
  }, [t, exercises, groups, leaderboard]);

  async function handleSubmit(submittedLeaderboard: LeaderboardFormValues) {
    const updatedLeaderboard = LeaderboardHelpers.fromForm(submittedLeaderboard);

    return onSubmit({
      ...updatedLeaderboard,
      groupIds: organisation.featureConfig.groupsEnabled ? updatedLeaderboard.groupIds : leaderboard.groupIds,
    });
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {children}
    </Formik>
  );
}

export default LeaderboardFormikProvider;
