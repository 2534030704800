import { firstBy } from "thenby";

export const ALPHABETICAL_ASC = "ALPHABETICAL_ASC";
export const LAST_TESTED = "LAST_TESTED";
export const LAST_MONITORING_UPDATE = "LAST_MONITORING_UPDATE";
export const NEEDS_ATTENTION = "NEEDS_ATTENTION";
export const TOP_PERFORMING = "TOP_PERFORMING";

export const sortTypes = [
  {
    strategyLabelKey: "athlete.sort_strategy_alphabetical",
    strategy: ALPHABETICAL_ASC,
  },
  {
    strategyLabelKey: "athlete.sort_strategy_last_tested",
    strategy: LAST_TESTED,
  },
  {
    strategyLabelKey: "athlete.sort_strategy_last_monitoring_update",
    strategy: LAST_MONITORING_UPDATE,
  },
  {
    strategyLabelKey: "athlete.sort_strategy_needs_attention",
    strategy: NEEDS_ATTENTION,
  },
  {
    strategyLabelKey: "athlete.sort_strategy_top_performing",
    strategy: TOP_PERFORMING,
  },
];

const sortbyHasAnyScores = (athlete) =>
  athlete.ragStatuses.every((summary) => summary?.values.every(({ progressArrow }) => progressArrow === -2)) ? 0 : 1;

const lowestProgressArrowScore = (acc, value) => {
  if (value.progressArrow < acc) return value.progressArrow;
  return acc;
};

const countProgressArrows = (athlete, type) => {
  return athlete.ragStatuses.reduce((total, summary) => {
    const lowestScoreFromValues = summary?.values.reduce(lowestProgressArrowScore, 1);
    if (lowestScoreFromValues === type) {
      return total + 1;
    }

    return total;
  }, 0);
};

const getLatestMonitorUpdate = (athlete) => {
  const dateString = athlete.ragStatuses
    .map((summary) => summary?.values.map((value) => value.lastTested))
    .filter(Boolean)
    .flat()
    .sort()
    .pop();

  return dateString;
};

export const sortAthletes = (rootAthletes, activeSortingStrategy, getAthleteRagStatuses) => {
  const athletes = rootAthletes.map((athlete) => ({
    ...athlete,
    ragStatuses: getAthleteRagStatuses(athlete.id) || [],
  }));

  switch (activeSortingStrategy) {
    case LAST_TESTED:
      return athletes.sort(firstBy("lastTested", "desc").thenBy("firstName").thenBy("lastName").thenBy("id"));
    case LAST_MONITORING_UPDATE:
      return athletes.sort(firstBy(sortbyHasAnyScores, "desc").thenBy(getLatestMonitorUpdate, "desc").thenBy("id"));
    case NEEDS_ATTENTION:
      return athletes.sort(
        firstBy(sortbyHasAnyScores, "desc")
          .thenBy((athlete) => countProgressArrows(athlete, -1), "desc")
          .thenBy((athlete) => countProgressArrows(athlete, 0), "desc")
          .thenBy((athlete) => countProgressArrows(athlete, 1))
          .thenBy("firstName")
          .thenBy("lastName")
          .thenBy("id")
      );
    case TOP_PERFORMING:
      return athletes.sort(
        firstBy(sortbyHasAnyScores, "desc")
          .thenBy((athlete) => countProgressArrows(athlete, 1), "desc")
          .thenBy((athlete) => countProgressArrows(athlete, 0), "desc")
          .thenBy((athlete) => countProgressArrows(athlete, -1))
          .thenBy("firstName")
          .thenBy("lastName")
          .thenBy("id")
      );
    default:
      /**
       * @default ALPHABETICAL_ASC
       * */
      return athletes.sort(firstBy("firstName").thenBy("lastName").thenBy("id"));
  }
};
