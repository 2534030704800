import * as Yup from "yup";
import moment from "moment";

export const errorMessages = {
  customDate: "global.time_label_custom_time_error_message",
  athlete: "export.select_athlete_error_message",
  exerciseIds: "export.select_exercises_or_other_error_message",
};

export const schema = Yup.object()
  .shape({
    athleteIds: Yup.array()
      .of(Yup.string().required())
      .min(1, errorMessages.athlete)
      .required()
      .transform((options) => {
        const idsList = options.reduce((ids, option) => {
          if (Array.isArray(option.athleteIds)) {
            /**
             * This is a group, extract athleteIds
             * from group */
            return [...ids, ...option.athleteIds];
          }

          return [...ids, option.id];
        }, []);

        return [...new Set(idsList)];
      }),
    exerciseIds: Yup.array()
      .of(Yup.string().required())
      .when(["includeHeight", "includeWeight", "includeWellness"], ([includeHeight, includeWeight, includeWellness]) =>
        (!(includeHeight || includeWeight || includeWellness)
          ? Yup.array().min(1, errorMessages.exerciseIds)
          : Yup.array().min(0)
        ).transform((exercises) => exercises.map((exercise) => exercise.id))
      ),
    startDate: Yup.string()
      .nullable()
      .when("period", ([period], schema) => {
        if (period === "all") {
          return schema
            .nullable()
            .notRequired()
            .transform(() => moment("2019-01-01").startOf("day").toISOString());
        } else if (period === "custom") {
          return schema.required(errorMessages.customDate).transform((_, momentDate) => {
            return moment(momentDate).startOf("day").toISOString();
          });
        } else if (period === "day") {
          return schema
            .nullable()
            .notRequired()
            .transform(() => moment().startOf("day").toISOString());
        }
        return schema.required().transform(() => {
          return moment().startOf("day").subtract(1, period).toISOString();
        });
      }),
    endDate: Yup.string()
      .nullable()
      .when("period", ([period], schema) => {
        if (period === "custom") {
          return schema.required(errorMessages.customDate).transform((_, momentDate) => {
            return moment(momentDate).endOf("day").toISOString();
          });
        }
        return schema
          .nullable()
          .notRequired()
          .transform(() => {
            return moment().endOf("day").toISOString();
          });
      }),
  })
  .required();
