import { useTranslation } from "react-i18next";
import ModuleModalWrapper from "../../ModuleModalWrapper";
import GroupAthleteComparisonChartModuleForm from "./Forms/GroupAthleteComparisonChartModuleForm";

function ComparisonGraphModule(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal, reportSelectedGroups } = props;
  const { t } = useTranslation();

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title={t("reports.add_athlete_comparison_module_button_text")} close={closeModal}>
      <GroupAthleteComparisonChartModuleForm
        onSubmit={submitModule_V2}
        onCancel={closeModal}
        initialValues={moduleToEdit}
        groupAthleteIds={reportSelectedGroups[0] ? reportSelectedGroups[0].athleteIds : undefined}
      />
    </ModuleModalWrapper>
  );
}

export default ComparisonGraphModule;
