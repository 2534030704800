import * as Yup from "yup";
import { CoachFormValues } from "./CoachForm.types";
import CommonSchemas from "schemas/CommonSchemas";

export const errorMessages = {
  email: "errors.coach_missing_email",
  name: "errors.coach_missing_name",
  groups: {
    min: "errors.coach_min_groups",
    max: "errors.coach_max_groups",
  },
};

export const coachFormSchema = Yup.object<CoachFormValues>().shape({
  email: Yup.string().email().min(1).required(errorMessages.email),
  name: Yup.string().min(1).required(errorMessages.name),
  groups: Yup.array()
    .min(1, errorMessages.groups.min)
    .max(30, errorMessages.groups.max)
    .required(errorMessages.groups.min),
  profilePhotoName: CommonSchemas.profilePhoto,
});
