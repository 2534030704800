import { useFormikContext } from "formik";
import { Athlete } from "types/athlete";
import useAllAthletes from "hooks/useAllAthletes";
import Collapse from "@mui/material/Collapse";
import { memo, useMemo } from "react";
import * as Components from "./DuplicateAthleteAlert.components";
import { Trans } from "react-i18next";

interface DuplicateAthleteAlertProps {
  submitText: string;
}

const getFullName = (person: { firstName: string; lastName: string }) =>
  [person.firstName.trim(), person.lastName.trim()].filter(Boolean).join(" ");

function DuplicateAthleteAlert(props: DuplicateAthleteAlertProps) {
  const athletes = useAllAthletes();
  const { values } = useFormikContext<Athlete>();
  const newAthleteName = getFullName(values);
  const existingAthlete = useMemo(
    () => athletes.find((athlete) => newAthleteName === getFullName(athlete)),
    [athletes, newAthleteName]
  );
  const matchingAthleteIds = values.id === existingAthlete?.id;
  const showAlert = Boolean(!matchingAthleteIds && newAthleteName.length > 2 && existingAthlete);

  const dateOfBirth = useMemo(() => {
    if (!existingAthlete?.dateOfBirth) return null;
    return new Date(existingAthlete?.dateOfBirth).toLocaleDateString();
  }, [existingAthlete?.dateOfBirth]);

  return (
    <Collapse in={showAlert} unmountOnExit>
      <Components.StyledAlert severity="info" variant="filled">
        <Trans
          i18nKey="athlete.duplicate_athlete_alert_message"
          context={existingAthlete?.isArchived ? "archived" : undefined}
          values={{ athleteName: newAthleteName, dob: dateOfBirth }}
        >
          An athlete with the name{" "}
          <strong>
            '{newAthleteName} (Date of birth: {dateOfBirth})'
          </strong>{" "}
          already exists in your account. You can still click <strong>{props.submitText}</strong> below.
        </Trans>
      </Components.StyledAlert>
    </Collapse>
  );
}

export default memo(DuplicateAthleteAlert);
