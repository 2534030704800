import Constants from "config/Constants";
import useCanDo from "hooks/useCanDo";
import { useMemo } from "react";
import DashboardIcon from "components/Icons/DashboardIcon";
import ActivityIcon from "components/Icons/ActivityIcon";
import AthletesIcon from "components/Icons/AthletesIcon";
import LineChartIcon from "components/Icons/LineChartIcon";
import GroupsIcon from "components/Icons/GroupsIcon";
import LeaderboardsIcon from "components/Icons/LeaderboardsIcon";
import ReportsIcon from "components/Icons/ReportsIcon";
import ExercisesIcon from "components/Icons/ExercisesIcon";
import WorkoutsIcon from "components/Icons/WorkoutsIcon";
import ExportIcon from "components/Icons/ExportIcon";
import SettingsIcon from "components/Icons/SettingsIcon";
import { useTranslation } from "react-i18next";

export const useDrawerMenuItems = () => {
  const canDo = useCanDo();
  const { t } = useTranslation();

  return useMemo(
    () =>
      [
        canDo("dashboard.any") && {
          route: "/dashboards",
          title: t("pages.dashboard_title"),
          icon: <DashboardIcon />,
        },
        {
          route: "/live",
          title: t("pages.live_title"),
          icon: <ActivityIcon />,
        },
        {
          route: Constants.routes.athletes.route,
          title: t(Constants.routes.athletes.titleKey),
          icon: <AthletesIcon />,
        },
        {
          route: "/charting",
          title: t("pages.charting_title"),
          icon: <LineChartIcon />,
        },
        canDo("group.any") && {
          route: Constants.routes.groups.route,
          title: t(Constants.routes.groups.titleKey),
          icon: <GroupsIcon />,
        },
        {
          route: Constants.routes.leaderBoard.route,
          title: t(Constants.routes.leaderBoard.titleKey),
          icon: <LeaderboardsIcon />,
        },
        canDo("report.any") && {
          route: Constants.routes.reportGeneration.route,
          title: t(Constants.routes.reportGeneration.titleKey),
          icon: <ReportsIcon />,
        },
        canDo("library.any") && {
          route: Constants.routes.exerciseLibrary.route,
          title: t("library.main_menu_title"),
          icon: <ExercisesIcon />,
        },
        canDo("workout.any") && {
          route: "/workouts",
          title: t("pages.workouts_title"),
          icon: <WorkoutsIcon />,
        },
        canDo("export.any") && {
          route: Constants.routes.exportData.route,
          title: t(Constants.routes.exportData.titleKey),
          icon: <ExportIcon />,
        },
        {
          route: Constants.routes.settings.route,
          title: t(Constants.routes.settings.titleKey),
          icon: <SettingsIcon />,
        },
      ].filter(Boolean),
    [canDo, t]
  );
};
