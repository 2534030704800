import { useContext, useState } from "react";
import ConfirmationDialog from "../../../../../ConfirmationDialog";
import Box from "@mui/material/Box";
import MeasurementsContext from "contexts/MeasurementsContext";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";

function TableDeleteMeasurementButton(props) {
  const { id } = props;
  const [open, setOpen] = useState(false);
  const measurementsContextValue = useContext(MeasurementsContext);
  const { t } = useTranslation();
  if (typeof measurementsContextValue === "undefined") {
    return null;
  }

  function handleDelete() {
    const [, { deleteById }] = measurementsContextValue;
    deleteById(id);
  }

  return (
    <>
      <Box color="error.main" pt={2}>
        <Mui5.Button
          square
          size="medium"
          fullWidth={false}
          variant="outlined"
          color="error"
          onClick={() => setOpen((open) => !open)}
          aria-label={t("charting.delete_activity_aria_label")}
        >
          {t("charting.delete_activity")}
        </Mui5.Button>
      </Box>
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        title={t("charting.delete_exercise_measurement")}
        body={t("charting.info_delete_exercise_measurement")}
        cancelText={t("global.cancel_button_text")}
        confirmText={t("global.delete_button_text")}
        onConfirm={handleDelete}
        color="error"
      />
    </>
  );
}

export default TableDeleteMeasurementButton;
