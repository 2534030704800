import { TimePeriod } from "types/period";
import { AggregateByPeriod, AggregationType } from "../../components/charting/types/AggregateBy";
import { ChartingOptions } from "../../components/charting/types/ChartingOptions.types";
import moment from "moment";
import { ChartingSelections } from "../../components/charting/types/ChartingSelections.types";
import LocalStorageHelper from "helpers/LocalStorageHelper";

export const aggregationPeriod = [
  { label: "charting.chart_selections_aggregation_period_label_day", value: AggregateByPeriod.DAY },
  { label: "charting.chart_selections_aggregation_period_label_week", value: AggregateByPeriod.WEEK },
  { label: "charting.chart_selections_aggregation_period_label_month", value: AggregateByPeriod.MONTH },
  { label: "charting.chart_selections_aggregation_period_label_show_all", value: null },
];
export const aggregationType = [
  { label: "charting.chart_selections_aggregation_type_label_best", value: AggregationType.BEST },
  { label: "charting.chart_selections_aggregation_type_label_worst", value: AggregationType.WORST },
  { label: "charting.chart_selections_aggregation_type_label_average", value: AggregationType.AVERAGE },
];
export const period = [
  { label: "charting.chart_selections_period_label_today", value: TimePeriod.TODAY },
  { label: "charting.chart_selections_period_label_week", value: TimePeriod.WEEK },
  { label: "charting.chart_selections_period_label_month", value: TimePeriod.MONTH },
  { label: "charting.chart_selections_period_label_year", value: TimePeriod.YEAR },
];

export const getInitialOptions = ({ sources = [], measures = [] }: Partial<ChartingOptions>): ChartingOptions => ({
  // type: [],
  sources: [...sources],
  measures: [...measures],
  aggregationPeriod,
  aggregationType,
  period,
  showPersonalBest: false,
  showOneRepMax: false,
  showAverage: false,
  showStandardDeviation: false,
  showPlotFromZero: true,
});

export const emptyMeasure = { type: null, id: null, metadata: null, metric: null, repMetric: null };

export const getInitialSelections = (opts?: Partial<ChartingSelections>): ChartingSelections => ({
  sources: [],
  aggregationPeriod: aggregationPeriod[0],
  aggregationType: aggregationType[0],
  period: period[2],
  measures: [emptyMeasure],
  startDate: moment().endOf("day").subtract(1, period[2].value),
  endDate: moment().endOf("day"),
  showPersonalBest: LocalStorageHelper.get(LocalStorageHelper.keys.showPersonalBest, false),
  showOneRepMax: LocalStorageHelper.get(LocalStorageHelper.keys.showOneRepMax, false),
  showAverage: LocalStorageHelper.get(LocalStorageHelper.keys.showAverageValue, false),
  showStandardDeviation: LocalStorageHelper.get(LocalStorageHelper.keys.showStandardDeviation, false),
  showPlotFromZero: LocalStorageHelper.get(LocalStorageHelper.keys.beginYAxesAtZero, true),
});
