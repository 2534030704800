class GenderHelpers {
  static genderOptions = [
    {
      label: "global.gender_picker_Male",
      value: "Male",
    },
    {
      label: "global.gender_picker_Female",
      value: "Female",
    },
    {
      label: "global.gender_picker",
      value: "Prefer Not Say",
    },
  ];

  /**
   * @description
   * This is a list of gender accepted by the system
   * To be used in the validation before saving to the database
   */
  static gendersAccepted = ["Male", "Female"];

  /**
   * @description
   * To be used in the context of validation with formik since it is a required field even though it can be null
   */
  static genders = ["Male", "Female", "Prefer Not Say"];
}

export default GenderHelpers;
