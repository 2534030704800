import NotFound from "features/NotFound";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ExerciseLibraryHome = lazy(() => import("./ExerciseLibraryHome"));

function ExerciseLibrary() {
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <Suspense fallback={<div>{t("global.loading_text")}</div>}>
            <ExerciseLibraryHome />
          </Suspense>
        }
      />
      <Route path="*" element={<NotFound link="/exercise-library" buttonText={t("library.go_back404_text")} />} />
    </Routes>
  );
}

export default ExerciseLibrary;
