export const periods = [
  {
    label: "global.time_label_today",
    value: "day",
  },
  {
    label: "global.time_label_past_week",
    value: "week",
  },
  {
    label: "global.time_label_past_month",
    value: "month",
  },
  {
    label: "global.time_label_all_time",
    value: "all",
  },
];

export const defaultPeriod = {
  label: "global.time_label_past_month",
  value: "month" as const,
};

export type Period = {
  label: string;
  value: StringAutocomplete<keyof typeof periods>;
};
