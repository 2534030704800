import { initializeApp } from "firebase/app";
import { AppCheck, initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

import { getFirestore } from "firebase/firestore";
import firebaseConfig from "../firebase.config";

if (process.env.NODE_ENV === "development") {
  /**
   * @description Firebase app Debugging for development
   *
   * {@link https://firebase.google.com/docs/app-check/web/debug-provider#web}
   *
   * {@link https://console.firebase.google.com/u/0/project/output-development/appcheck/apps}
   */

  // @ts-ignore
  global.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
}

export const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

export let appCheck: AppCheck | undefined;

if (JSON.parse(process.env.REACT_APP_ENABLE_FIREBASE_APPCHECK || "false")) {
  if (!process.env.REACT_APP_FIREBASE_APPCHECK_RECAPTCHA_ID) {
    throw new Error("REACT_APP_FIREBASE_APPCHECK_RECAPTCHA_ID is not set");
  }

  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_FIREBASE_APPCHECK_RECAPTCHA_ID),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
}
