import AthleteTableHeader from "./AthleteTableHeader";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import AthleteStoreContext from "providers/AthletesProvider/contexts/AthleteStoreContext";
import Tables from "components/Tables";
import Typography from "@mui/material/Typography";
import useSearchText from "providers/SearchTextProvider/useSearchText";
import NoDataWrapper from "components/NoDataWrapper";
import useAthletesFilter from "../hooks/useAthletesFilter";
import { useCallback, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import AthleteTableRow from "./AthleteTableRow";
import VirtualTable from "components/VirtualTable";
import { Trans, useTranslation } from "react-i18next";

interface AthleteTableProps {
  TableControlsComponent: (props: any) => JSX.Element;
}

function AthleteTable(props: AthleteTableProps) {
  const { TableControlsComponent } = props;
  const athletes = useAthleteStore();
  const searchText = useSearchText();
  const parentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const noResultsMessage = searchText ? (
    <Trans i18nKey="global.no_search_results">
      There are no results for your search term{" "}
      <Typography component="span" variant="h4" fontWeight="bold">
        <>{{ searchText }}...</>
      </Typography>
    </Trans>
  ) : (
    t("athlete.table_no_results_text")
  );

  const filteredAthletes = useAthletesFilter(athletes);
  const rowVirtualizer = useVirtualizer({
    count: filteredAthletes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => 57, []),
    overscan: 15,
  });

  return (
    <AthleteStoreContext.Provider value={filteredAthletes}>
      <Tables.Components.PaperCard>
        <TableControlsComponent />
        <AthleteTableHeader />
        <Tables.Components.RowsBox>
          <NoDataWrapper
            hasData={Boolean(filteredAthletes.length)}
            fallback={<Tables.Components.Skeleton text={noResultsMessage} />}
          >
            <VirtualTable.Parent ref={parentRef}>
              <VirtualTable.Inner sx={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
                {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                  <VirtualTable.Row
                    key={virtualRow.index}
                    data-index={virtualRow.index}
                    ref={rowVirtualizer.measureElement}
                    style={{ height: `${virtualRow.size}px`, transform: `translateY(${virtualRow.start}px)` }}
                  >
                    <AthleteTableRow athlete={filteredAthletes[virtualRow.index]} />
                  </VirtualTable.Row>
                ))}
              </VirtualTable.Inner>
            </VirtualTable.Parent>
          </NoDataWrapper>
        </Tables.Components.RowsBox>
      </Tables.Components.PaperCard>
    </AthleteStoreContext.Provider>
  );
}

export default AthleteTable;
