import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InfoCard from "components/InfoCard";
import InfoCardControlDescriptor from "components/InfoCard/InfoCardControlDescriptor";
import CsvExportIcon from "components/Icons/CsvExportIcon";
import FullScreenIcon from "components/Icons/FullScreenIcon";
import ImageExportIcon from "components/Icons/ImageExportIcon";
import ChartingIcon from "components/Icons/LineChartIcon";
import { useTranslation } from "react-i18next";

export interface ChartingInfoCardProps {
  onClose?(): void;
}

function ChartingInfoCard(props: ChartingInfoCardProps) {
  const { t } = useTranslation();

  return (
    <InfoCard title={t("charting.info_card_title")} icon={<ChartingIcon htmlColor={"black"} />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h4">
            {t("charting.info_card_description_p1")} {t("charting.info_card_description_p2")}{" "}
            {t("charting.info_card_description_p3")} {t("charting.info_card_description_p4")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={0}>
          <Grid item xs={12} pb={0}>
            <InfoCardControlDescriptor
              title={t("charting.info_card_icon_title1")}
              control={<CsvExportIcon fontSize="large" />}
              label={t("charting.info_card_icon_label1")}
            />
          </Grid>
          <Grid item xs={12} pb={0}>
            <InfoCardControlDescriptor
              title={t("charting.info_card_icon_title2")}
              control={<ImageExportIcon fontSize="large" />}
              label={t("charting.info_card_icon_label2")}
            />
          </Grid>
          <Grid item xs={12} pb={0}>
            <InfoCardControlDescriptor
              title={t("charting.info_card_icon_title3")}
              control={<FullScreenIcon fontSize="large" />}
              label={t("charting.info_card_icon_label3")}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={1}>
          <Typography variant="h2">{t("charting.info_card_advanced_options_section_title")}</Typography>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("charting.advanced_options_show_personal_best_header")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("charting.info_card_icon_advanced_options_label1")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("charting.advanced_options_show1rm_line_header")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("charting.info_card_icon_advanced_options_label2")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("charting.advanced_options_show_average_value")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("charting.info_card_icon_advanced_options_label3")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("charting.advanced_options_show_standard_deviation")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("charting.info_card_icon_advanced_options_label4")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("charting.advanced_options_plot_from_zero")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("charting.info_card_icon_advanced_options_label5")}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <Grid container alignItems="center" spacing={1} p={0.5}>
            <Grid item xs={4}>
              <Typography variant="h3">{t("charting.advanced_options_aggregation_header")}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h4">{t("charting.info_card_icon_advanced_options_label6")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default ChartingInfoCard;
