import { Component, Fragment } from "react";
import closeIcon from "../../../../../assets/images/close-icon.svg";
import athleteMetrics from "../../../../../assets/images/athlete-metrics.svg";
import lineGraph from "../../../../../assets/images/line-graph.svg";
import tableView from "../../../../../assets/images/table-view-logo.svg";
import comparisonGraph from "../../../../../assets/images/comparison-graph.svg";
import ragStatus from "../../../../../assets/images/rag-status.svg";
import "./ModuleModal.scss";
import AthleteMetrics from "./AthleteMetrics";
import LineGraph from "./LineGraph";
import TableView from "./TableView";
import ComparisonGraph from "./ComparisonGraph";
import AthleteMonitoringIndicators from "./AthleteMonitoringIndicators";
import { t } from "i18next";

class ModuleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleModals: true,
      selectedModule: {
        0: false, // KPI
        1: false, // LINE GRAPH
        2: false, // TABLE VIEW
        3: false, // Athlete Monitoring
        4: false, // COMPARISON GRAPH
      },
    };
  }

  panelClick(id) {
    this.setState({
      moduleModals: false,
      selectedModule: {
        ...this.state.selectedModule,
        [id]: true,
      },
    });
  }

  handleBack() {
    this.props.onCloseModalHandler();
    this.setState({
      moduleModals: true,
      selectedModule: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
    });
  }
  componentDidMount() {
    if (this.props.isEditingModule) {
      this.panelClick(this.props.moduleToEdit.moduleType);
    }
  }

  render() {
    const { moduleModals, selectedModule } = this.state;
    return (
      <Fragment>
        <div className={`oh-report-module-modal-overlay`}>
          {moduleModals && this.props.athleteModuleReport.moduleType === null && (
            <div className="oh-leader-board-modal">
              <button type="button" className="oh-lbm-close" onClick={this.props.handleCloseModal}>
                <img src={closeIcon} alt={t("global.close_label")} />
              </button>
              <div className="oh-lbm-inner">
                <h1 className="oh-modal-title">{t("reports.add_a_new_module")}</h1>
                <div className="modules-wrapper">
                  <div id="athleteMetrics" className="module-area" onClick={() => this.panelClick(0)}>
                    <div>
                      <img src={athleteMetrics} alt={t("global.athlete_metrics_icon_alt")} />
                      <span>{t("reports.athlete_kpi_module")}</span>
                    </div>
                  </div>
                  <div id="lineGraph" className="module-area" onClick={() => this.panelClick(1)}>
                    <div>
                      <img src={lineGraph} alt={t("global.line_graph_icon_alt")} />
                      <span>{t("global.line_chart_label")}</span>
                    </div>
                  </div>
                </div>
                <div className="modules-wrapper">
                  <div id="tableView" className="module-area" onClick={() => this.panelClick(2)}>
                    <div>
                      <img src={tableView} alt={t("global.table_view_icon_alt")} />
                      <span>{t("global.measurements_label")}</span>
                    </div>
                  </div>
                  <div id="ragStatus" className="module-area" onClick={() => this.panelClick(3)}>
                    <div>
                      <img src={ragStatus} alt={t("global.table_view_icon_alt")} />
                      <span>{t("global.athlete_monitoring_label")}</span>
                    </div>
                  </div>
                </div>
                <div className="modules-wrapper">
                  <div id="comparisonGraph" className="module-area" onClick={() => this.panelClick(4)}>
                    <div>
                      <img src={comparisonGraph} alt={t("global.comparison_graph_icon_alt")} />
                      <span>{t("global.set_comparison_chart_label")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedModule[0] && (
            <AthleteMetrics
              {...this.props}
              handleCloseModal={this.props.handleCloseModal}
              handleBack={() => this.handleBack()}
              moduleType={0}
            />
          )}
          {selectedModule[1] && (
            <LineGraph
              {...this.props}
              handleCloseModal={this.props.handleCloseModal}
              handleBack={() => this.handleBack()}
              moduleType={1}
            />
          )}
          {selectedModule[2] && (
            <TableView
              {...this.props}
              handleCloseModal={this.props.handleCloseModal}
              handleBack={() => this.handleBack()}
              moduleType={2}
            />
          )}
          {selectedModule[3] && (
            <AthleteMonitoringIndicators
              {...this.props}
              handleCloseModal={this.props.handleCloseModal}
              handleBack={() => this.handleBack()}
              moduleType={3}
            />
          )}
          {selectedModule[4] && (
            <ComparisonGraph
              {...this.props}
              handleCloseModal={this.props.handleCloseModal}
              handleBack={() => this.handleBack()}
              moduleType={4}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default ModuleModal;
