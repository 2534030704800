import MessageWithLogo from "components/MessageWithLogo";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import moment from "moment";
import { useCallback, useRef } from "react";
import ActivityFeedService from "services/ActivityFeedService";
import useAllowedAthleteMeasurementFilter from "hooks/useAllowedAthleteMeasurementFilter";
import useMeasurementsFilter from "../hooks/useMeasurementsFilter";
import useMeasurementsSubscription from "../hooks/useMeasurementsSubscription";
import ShowMoreActivityProvider from "../providers/ShowMoreActivityProvider";
import useLiveActivityFeedStore from "./LiveActivityFeed.hooks";
import LiveActivityFeedVirtualTable from "./LiveActivityFeedVirtualTable";
import useExcludeArchivedExerciseItems from "hooks/useExcludeArchivedExerciseItems";
import { useTranslation } from "react-i18next";

interface LiveActivityFeedProps {
  startDate: string;
}

function LiveActivityFeed(props: LiveActivityFeedProps) {
  const organisation = useOrganisation();
  const { startDate } = props;
  const oldestCompletedDateRef = useRef<string>();
  const [activity, setUpdatedActivity] = useLiveActivityFeedStore(oldestCompletedDateRef);
  const { t } = useTranslation();

  useMeasurementsSubscription({ organisationId: organisation.id, startDate, limit: 100 }, setUpdatedActivity);

  const showMoreMeasurements = useCallback(async () => {
    const opts = {
      organisationId: organisation.id,
      endDate: moment(oldestCompletedDateRef.current).subtract(1, "millisecond").toISOString(),
      limit: 50,
    };

    try {
      const measurements = await ActivityFeedService.get(opts);

      setUpdatedActivity(measurements);
    } catch (e) {}
  }, [organisation.id, setUpdatedActivity]);

  const excludeArchivedExerciseMeasurements = useExcludeArchivedExerciseItems(activity);
  const allowedAthleteMeasurements = useAllowedAthleteMeasurementFilter(excludeArchivedExerciseMeasurements);
  const measurements = useMeasurementsFilter(allowedAthleteMeasurements);

  if (!activity.length) {
    return <MessageWithLogo>{t("info.live_no_activities")}</MessageWithLogo>;
  }

  if (activity.length && !measurements.length) {
    return <MessageWithLogo>{t("info.live_no_activities_filter")}</MessageWithLogo>;
  }

  return (
    <ShowMoreActivityProvider showMore={showMoreMeasurements}>
      <LiveActivityFeedVirtualTable measurements={measurements} />
    </ShowMoreActivityProvider>
  );
}

export default LiveActivityFeed;
