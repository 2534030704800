import IconButton from "@mui/material/IconButton";
import Tables from "components/Tables";
import Modal from "components/Modal";
import AthleteBulkEdit from "components/AthleteBulkEdit";
import OneRepMaxProvider from "providers/OneRepMaxProvider";
import { useCallback, useState } from "react";
import useMultiSelectIds from "providers/MultiSelectProvider/useMultiSelectIds";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";

interface AthleteTableControlsBulk1rmEditProps {}

function AthleteTableControlsBulk1rmEdit(props: AthleteTableControlsBulk1rmEditProps) {
  const [open, setOpen] = useState(false);
  const selectedIds = useMultiSelectIds();
  const { t } = useTranslation();

  const disableBulkEdit = Boolean(!selectedIds.length);

  const toggleOpen = useCallback(() => setOpen((prev) => !prev), []);

  return (
    <>
      <Tooltip title={t("athlete.bulk_edit1rm_tooltip_title")} arrow placement="top">
        <span>
          <IconButton size="small" color="primary" disabled={disableBulkEdit} onClick={toggleOpen}>
            <Tables.Icons.OneRepMax />
          </IconButton>
        </span>
      </Tooltip>
      <Modal open={open} toggleOpen={toggleOpen}>
        <OneRepMaxProvider>
          <AthleteBulkEdit athleteIds={selectedIds} onCancel={toggleOpen} />
        </OneRepMaxProvider>
      </Modal>
    </>
  );
}

export default AthleteTableControlsBulk1rmEdit;
