import MessageCard from "components/MessageCard";
import * as Components from "./AthleteProfilingChartNoData.components";
import { Trans, useTranslation } from "react-i18next";

interface AthleteProfilingChartNoDataProps {}

function AthleteProfilingChartNoData(props: AthleteProfilingChartNoDataProps) {
  const { t } = useTranslation();
  return (
    <MessageCard title={t("global.profiling_label")}>
      <Trans
        i18nKey="charting.profiling_no_data_to_display"
        components={[<Components.ImportantText />, <Components.ImportantText />]}
      >
        No data to display. Please check you have selected a{" "}
        <Components.ImportantText>Rep Metric</Components.ImportantText> and the{" "}
        <Components.ImportantText>Sets</Components.ImportantText> you want to plot.
      </Trans>
    </MessageCard>
  );
}

export default AthleteProfilingChartNoData;
