import { Trans, useTranslation } from "react-i18next";
import AthleteIcon from "components/Icons/AthletesIcon";
import InfoCard from "components/InfoCard";
import Grid from "@mui/material/Grid";
import InfoCardControlDescriptor from "components/InfoCard/InfoCardControlDescriptor";
import Tables from "components/Tables";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SettingsIcon from "components/Icons/SettingsIcon";
import OneRepMaxIcon from "components/Tables/icons/OneRepMaxIcon";
import NotePadIcon from "components/IconsStandardized/NotePadIcon/NotePadIcon";
import ProfilingChartIcon from "components/Icons/charts/ProfilingChartIcon";
import SetComparisonChartIcon from "components/Icons/charts/SetComparisonChartIcon";
import MeasurementsTableIcon from "components/Icons/charts/MeasurementsTableIcon";
import LineChartIcon from "components/Icons/charts/LineChartIcon";
import CalendarIcon from "components/IconsStandardized/CalendarIcon";
import Link from "@mui/material/Link";

export interface AthleteProfileInfoCardProps {
  onClose(): void;
}

function AthleteProfileInfoCard(props: AthleteProfileInfoCardProps) {
  const { t } = useTranslation();

  return (
    <InfoCard title={t("athlete_profile_info_card.title")} icon={<AthleteIcon />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h4">
            {t("athlete_profile_info_card.description1")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            control={<NotePadIcon fontSize="small" />}
            title={t("athlete_profile_info_card.edit_label")}
            label={
              <Trans i18nKey="athlete_profile_info_card.edit_description">
                Edit Athlete details by clicking the note pad icon. You can setup
                <Link
                  href="http://25970650.hs-sites-eu1.com/knowledge/how-to-set-up-an-athlete-level-login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Athlete level login
                </Link>{" "}
                here by adding an email address for the Athlete.
              </Trans>
            }
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            control={<OneRepMaxIcon fontSize="small" />}
            title={t("athlete_profile_info_card.1rm_label")}
            label={t("athlete_profile_info_card.1rm_description")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            control={<CalendarIcon />}
            title={t("athlete_profile_info_card.calendar_label")}
            label={t("athlete_profile_info_card.calendar_description")}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h2">
            {t("athlete_profile_info_card.charting_title")}
          </Typography>
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("athlete_profile_info_card.line_chart_title")}
            control={<LineChartIcon />}
            label={t("athlete_profile_info_card.line_chart_description")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("global.measurements_label")}
            control={<MeasurementsTableIcon />}
            label={t("athlete_profile_info_card.measurements_description")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("athlete_profile_info_card.set_comparison_title")}
            control={<SetComparisonChartIcon />}
            label={t("athlete_profile_info_card.set_comparison_description")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("athlete_profile_info_card.profiling_chart_title")}
            control={<ProfilingChartIcon />}
            label={
              <Trans i18nKey="athlete_profile_info_card.profiling_chart_description">
                Profile and compare an Athlete's capability across a selection of weights and sets.
                <Link
                  href="https://www.outputsports.com/blog/load-velocity-profiling"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  More Info
                </Link>
              </Trans>
            }
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("athlete_profile_info_card.view1rm_title")}
            control={<Tables.Icons.OneRepMaxNoOutline />}
            label={t("athlete_profile_info_card.view1rm_description")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("athlete_profile_info_card.settings_title")}
            control={<SettingsIcon />}
            label={t("athlete_profile_info_card.settings_description")}
          />
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default AthleteProfileInfoCard;
