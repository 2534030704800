import Athlete from "./Athlete-en.json";
import AthletesAutocomplete from "components/Inputs/Autocomplete/AthletesAutocomplete/AthletesAutocomplete.translations-en.json";
import Charting from "./Charting-en.json";
import Components from "./Components.translations-en";
import Dashboards from "features/Dashboards/Dashboards.translations-en";
import Errors from "./Errors-en.json";
import ExerciseLibrary from "./ExerciseLibrary-en.json";
import ExportData from "./ExportData-en.json";
import Global from "./Global-en.json";
import Group from "./Group-en.json";
import Leaderboards from "./Leaderboards-en.json";
import Measures from "./Measures-en.translations.json";
import NotFound from "features/NotFound/NotFound.translations-en.json";
import ReauthenticationProvider from "components/ReauthenticationProvider/ReauthenticationProvider.translations-en.json";
import SettingsConnectedAccounts from "features/Settings/SettingsConnectedAccounts/SettingsConnectedAccounts.translations-en.json";
import Workouts from "./Workouts-en.json";
import Login from "features/Login/Login-en.translations.json";
import { Resource } from "i18next";
import PredefinedVelocityTargetsOnBoardingTooltip from "features/Workouts/WorkoutForm/components/WorkoutFormSetEditor/WorkoutFormAdvancedOptions/PredefinedVelocityTargetsOnBoardingTooltip/PredefinedVelocityTargetsOnBoardingTooltip.translations-en.json";
import VelocityZoneMinMaxPresets from "features/Workouts/WorkoutForm/components/WorkoutFormSetEditor/WorkoutFormAdvancedOptions/VelocityZoneMinMaxPresets/VelocityZoneMinMaxPresets.translations-en.json";
// TODO: Remove this import after Lokalise is set up
import Translation from "./translation.json";

const en: Resource = {
  translation: Object.assign(
    {},
    Athlete,
    AthletesAutocomplete,
    Charting,
    Components,
    Dashboards,
    Errors,
    ExerciseLibrary,
    ExportData,
    Global,
    Group,
    Leaderboards,
    Login,
    Measures,
    NotFound,
    PredefinedVelocityTargetsOnBoardingTooltip,
    ReauthenticationProvider,
    SettingsConnectedAccounts,
    VelocityZoneMinMaxPresets,
    Workouts,
    Translation
  ),
};

export default en;
