import MicrosoftButton from "features/Settings/SettingsConnectedAccounts/components/MicrosoftButton";
import { useCallback, useReducer } from "react";
import Dialog from "components/Dialog";
import { Trans, useTranslation } from "react-i18next";
import Button from "components/Button";
import Span from "components/primitives/Span";
import { FirebaseError } from "firebase/app";
import ThemeProvider2024 from "components/MuiThemeProvider/ThemeProvider2024";

export interface MicrosoftLoginProps {
  onSignInWithMicrosoft: () => Promise<void>;
  setError(error: null | Error | FirebaseError): void;
}

function MicrosoftLogin(props: MicrosoftLoginProps) {
  const { t } = useTranslation();
  const { onSignInWithMicrosoft } = props;
  const [showErrorDialog, toggleShowErrorDialog] = useReducer((prev) => !prev, false);

  const handleSignInWithMicrosoft = useCallback(async () => {
    try {
      await onSignInWithMicrosoft();
    } catch (error: any) {
      /**
       * @error `"auth/admin-restricted-operation"`
       * This error happens because if a user doesn't have their account linked to Microsoft
       * firebase then trying to create an account for them.
       *
       * We are catching this error and using it for the purpose of not being allowed to login.
       */
      if (error.code === "auth/admin-restricted-operation") {
        toggleShowErrorDialog();
      } else {
        props.setError(error);
      }
    }
  }, [onSignInWithMicrosoft, props]);

  return (
    <>
      <ThemeProvider2024>
        <MicrosoftButton onClick={handleSignInWithMicrosoft}>{t("login.microsoft_button_text")}</MicrosoftButton>
      </ThemeProvider2024>
      <Dialog
        id="auth-error-dialog"
        severity="error"
        open={showErrorDialog}
        onClose={toggleShowErrorDialog}
        maxWidth={240}
        title={t("login.error_microsoft_title")}
        subTitle={t("login.error_microsoft_sub_title")}
        text={
          <Trans i18nKey="login.error_microsoft_text">
            Not bold <Span sx={{ fontWeight: 500 }}>Bold</Span>.
          </Trans>
        }
        cancelButton={
          <Button onClick={toggleShowErrorDialog} sx={{ "&.MuiButton-sizeLarge": { width: "100%" } }}>
            {t("global.ok_button_text")}
          </Button>
        }
      />
    </>
  );
}

export default MicrosoftLogin;
