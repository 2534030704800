import AthleteTableViewForm from "./Forms/AthleteTableViewForm";
import ModuleModalWrapper from "../../ModuleModalWrapper";
import { useTranslation } from "react-i18next";

function TableView(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;
  const { t } = useTranslation();

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title={t("reports.add_measurements_table_module_button_text")} close={closeModal}>
      <AthleteTableViewForm onSubmit={submitModule_V2} onCancel={closeModal} initialValues={moduleToEdit} />
    </ModuleModalWrapper>
  );
}

export default TableView;
