import ButtonGroup from "components/ButtonGroup";
import useChartOptions from "../../../hooks/useChartOptions";
import useChartSelections from "../../../../../components/charting/hooks/useChartSelections";
import useSetChartSelections from "../../../hooks/useSetChartSelections";
import { useTranslation } from "react-i18next";

interface AggregationSelectorProps {}

function AggregationSelector(props: AggregationSelectorProps) {
  const options = useChartOptions();
  const selections = useChartSelections();
  const setSelections = useSetChartSelections();
  const { t } = useTranslation();

  const translatedOptions = options.aggregationPeriod.map((option) => ({
    ...option,
    label: typeof option.label === "string" ? t(option.label) : option.label,
  }));

  return (
    <ButtonGroup
      setOption={(aggregationPeriod) => setSelections({ aggregationPeriod })}
      value={selections.aggregationPeriod}
      options={translatedOptions}
    />
  );
}

export default AggregationSelector;
