import MessageCard from "components/MessageCard";
import * as NoChartDataComp from "./NoChartData.components";
import { Box } from "@mui/material";
import useChartSelections from "components/charting/hooks/useChartSelections";
import { Trans, useTranslation } from "react-i18next";

interface NoChartDataProps {}

function NoChartData(props: NoChartDataProps) {
  const selections = useChartSelections();
  const { t } = useTranslation();

  return (
    <Box height="100%" flex={1} ml={2} mt={1.5}>
      <MessageCard title={t("charting.info_card_title")}>
        <Trans
          i18nKey="charting.no_data_message"
          context={selections.period.value || undefined}
          components={[
            <NoChartDataComp.ImportantText component="span" />,
            <NoChartDataComp.ImportantText component="span" />,
          ]}
        >
          No data to display. Please update{" "}
          <NoChartDataComp.ImportantText component="span">Measurement</NoChartDataComp.ImportantText> and{" "}
          <NoChartDataComp.ImportantText component="span">Time</NoChartDataComp.ImportantText> selections.
        </Trans>
      </MessageCard>
    </Box>
  );
}

export default NoChartData;
