import { Component, Fragment } from "react";
import closeIcon from "../../../../../assets/images/close-icon.svg";
import comparisonGraph from "../../../../../assets/images/comparison-graph.svg";
import leaderBoardIcon from "../../../../../assets/images/leaderboard-module-icon.svg";
import ragStatus from "../../../../../assets/images/rag-status.svg";

import ComparisonGraphModule from "./ComparisonGraphModule";
import AthleteMonitoringIndicators from "./AthleteMonitoringIndicators";
import LeaderBoardModule from "./LeaderBoardModule";
import "./ModuleModal.scss";
import { t } from "i18next";

class ModuleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleModals: true,
      selectedModule: {
        5: false, // COMPARISON
        6: false, // RAG
        7: false, // LEADERBOARD
      },
    };
  }

  panelClick(id) {
    this.setState({
      moduleModals: false,
      selectedModule: {
        ...this.state.selectedModule,
        [id]: true,
      },
    });
  }

  handleBack() {
    this.props.onCloseModalHandler();
    this.setState({
      moduleModals: true,
      selectedModule: {
        5: false,
        6: false,
        7: false,
      },
    });
  }
  componentDidMount() {
    if (this.props.isEditingModule) {
      this.panelClick(this.props.moduleToEdit.moduleType);
    }
  }

  render() {
    const { moduleModals, selectedModule } = this.state;
    return (
      <Fragment>
        <div className={`oh-report-module-modal-overlay`}>
          {moduleModals && (
            <div className="oh-group-report-module-modal">
              <button type="button" className="oh-lbm-close" onClick={this.props.handleCloseModal}>
                <img src={closeIcon} alt="close" />
              </button>
              <div className="oh-lbm-inner">
                <h1 className="oh-modal-title">{t("reports.add_a_new_module")}</h1>
                <div className="modules-wrapper">
                  <div id="leaderBoard" className="module-area" onClick={() => this.panelClick(7)}>
                    <div>
                      <img src={leaderBoardIcon} alt={t("global.leaderboard_label")} />
                      <span>{t("global.leaderboard_label")}</span>
                    </div>
                  </div>
                  <div id="ragStatus" className="module-area" onClick={() => this.panelClick(6)}>
                    <div>
                      <img src={ragStatus} alt={t("global.rag_status_alt")} />
                      <span>{t("global.athlete_monitoring_label")}</span>
                    </div>
                  </div>
                </div>
                <div className="modules-wrapper">
                  <div id="comparisonGraph" className="module-area" onClick={() => this.panelClick(5)}>
                    <div>
                      <img src={comparisonGraph} alt={t("global.comparison_graph_alt")} />
                      <span>{t("global.athlete_comparison_label")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedModule[7] && (
            <LeaderBoardModule
              {...this.props}
              handleCloseModal={this.props.handleCloseModal}
              handleBack={() => this.handleBack()}
              moduleType={7}
            />
          )}
          {selectedModule[6] && (
            <AthleteMonitoringIndicators
              {...this.props}
              handleCloseModal={this.props.handleCloseModal}
              handleBack={() => this.handleBack()}
              moduleType={5}
            />
          )}
          {selectedModule[5] && (
            <ComparisonGraphModule
              {...this.props}
              handleCloseModal={this.props.handleCloseModal}
              handleBack={() => this.handleBack()}
              moduleType={5}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

export default ModuleModal;
