import useMonitoringTemplates from "hooks/useMonitoringTemplates";
import { useLoadingContext } from "hooks";
import Grid from "@mui/material/Grid";
import { useMemo } from "react";
import RAGTemplate from "./RAGTemplate";
import Tables from "components/Tables";
import { useTranslation } from "react-i18next";

interface RAGTemplatesListProps {}

function RAGTemplatesList(props: RAGTemplatesListProps) {
  const [ragTemplates] = useMonitoringTemplates();
  const [loading] = useLoadingContext();
  const { t } = useTranslation();

  const templatesComponent = useMemo(
    () =>
      ragTemplates.map((template) => (
        <Grid key={template.id} item xs={12}>
          <RAGTemplate {...template} />
        </Grid>
      )),
    [ragTemplates]
  );

  if (!ragTemplates.length) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Tables.Components.Skeleton
            parentHeight={800}
            animation={loading && "wave"}
            text={t("settings.rag_create_new_template_info")}
          />
        </Grid>
      </Grid>
    );
  }

  return <Grid container>{templatesComponent}</Grid>;
}

export default RAGTemplatesList;
