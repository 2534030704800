import { LeaderboardBase } from "types";
import { LeaderboardComparePeriod, LeaderboardTimePeriod } from "./LeaderboardEditable.types";

export const maxLeaderboards = 3;

export const leaderboardTimePeriodDay = {
  label: "global.time_label_today",
  value: "Day" as const,
};
export const leaderboardTimePeriodWeek = {
  label: "global.time_label_week",
  value: "Week" as const,
};
export const leaderboardTimePeriodMonth = {
  label: "global.time_label_month",
  value: "Month" as const,
};
export const leaderboardTimePeriodAll = {
  label: "global.time_label_all_time",
  value: "All" as const,
};
export const leaderboardTimePeriods: LeaderboardTimePeriod[] = [
  leaderboardTimePeriodDay,
  leaderboardTimePeriodWeek,
  leaderboardTimePeriodMonth,
  leaderboardTimePeriodAll,
];

export const leaderboardComparePeriodWeek = {
  label: "global.time_label_past_week",
  value: "Week" as const,
};
export const leaderboardComparePeriodMonth = {
  label: "global.time_label_past_month",
  value: "Month" as const,
};
export const leaderboardComparePeriodYear = {
  label: "global.time_label_past_year",
  value: "Year" as const,
};
export const leaderboardComparePeriodAll = {
  label: "global.time_label_all_time",
  value: "All" as const,
};
export const leaderboardComparePeriodCustom = {
  label: "global.time_label_custom",
  value: "Custom" as const,
};

export const leaderboardComparePeriods: LeaderboardComparePeriod[] = [
  leaderboardComparePeriodWeek,
  leaderboardComparePeriodMonth,
  leaderboardComparePeriodYear,
  leaderboardComparePeriodAll,
  leaderboardComparePeriodCustom,
];

export const defaultLeaderboardTimePeriod = leaderboardTimePeriodDay;
export const defaultLeaderboardComparePeriod = leaderboardComparePeriodWeek;

export const leaderboardDefaults: LeaderboardBase = {
  name: "",
  exerciseId: "",
  metricId: "",
  groups: [],
  groupIds: [],
  type: defaultLeaderboardTimePeriod.value,
  endTime: null,
  variant: null,
  startTime: null,
  comparePeriodType: defaultLeaderboardComparePeriod.value,
  startDateComparePeriod: null,
};
