import PropTypes from "prop-types";
import { useUnitFormatter } from "../../../../../../hooks";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import * as Components from "./RepetitionsTable.components";
import { useTranslation } from "react-i18next";

function RepetitionsTable(props) {
  const { t } = useTranslation();
  const unitFormatter = useUnitFormatter();

  const tableHeaderContent = (
    <TableHead size="small">
      <Components.HeadTableRow>
        <Components.WordBreakTableCell width="2%" />
        {props.repetitionMetadata.map((rep) => (
          <Components.WordBreakTableCell key={rep.name} title={rep.name?.toUpperCase()} align="center">
            <Box overflow="hidden" textOverflow="ellipsis" lineHeight={1}>
              <Typography color="textSecondary" variant="caption">
                {rep.name}
              </Typography>
            </Box>
          </Components.WordBreakTableCell>
        ))}
      </Components.HeadTableRow>
    </TableHead>
  );

  function renderRepetitionMetrics(repetitionMetrics) {
    return props.repetitionMetadata.map(({ field, unitOfMeasure, displayMetadata }) => {
      const repMetric = repetitionMetrics.find((repMetric) => repMetric.field === field);

      return (
        <Components.WordBreakTableCell key={field} align="center">
          <Typography color="primary" component="span" variant="body2">
            {unitFormatter(repMetric?.value, { unitOfMeasure, displayMetadata })}
          </Typography>
        </Components.WordBreakTableCell>
      );
    });
  }

  const tableBodyContent = (
    <TableBody>
      {props.repetitions.map((repMetrics, repIndex) => (
        <Components.RootTableRow key={repIndex}>
          <Components.WordBreakTableCell width="2%">
            <Typography color="primary" component="span" variant="body2">
              {repIndex + 1}
            </Typography>
          </Components.WordBreakTableCell>
          {renderRepetitionMetrics(repMetrics)}
        </Components.RootTableRow>
      ))}
    </TableBody>
  );

  return (
    <Box pt={2}>
      <Typography variant="h4">
        <Box component="span" fontWeight={500}>
          {t("repetitions_table.rep_metrics_title")}
        </Box>
      </Typography>
      <TableContainer>
        <Table size="small" aria-label={t("charting.measurements_table_label")}>
          <TableBody>
            <Components.RootTableRow>
              <Components.WordBreakNoPaddingTableCell maxWidth={0}>
                <Table aria-label={t("charting.repetitions_table_aria_label")}>
                  {tableHeaderContent}
                  {tableBodyContent}
                </Table>
              </Components.WordBreakNoPaddingTableCell>
            </Components.RootTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

RepetitionsTable.propTypes = {
  repetitionMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  repetitions: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired
  ).isRequired,
};

export default RepetitionsTable;
