import { Component } from "react";
import PageContent from "components/PageContent";
import athleteLogo from "../../../assets/images/athlete-logo.svg";
import groupLogo from "../../../assets/images/group-logo.svg";
import "./CreateReport.scss";
import { Link } from "react-router-dom";
import Constants from "../../../config/Constants";
import Box from "@mui/material/Box";
import CanDoAction from "components/CanDoAction";
import { t } from "i18next";

class CreateReport extends Component {
  render() {
    return (
      <div className="oh-report">
        <PageContent pageHeadingLink={Constants.routes.reportGeneration.route} pageHeading={t("reports.create_report")}>
          <Box display="flex" className="create-report">
            <div className="oh-report-fixed-cta-wrapper">{t("reports.create_report_for")}</div>
          </Box>
          <Box display="flex" className="panel-wrapper" justifyContent="space-around">
            <Link className="panel" to={Constants.routes.createAthleteReport.route}>
              <div className="logo-wrapper">
                <img src={athleteLogo} alt={t("global.athlete_logo_alt")} />
              </div>
              <div className="text-wrapper">{t("global.athlete_label")}</div>
            </Link>
            <CanDoAction action="group.any">
              <Link className="panel" to={Constants.routes.createGroupReport.route}>
                <div className="logo-wrapper">
                  <img src={groupLogo} alt={t("global.group_logo_alt")} />
                </div>
                <div className="text-wrapper">{t("global.group_label")}</div>
              </Link>
            </CanDoAction>
          </Box>
        </PageContent>
      </div>
    );
  }
}

export default CreateReport;
