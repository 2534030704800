import Grid from "@mui/material/Grid";
import Tables from "components/Tables";
import AthleteTableControlSort from "../AthleteTableControlSort";
import { Divider } from "@mui/material";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import { useRequest } from "hooks";
import useAthleteActions from "providers/AthletesProvider/useAthleteActions";
import { useMultiSelectIds } from "providers/MultiSelectProvider";
import { useCallback, useMemo } from "react";
import AthleteTableControlsBulk1rmEdit from "../AthleteTableControlsBulk1rmEdit";
import useSelectedEntities from "providers/SelectedEntitiesProvider/useSelectedEntities";
import IconPopperMenu from "components/IconPopperMenu";
import AthleteTableBulkGroupAssign from "../AthleteTableBulkGroupAssign";
import CanDoAction from "components/CanDoAction/CanDoAction";
import useGroups from "providers/GroupsProvider/useGroups";
import useRole from "providers/RoleProvider/useRole";
import { Group } from "types/groups";
import { useTranslation } from "react-i18next";

interface AthleteTableIconButtonControlsProps {}

function AthleteTableIconButtonControls(props: AthleteTableIconButtonControlsProps) {
  const selectedItemIds = useMultiSelectIds();
  const athletes = useAthleteStore();
  const selectedGroups = useSelectedEntities<Group>();
  const groups = useGroups();
  const role = useRole();
  const { t } = useTranslation();

  const visibleSelectedItemIds = useMemo(
    () => athletes.filter((athlete) => selectedItemIds.includes(athlete.id)).map((athlete) => athlete.id),
    [selectedItemIds, athletes]
  );
  const athleteActions = useAthleteActions();
  const deleteAthlete = useRequest(athleteActions.delete);
  const archiveAthlete = useRequest(athleteActions.toggleArchived);
  const handleArchiveAthlete = useCallback((athleteId: string) => archiveAthlete(athleteId, true), [archiveAthlete]);

  const showBulkGroupAssignForCoach = !Boolean(role === "COACH" && groups.length <= 1);

  return (
    <Grid container height="100%" alignItems="center">
      <CanDoAction action="group.view">
        <Grid {...Tables.controlCells.standard}>
          <IconPopperMenu
            tooltipTitle={t("athlete.table_group_filter_tooltip_title")}
            icon={<Tables.Icons.Filter />}
            badgeContent={selectedGroups.length}
            popperContent={
              <Tables.Components.TableFiltersPopperContent
                SearchInputProps={{ placeholder: t("athlete.table_group_filter_tooltip_title") }}
                options={groups}
                getOptionLabel={(option) => option.name}
                getColor={(option) => option.colour}
              />
            }
          />
        </Grid>
      </CanDoAction>
      <Grid {...Tables.controlCells.standard}>
        <AthleteTableControlSort />
      </Grid>
      <Grid item width={"1px"} height="calc(100% - 30px)" pr={2}>
        <Divider orientation="vertical" />
      </Grid>
      <CanDoAction action="group.any">
        {showBulkGroupAssignForCoach && (
          <Grid {...Tables.controlCells.standard}>
            <AthleteTableBulkGroupAssign />
          </Grid>
        )}
      </CanDoAction>
      <Grid {...Tables.controlCells.standard}>
        <AthleteTableControlsBulk1rmEdit />
      </Grid>
      <Grid item width={"1px"} height="calc(100% - 30px)" pr={2}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid {...Tables.controlCells.standard}>
        <Tables.Components.BulkArchiveControl onArchive={handleArchiveAthlete} ids={visibleSelectedItemIds} />
      </Grid>
      <CanDoAction action="athlete.delete">
        <Grid {...Tables.controlCells.standard}>
          <Tables.Components.BulkDeleteControl
            onDelete={deleteAthlete}
            ids={visibleSelectedItemIds}
            tooltipTitle={t("athlete.bulk_delete_athlete_button_text")}
          />
        </Grid>
      </CanDoAction>
    </Grid>
  );
}

export default AthleteTableIconButtonControls;
