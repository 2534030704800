import Tables from "components/Tables";
import { IconButton } from "@mui/material";
import Tooltip from "components/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useCallback, useState } from "react";
import { sortTypes } from "./AthleteTableControlSort.helpers";
import ListItemText from "@mui/material/ListItemText";
import useSetTableSort from "providers/TableSortProvider/useSetTableSort";
import useTableSort from "providers/TableSortProvider/useTableSort";
import { useTranslation } from "react-i18next";

interface AthleteTableControlSortProps {}

function AthleteTableControlSort(props: AthleteTableControlSortProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const sortBy = useTableSort();
  const setSortBy = useSetTableSort();
  const { t } = useTranslation();

  const curriedHandleClick = useCallback(
    (strategy: string) => () => {
      setSortBy({ key: strategy, direction: "asc" });
    },
    [setSortBy]
  );

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={t("athlete.sort_table_control_tooltip_title")} arrow placement="top">
        <span>
          <IconButton
            color="primary"
            onClick={handleOpen}
            size="small"
            aria-label={t("athlete.sort_table_control_tooltip_title")}
            aria-controls={open ? "sort-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Tables.Icons.Sort />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="sort-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {sortTypes.map(({ strategy, strategyLabelKey: labelKey }) => (
          <MenuItem key={strategy} selected={sortBy.key === strategy} onClick={curriedHandleClick(strategy)}>
            <ListItemText primary={t(labelKey)} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default AthleteTableControlSort;
