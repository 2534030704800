import Box from "@mui/material/Box";
import * as Components from "./LeaderboardEditableFullscreen.components";
import outputLogoFull from "assets/images/Logo-reversed.svg";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

interface LeaderboardEditableFullscreenProps {
  children?: React.ReactNode;
}

function LeaderboardEditableFullscreen(props: LeaderboardEditableFullscreenProps) {
  const { t } = useTranslation();
  return (
    <>
      <Components.Container>
        <Box display="flex" flexDirection="column" height="100%" maxWidth={900} margin="0 auto">
          <Box flex={1} minHeight={0}>
            {props.children}
          </Box>
          <Box display="flex" justifyContent="center" pt="24px">
            <Grid container spacing="8px" justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="h4">{t("global.powered_by")}</Typography>
              </Grid>
              <Grid item>
                <img src={outputLogoFull} alt={t("global.logo_stripes_alt_text")} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Components.Container>
    </>
  );
}

export default LeaderboardEditableFullscreen;
