import { GridProps } from "@mui/material/Grid";

export const tableCells: { [id: string]: GridProps } = {
  checkbox: { width: 16, textAlign: "center" },
  name: { xs: true, pl: 2 },
  actions: { minWidth: "96px", pr: "3px" /* 5px scrollbar + 3px padding = theme.spacing(1) */ },
};

export const columnLabels = {
  checkbox: null,
  name: "global.name_text",
  actions: null,
};
