import * as Yup from "yup";

export const errorMessages = {
  size: "errors.mfa_sms_code_size",
  codeRequired: "errors.mfa_sms_code_required",
};

const SmsCodeSchema = Yup.object()
  .shape({
    code: Yup.string().length(6, errorMessages.size).required(errorMessages.codeRequired),
  })
  .required();
export default SmsCodeSchema;
