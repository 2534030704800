import axios from "axios";

class HttpHelpers {
  static async onError(error: unknown): Promise<void> {
    // @ts-ignore
    console.log(error.message);
    if (axios.isAxiosError(error)) {
      const customErrorMessages = {
        409: "edit_athlete.email_address_conflict",
      };
      const errorCode = error?.response?.status as keyof typeof customErrorMessages;
      error.message = customErrorMessages[errorCode] ?? error.message;
    }
    throw error;
  }
}

export default HttpHelpers;
