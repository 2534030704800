import * as Yup from "yup";

export const errorMessages = {
  password: "errors.password_missing",
  passwordTooShort: "errors.password_too_short",
  passwordTooLong: "errors.password_too_long",
};

export const PasswordInputSchema = Yup.object().shape({
  password: Yup.string()
    .max(50, errorMessages.passwordTooLong)
    .min(6, errorMessages.passwordTooShort)
    .required(errorMessages.password),
});
