import { useState } from "react";
import { ScrollableTable, ShowMoreButton } from "./AthleteProfileMeasurementsTable.components";
import AthleteProfileMeasurementsTableRows from "components/Chart/ChartRenderer/TableView/AthleteProfileMeasurementsTableRows";
import MeasurementsProvider from "providers/MeasurementsProvider";
import Box from "@mui/material/Box";
import useChartSelections from "components/charting/hooks/useChartSelections";
import NoDataWrapper from "components/NoDataWrapper";
import AthleteProfileMeasurementsNoData from "./AthleteProfileMeasurementsNoData";
import useAthleteMeasurementsApi from "./useAthleteMeasurementsApi";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";

interface AthleteProfileMeasurementsTableProps {}

function AthleteProfileMeasurementsTable(props: AthleteProfileMeasurementsTableProps) {
  const { t } = useTranslation();
  const [prevEndDate, setPrevEndDate] = useState<string | undefined>();
  const selections = useChartSelections();
  const athleteId = selections.sources[0]?.id;
  const [measurements, actions] = useAthleteMeasurementsApi({
    athleteId,
    measure: selections.measures[0],
  });

  const oldestMeasurementCompletedDate = [...measurements].pop()?.completedDate;

  function handleShowMore() {
    setPrevEndDate(oldestMeasurementCompletedDate);
    actions.getMeasurementsUpToEndDate(athleteId, {
      endDate: oldestMeasurementCompletedDate,
      measure: selections.measures[0],
    });
  }

  return (
    <NoDataWrapper hasData={Boolean(measurements.length)} fallback={<AthleteProfileMeasurementsNoData />}>
      <MeasurementsProvider deleteById={actions.deleteMeasurementById}>
        <ScrollableTable>
          <AthleteProfileMeasurementsTableRows measurements={measurements} />
          <Mui5.Paper elevation={0} square>
            <Box textAlign="center">
              <ShowMoreButton disabled={prevEndDate === oldestMeasurementCompletedDate} onClick={handleShowMore}>
                {t("athlete_profile_measurements_table.show_more_button_text")}
              </ShowMoreButton>
            </Box>
          </Mui5.Paper>
        </ScrollableTable>
      </MeasurementsProvider>
    </NoDataWrapper>
  );
}

export default AthleteProfileMeasurementsTable;
