import Tables from "..";
import Tooltip from "components/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmationDialog, { ConfirmationDialogProps } from "components/ConfirmationDialog";
import { useTranslation } from "react-i18next";
import useAthleteLimits from "hooks/useAthleteLimits";
import AthleteArchiveDisabledAlert from "components/AthleteHub/components/AthleteArchiveDisabledAlert";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";

interface BulkArchiveControlProps {
  ids: string[];
  confirmDialogTitle?: string;
  onArchive(id: string): PromiseLike<any>;
}

function BulkArchiveControl(props: BulkArchiveControlProps) {
  const { onArchive, ids } = props;
  const [dialogProps, setDialogProps] = useState<Omit<ConfirmationDialogProps, "open" | "setOpen"> | false>(false);
  const { t } = useTranslation();
  const athleteLimits = useAthleteLimits();
  const organisationActions = useOrganisationActions();

  useEffect(() => {
    organisationActions.get();
  }, [organisationActions]);

  const handleClose = useCallback(() => {
    setDialogProps(false);
  }, []);

  const handleArchive = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      handleClose();

      setDialogProps({
        title: t("athlete.archive_dialog_title"),
        cancelText: t("global.cancel_button_text"),
        confirmText: t("athlete.archive_dialog_confirm_text"),
        color: "error",
        body: [
          t("athlete.archive_dialog_body_text1", { count: ids.length }),
          t("athlete.archive_dialog_body_text2"),
        ].join(" "),
        onConfirm: () => Promise.allSettled(ids.map((id) => onArchive(id))),
      });
    },
    [handleClose, ids, onArchive, t]
  );

  const confirmationDialog = Boolean(dialogProps) && (
    <ConfirmationDialog open={Boolean(dialogProps)} setOpen={setDialogProps} {...dialogProps} />
  );

  const archiveLimitHit = useMemo(
    () => ids.length + athleteLimits.archived.count > athleteLimits.archived.limit,
    [athleteLimits.archived.count, athleteLimits.archived.limit, ids.length]
  );
  const disableBulkArchive = useMemo(() => ids.length === 0 || archiveLimitHit, [archiveLimitHit, ids.length]);

  return (
    <>
      <Tooltip
        title={archiveLimitHit ? <AthleteArchiveDisabledAlert /> : t("athlete.archive_tooltip_title")}
        severity={archiveLimitHit ? "warning" : undefined}
        arrow
        placement="top"
      >
        <span>
          <IconButton
            color="primary"
            size="small"
            onClick={handleArchive}
            disabled={disableBulkArchive}
            aria-label={t("athlete.bulk_archive_multiple_aria_label")}
          >
            <Tables.Icons.Archive />
          </IconButton>
        </span>
      </Tooltip>
      {confirmationDialog}
    </>
  );
}

export default BulkArchiveControl;
