import Grid from "@mui/material/Grid";
import Mui5 from "components/mui5";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { GridBorderBottomGrey } from "../../Settings.components";
import { useEffect, useState } from "react";
import SubscriptionService from "services/SubscriptionService";
import { useTranslation } from "react-i18next";

interface SettingsSubscriptionManagementProps {}

function SettingsSubscriptionManagement(props: SettingsSubscriptionManagementProps) {
  const [subMgmtUrl, setSubMgmtUrl] = useState<string>();
  const { t } = useTranslation();

  useEffect(() => {
    SubscriptionService.getManagementUrl().then(setSubMgmtUrl);
  }, []);

  if (typeof subMgmtUrl === "undefined") {
    return null;
  }

  return (
    <GridBorderBottomGrey item xs={12}>
      <Box p={6}>
        <Box pb={4}>
          <Typography variant="h1">
            <Box component="span" fontWeight="fontWeightMedium">
              {t("settings.subscription_management")}
            </Box>
          </Typography>
        </Box>
        <Grid container spacing="24px" maxWidth={400}>
          <Grid item xs={12}>
            <Mui5.Button
              LinkComponent="a"
              href={subMgmtUrl}
              // @ts-ignore
              rel="noopener noreferrer"
              // @ts-ignore
              target="_blank"
              color="primary"
              variant="contained"
              fullWidth
            >
              {t("settings.manage_my_subscription")}
            </Mui5.Button>
          </Grid>
        </Grid>
      </Box>
    </GridBorderBottomGrey>
  );
}

export default SettingsSubscriptionManagement;
