import { Form, Formik } from "formik";
import useMonitoringTemplates from "hooks/useMonitoringTemplates";
import usePreferences from "hooks/usePreferences";
import Button from "components/mui5/Button";
import Grid from "@mui/material/Grid";
import FormAutoComplete from "components/Form/FormAutocomplete";
import { RAGSettings } from "contexts/RAGSettingsContext/RAGSettingsContext";
import { useMemo } from "react";
import { schema } from "./AthleteMonitoringIndicatorsForm.schema";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";

interface AthleteMonitoringIndicatorsFormProps {
  onSubmit(values: { ragStatusTemplateId: string | null; moduleType: 3 | 6 }): void;
  onCancel(): void;
  moduleType: 3 | 6;
  initialValues?: { ragStatusTemplateId: string | null };
}

function AthleteMonitoringIndicatorsForm(props: AthleteMonitoringIndicatorsFormProps) {
  const { onSubmit, initialValues, onCancel, moduleType = 3 } = props;
  const [templates] = useMonitoringTemplates();
  const [{ defaultRagStatusTemplateId }] = usePreferences();
  const { t } = useTranslation();

  function handleSubmit(values: { ragStatusTemplate: RAGSettings | null }) {
    return onSubmit({
      moduleType,
      ragStatusTemplateId: values.ragStatusTemplate?.id || null,
    });
  }

  const selectedTemplate = useMemo(
    () =>
      templates.find(({ id }) => id === initialValues?.ragStatusTemplateId) ||
      templates.find(({ id }) => id === defaultRagStatusTemplateId) ||
      null,
    [defaultRagStatusTemplateId, initialValues?.ragStatusTemplateId, templates]
  );

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ ragStatusTemplate: selectedTemplate }} validationSchema={schema}>
      <Form>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <FormAutoComplete
              name="ragStatusTemplate"
              options={templates}
              getOptionLabel={(option: RAGSettings) => option.name}
              renderInput={(params: any) => (
                <Mui5.TextField {...params} label={t("reports.athlete_monitoring_template_label")} variant="standard" />
              )}
            />
          </Grid>
          <Grid item xs={8}>
            <Button disableElevation fullWidth type="submit" variant="contained" color="primary">
              {t("global.confirm_button_text")}
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={onCancel}>
              {t("global.cancel_button_text")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}

export default AthleteMonitoringIndicatorsForm;
