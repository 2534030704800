import Mui5 from "components/mui5";
import Grid from "@mui/material/Grid";
import Tables from "components/Tables";
import { tableCells } from "../CompletedWorkoutsTable.config";
import { CompletedWorkout } from "features/Workouts/Workouts.types";
import AthleteName from "components/AthleteName";
import Typography from "@mui/material/Typography";
import moment from "moment";
import useAthlete from "hooks/useAthlete";
import ActiveIndicator from "components/ActiveIndicator";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function CompletedWorkoutsTableRow(props: CompletedWorkout) {
  const { id: completedWorkoutId } = props;
  const athlete = useAthlete(props.athleteId);
  const { t } = useTranslation();
  const startTime = useMemo(() => moment(props.startTime), [props.startTime]);
  const lastUpdated = useMemo(() => moment(props.lastUpdated || startTime), [props.lastUpdated, startTime]);

  const isWorkoutActive = useMemo(() => {
    if (props.endTime) return false;
    return moment().diff(lastUpdated, "minutes") < 30;
  }, [lastUpdated, props.endTime]);

  const startTimeString = startTime.format("D MMM YYYY");

  return (
    <Mui5.Button size="small" fullWidth variant="text" href={`/workouts/completed/${completedWorkoutId}`}>
      <Grid
        width="100%"
        container
        component={Tables.Components.TableRow}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item {...tableCells.name}>
          <ActiveIndicator
            title={t("workouts.completed_workout_last_completed", { lastUpdatedAt: lastUpdated?.fromNow(false) })}
            color="secondary"
            overlap="rectangular"
            variant={isWorkoutActive ? "dot" : undefined}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <AthleteName athlete={athlete} id={props.athleteId} />
          </ActiveIndicator>
        </Grid>
        <Grid item {...tableCells.startTime} minWidth={0}>
          <Typography variant="body1" noWrap title={startTimeString}>
            {startTimeString}
          </Typography>
        </Grid>
        <Grid item {...tableCells.rpe}>
          {props.rpe ? t("workouts.rpe_out_of_ten", { rpe: props.rpe }) : "-"}
        </Grid>
      </Grid>
    </Mui5.Button>
  );
}

export default CompletedWorkoutsTableRow;
