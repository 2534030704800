import { memo, type MouseEvent } from "react";
import { styled } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import type { DialogTitleProps as MuiDialogTitleProps } from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const DialogTitleBase = styled(MuiDialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 2),
  fontSize: theme.typography.body2.fontSize,
}));

interface DialogTitleProps {
  title: React.ReactNode;
  onClose?: (event: MouseEvent) => void;
  sx?: MuiDialogTitleProps["sx"];
}

function DialogTitle(props: DialogTitleProps) {
  const { title, onClose, sx } = props;
  const { t } = useTranslation();
  return (
    <DialogTitleBase sx={sx}>
      <Grid container alignItems="center">
        <Grid item xs zeroMinWidth>
          {title}
        </Grid>
        {onClose && (
          <Grid item lineHeight={0} ml={1}>
            <IconButton size="small" aria-label={t("global.close_label")} onClick={onClose}>
              <CloseIcon htmlColor="#FFF" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </DialogTitleBase>
  );
}
export default memo(DialogTitle);
