import * as Yup from "yup";
import { FormOneRepMax } from "components/AthleteBulkEdit/hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";

type AthleteProfile1RMEditFormValues = {
  selectedOneRepMaxes: FormOneRepMax[];
  imperialSystemEnabled: boolean;
};

const errorMessages = {
  weight: {
    imperial: {
      positive: "athlete_profile1rm_edit.form_weight_error_positive_imperial",
      max: "athlete_profile1rm_edit.form_weight_error_max_imperial",
    },
    metric: {
      positive: "athlete_profile1rm_edit.form_weight_error_positive_metric",
      max: "athlete_profile1rm_edit.form_weight_error_max_metric",
    },
  },
};

export const generateAthleteProfile1RMEditSchema = (imperialSystemEnabled: boolean) => {
  const yupWWeightNumber = imperialSystemEnabled
    ? Yup.number()
        .transform((v) => (Number.isNaN(v) ? null : v))
        .positive(errorMessages.weight.imperial.positive)
        .max(1350, errorMessages.weight.imperial.max)
        .nullable()
    : Yup.number()
        .transform((v) => (Number.isNaN(v) ? null : v))
        .positive(errorMessages.weight.metric.positive)
        .max(600, errorMessages.weight.metric.max)
        .nullable();

  return Yup.object<AthleteProfile1RMEditFormValues>().shape({
    selectedOneRepMaxes: Yup.array().of(
      Yup.object().shape({
        oneRepMaxes: Yup.array().of(
          Yup.object().shape({
            weight: yupWWeightNumber,
          })
        ),
      })
    ),
  });
};
