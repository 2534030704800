import * as Yup from "yup";

export const errors = {
  email: "errors.email_invalid",
  password: "errors.missing_password",
  passwordTooShort: "errors.password_too_short",
  passwordTooLong: "errors.password_too_long",
};

export const schema = Yup.object().shape({
  email: Yup.string().email(errors.email).required(errors.email),
  password: Yup.string().max(50, errors.passwordTooLong).min(6, errors.passwordTooShort).required(errors.password),
});
