import AthleteSetComparisonChartModuleForm from "./Forms/AthleteSetComparisonChartModuleForm";
import ModuleModalWrapper from "../../ModuleModalWrapper";
import { useTranslation } from "react-i18next";

function ComparisonGraph(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal, reportAthletes } = props;
  const { t } = useTranslation();

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title={t("reports.add_set_comparison_chart_module_button_text")} close={closeModal}>
      <AthleteSetComparisonChartModuleForm
        athleteId={reportAthletes[0] && reportAthletes[0].id}
        onSubmit={submitModule_V2}
        onCancel={closeModal}
        initialValues={moduleToEdit}
      />
    </ModuleModalWrapper>
  );
}

export default ComparisonGraph;
