import http from "../../axiosSetup";
import moment from "moment";
import WeightHelpers from "helpers/WeightHelpers";

class SetComparisonChartService {
  /**
   * @param {string} athleteId
   * @param {{ exerciseId: string }} options
   * @returns {Promise<{
   *   completedDate: string;
   *   measurementId: string;
   *   repetitionCount: number;
   *   setNumber: number;
   *   variant: null | "Left" | "Right";
   *   weightValue: number;
   * }[]>}
   */
  static getSets(athleteId, options) {
    return http
      .post(`/measurements/athlete-exercise/series`, {
        athleteId,
        exerciseId: options.exerciseId,
      })
      .then((response) => response.data);
  }

  static makeLabel(set, isImperial, t) {
    const weight = WeightHelpers.toReadable(set.weightValue, isImperial);

    return [
      t("set_selector.set_legend_set", { set: set.setNumber }),
      t("set_selector.set_legend_reps", { count: set.repetitionCount, variant: set.variant }),
      t("set_selector.set_legend_weight", { weight }),
      moment(set.completedDate).format(t("charting.set_legend_date_format")),
    ]
      .filter(Boolean)
      .join(" / ");
  }
}
export default SetComparisonChartService;
