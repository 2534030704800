import { useCallback, useMemo, useState } from "react";
import { AutocompleteChangeReason, FilterOptionsState, useAutocomplete } from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import IconPopperMenuSearchInput from "components/IconPopperMenu/IconPopperMenuSearchInput";
import IconPopperMenuListbox from "components/IconPopperMenu/IconPopperMenuListbox";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import difference from "lodash/difference";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import StringHelpers from "helpers/StringHelpers";
import EventHelpers from "helpers/EventHelpers";
import { WorkoutLabelOption } from "features/Workouts/Workouts.types";
import useWorkoutLabelOptions from "features/Workouts/providers/WorkoutLabelOptionsProvider/useWorkoutLabelOptions";
import { firstBy } from "thenby";

type WorkoutLabelAssignOption = {
  inputValue?: string;
} & WorkoutLabelOption;

interface TableWorkoutLabelAssignPopperContentProps {
  commonLabels: string[];
  onChange(label: string, isAssignment: boolean): void;
}

const filter = createFilterOptions<WorkoutLabelAssignOption>();

function TableWorkoutLabelAssignPopperContent(props: TableWorkoutLabelAssignPopperContentProps) {
  const { onChange } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>("");
  const workoutLabelOptions = useWorkoutLabelOptions();

  const sortedWorkoutLabelOptions: WorkoutLabelAssignOption[] = useMemo(
    () =>
      workoutLabelOptions.sort(
        firstBy((option: WorkoutLabelAssignOption) => option.count, "desc").thenBy((option) => option.name, "asc")
      ),
    [workoutLabelOptions]
  );

  const commonWorkoutLabelOptions: WorkoutLabelAssignOption[] = useMemo(
    () =>
      workoutLabelOptions.filter((option) =>
        props.commonLabels.some(
          (label) => StringHelpers.cleanWhitespace(label) === StringHelpers.cleanWhitespace(option.name)
        )
      ),
    [props.commonLabels, workoutLabelOptions]
  );

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: WorkoutLabelAssignOption[],
      reason: AutocompleteChangeReason
    ) => {
      let diffLabels: WorkoutLabelAssignOption[];
      if (reason === "selectOption") {
        diffLabels = difference(value, commonWorkoutLabelOptions);
        onChange(diffLabels[0].inputValue === undefined ? diffLabels[0].name : diffLabels[0].inputValue, true);
      } else if (reason === "removeOption") {
        diffLabels = difference(commonWorkoutLabelOptions, value);
        onChange(diffLabels[0].name, false);
      }
    },
    [commonWorkoutLabelOptions, onChange]
  );

  const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, value: string) => {
    const LABEL_MAX_LENGTH = 30;
    if (value.match(/^[a-zA-Z0-9\s._-]*$/) && value.length < LABEL_MAX_LENGTH) {
      setInputValue(value);
    }
  }, []);

  const filterOptions = useCallback(
    (options: WorkoutLabelAssignOption[], params: FilterOptionsState<WorkoutLabelAssignOption>) => {
      const filtered = filter(options, params);
      const isExisting = options.some(
        (option) =>
          StringHelpers.cleanWhitespace(params.inputValue.toLowerCase()) ===
          StringHelpers.cleanWhitespace(option.name.toLowerCase())
      );
      if (StringHelpers.cleanWhitespace(params.inputValue).length > 0 && !isExisting) {
        filtered.push({
          id: params.inputValue,
          name: t("workouts.table_label_assign_content", { labels: StringHelpers.cleanWhitespace(params.inputValue) }),
          inputValue: params.inputValue,
          count: 0,
        });
      }

      return filtered;
    },
    [t]
  );

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: "workout-assign-autocomplete",
    options: sortedWorkoutLabelOptions,
    value: commonWorkoutLabelOptions,
    inputValue,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.name === value.name,
    open: true,
    filterOptions,
    onChange: handleChange,
    onInputChange: handleInputChange,
    multiple: true,
    disableClearable: true,
  });

  const inputProps = getInputProps();
  const listboxProps = getListboxProps();

  return (
    <Grid container {...getRootProps()} flexDirection="column" flexWrap="nowrap" height="100%">
      <Grid item width="100%" height="62px">
        <IconPopperMenuSearchInput
          inputProps={inputProps}
          placeholder={t("workouts.label_assign_search_placeholder")}
          onKeyDown={EventHelpers.onKeyDownDisableBackspaceDelete}
        />
        <Divider />
      </Grid>
      <Grid item width="100%" position="relative" height={groupedOptions.length * 37} minHeight={43} maxHeight={320}>
        <IconPopperMenuListbox {...listboxProps}>
          {groupedOptions.length > 0 ? (
            (groupedOptions as WorkoutLabelAssignOption[]).map((workoutLabel, index) => {
              const selected = commonWorkoutLabelOptions.some(
                (commonLabel) => commonLabel.name.toLowerCase() === workoutLabel.name.toLowerCase()
              );
              return (
                <AutocompleteOptionColourable
                  {...getOptionProps({ option: workoutLabel, index })}
                  key={workoutLabel.name}
                >
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </ListItemIcon>
                  {`${workoutLabel.name} ${workoutLabel.count > 0 ? `(${workoutLabel.count})` : ""}`}
                </AutocompleteOptionColourable>
              );
            })
          ) : (
            <Box p={1.25} pl={3} pb={0} color="grey.600">
              {t("workouts.label_assign_search_no_results")}
            </Box>
          )}
        </IconPopperMenuListbox>
      </Grid>
    </Grid>
  );
}

export default TableWorkoutLabelAssignPopperContent;
