import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import { useEffect, useState } from "react";
import UserService from "services/UserService";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";
import CanDoAction from "components/CanDoAction";
import { useTranslation } from "react-i18next";

interface SettingsAccountUsageInfoProps {}

const InfoPair = ({ label, value }: { label: string; value?: string | number }) => (
  <Typography variant="h4" gutterBottom>
    {label}:{" "}
    <Box component="span" fontWeight={500}>
      {value}
    </Box>
  </Typography>
);

function SettingsAccountUsageInfo(props: SettingsAccountUsageInfoProps) {
  const [openApiEnabled, setOpenApiEnabled] = useState<boolean>(false);
  const { t } = useTranslation();

  // TODO: (0S-4710) Replace with AccountInfoProvider
  const organisation = useOrganisation();
  const organisationActions = useOrganisationActions();

  useEffect(() => {
    organisationActions.get();
    // END TODO
    (async () => {
      const idTokenResult = await UserService.currentUser?.getIdTokenResult();
      setOpenApiEnabled(Boolean(idTokenResult?.claims?.openApiUser));
    })();
  }, [organisationActions]);

  const subEndDate = organisation.subscriptionEndDate
    ? moment(organisation.subscriptionEndDate).toDate().toDateString()
    : "-";

  return (
    <Grid container spacing="24px" maxWidth={400}>
      <Grid item xs={12}>
        <Typography variant="h2" gutterBottom>
          {t("settings.subscription_usage_title")}
        </Typography>
        <InfoPair
          label={t("settings.subscription_usage_active_athletes")}
          value={organisation.accountInfo.activeAthleteCount}
        />
        <InfoPair
          label={t("settings.subscription_usage_active_athletes_limit")}
          value={organisation.accountInfo.athleteLimit}
        />
        <InfoPair
          label={t("settings.subscription_usage_archived_athletes")}
          value={organisation.accountInfo.archivedAthleteCount}
        />
        <InfoPair
          label={t("settings.subscription_usage_archived_athletes_limit")}
          value={organisation.accountInfo.athleteLimit}
        />
        <CanDoAction action="group.view">
          <InfoPair
            label={t("settings.subscription_usage_active_coaches")}
            value={organisation.accountInfo.activeCoachCount}
          />
          <InfoPair label={t("settings.subscription_usage_coach_limit")} value={organisation.accountInfo.coachLimit} />
        </CanDoAction>

        <Typography variant="h2" gutterBottom mt={2}>
          {t("settings.subscription_config_title")}
        </Typography>
        <InfoPair
          label={t("settings.subscription_config_open_api_enabled")}
          value={openApiEnabled ? t("global.yes_text") : t("global.no_text")}
        />
        <InfoPair
          label={t("settings.subscription_config_groups_enabled")}
          value={organisation.featureConfig.groupsEnabled ? t("global.yes_text") : t("global.no_text")}
        />
        <InfoPair
          label={t("settings.subscription_config_custom_exercise_library")}
          value={organisation.featureConfig.customExercisesEnabled ? t("global.yes_text") : t("global.no_text")}
        />
        <InfoPair
          label={t("settings.subscription_config_workouts_enabled")}
          value={organisation.featureConfig.isWorkoutsEnabled ? t("global.yes_text") : t("global.no_text")}
        />
        <InfoPair label={t("settings.subscription_config_subscription_end_date")} value={subEndDate} />
      </Grid>
    </Grid>
  );
}

export default SettingsAccountUsageInfo;
