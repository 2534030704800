import { useFormikContext } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import ProfilePhotoInput from "components/Inputs/ProfilePhotoInput";
import { useCallback, useRef } from "react";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";

interface FormProfilePhotoProps {
  name: string;
  buttonText: string;
}

function FormProfilePhoto(props: FormProfilePhotoProps) {
  const photoInputRef = useRef<HTMLInputElement>(null);
  const { values, initialValues, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<{
    [inputName: string]: null | string | { content: string };
  }>();
  const { t } = useTranslation();

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];

      if (!file) return;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (progressEvent) => {
        if (!progressEvent.target?.result) throw new Error("File ProgressEvent not valid");

        const newPhoto: { content: string | ArrayBuffer; name: string; contentType: string; size: number } = {
          content: progressEvent.target.result,
          name: file.name,
          contentType: file.type,
          size: file.size,
        };

        await setFieldTouched(props.name);
        await setFieldValue(props.name, newPhoto);
      };
    },
    [props.name, setFieldTouched, setFieldValue]
  );

  const handleDelete = useCallback(
    async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const valueOnDeleteClick = values[props.name] === initialValues[props.name] ? null : initialValues[props.name];

      if (photoInputRef.current) {
        photoInputRef.current.value = "";
      }

      await setFieldValue(props.name, valueOnDeleteClick);
      await setFieldTouched(props.name);
    },
    [initialValues, props.name, setFieldTouched, setFieldValue, values]
  );

  const error = touched[props.name] ? errors[props.name] : undefined;
  const helperText = error && t(error);
  const value = values[props.name];

  return (
    <>
      <ProfilePhotoInput
        customRef={photoInputRef}
        size="large"
        imageUrl={typeof value === "object" ? value?.content : value}
        onChange={handleChange}
        onDelete={handleDelete}
      />
      <Box display="flex" alignItems="center" flexDirection="column">
        <FormHelperText error>{helperText}</FormHelperText>
        <Mui5.Button
          color="primary"
          fullWidth={false}
          variant="outlined"
          onClick={() => photoInputRef.current?.click()}
          sx={{ width: 240, mt: 1 }}
        >
          {props.buttonText}
        </Mui5.Button>
      </Box>
    </>
  );
}

export default FormProfilePhoto;
