import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-http-backend";
import type { HttpBackendOptions } from "i18next-http-backend";
import en from "./translations/en";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init<HttpBackendOptions>({
    debug: process.env.NODE_ENV === "development",
    lng: "en",
    fallbackLng: "en",
    resources: { en },
    // returnEmptyString: false, // When using Lokalise, it returns empty string instead of key
    // parseMissingKeyHandler: () => "🍞", // replace with the following text to find change all translations
    interpolation: { escapeValue: false }, // react already safes from xss},
  });

export default i18n;
