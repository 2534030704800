import Grid from "@mui/material/Grid";
import { useCallback, useRef, cloneElement, useState } from "react";
import ImageService from "features/Charting/services/ImageService";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import useChartName from "features/Charting/hooks/useChartName";
import outputLogoFull from "assets/images/Logo-reversed.svg";
import ImageExportIcon from "components/Icons/ImageExportIcon";
import FullScreenIcon from "components/Icons/FullScreenIcon";
import Portal from "components/Portal";
import CsvExportIcon from "components/Icons/CsvExportIcon";
import useChartDataExportButtonProps from "features/Charting/hooks/useChartDataExportButtonProps";
import * as Components from "./ChartingScaffolding.components";
import CanDoAction from "components/CanDoAction";
import { useTranslation } from "react-i18next";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import logEvent from "utils/logEvent";
import ChartingInfoCard from "features/Charting/ChartingInfoCard";
import Modal from "components/Modal";

interface ChartingScaffoldingProps {
  chart: React.ReactNode;
  legend?: React.ReactNode;
  controls: React.ReactNode;
}

function ChartingScaffolding(props: ChartingScaffoldingProps) {
  const chartName = useChartName();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [showInfoCard, setShowInfoCard] = useState(false);
  const { t } = useTranslation();

  const downloadAsImage = useCallback(() => {
    if (!chartRef?.current) {
      throw new Error(t("errors.chart_rendering_image_failed"));
    }

    const fileName = chartName.replace(/\/\//g, "");

    ImageService.exportAsImage(chartRef.current, fileName);
  }, [t, chartName]);

  const toggleFullScreen = useCallback(() => {
    setShowFullScreen((open) => !open);
  }, []);

  const toggleInfoCard = useCallback(() => {
    setShowInfoCard((prev) => {
      const updatedValue = !prev;

      if (updatedValue) {
        logEvent("INFO_CARD_VIEW", { page: "Charting" });
      }

      return updatedValue;
    });
  }, []);

  const chartDataExportButtonProps = useChartDataExportButtonProps();

  const chartElement = (
    <Components.ChartContainerDiv ref={chartRef} className="chartContainer">
      <div>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs>
            <Grid container alignItems="center">
              <Grid item pr={0}>
                <Typography variant="h1">{chartName}</Typography>
              </Grid>
              {chartName === "Charting" && (
                <Grid pl={0}>
                  <div className="infoButtonContainer">
                    <IconButton size="small" color="primary" onClick={toggleInfoCard}>
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <div className="controlsClass">
              <Grid container spacing={1} justifyContent={"flex-end"}>
                <CanDoAction action="export.any">
                  <Grid item>
                    <IconButton
                      title={t("charting.export_csv_button_title")}
                      size="small"
                      color="primary"
                      {...chartDataExportButtonProps}
                    >
                      <CsvExportIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                </CanDoAction>
                <Grid item>
                  <IconButton
                    title={t("charting.export_png_button_title")}
                    size="small"
                    color="primary"
                    onClick={downloadAsImage}
                  >
                    <ImageExportIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title={t("charting.full_screen_button_title")}
                    size="small"
                    color="primary"
                    onClick={toggleFullScreen}
                  >
                    <FullScreenIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
            <div
              className="logoContainer"
              style={{
                display: "none",
              }}
            >
              <img src={outputLogoFull} className="logo" alt={t("global.logo_stripes_alt_text")} />
            </div>
          </Grid>
        </Grid>
      </div>
      {props.legend && <div>{props.legend}</div>}
      <Components.ChartDiv>{props.chart}</Components.ChartDiv>
    </Components.ChartContainerDiv>
  );

  return (
    <>
      <>
        <Components.ScaffoldingCtrDiv>
          <Grid container height="100%">
            <Grid item pr={3} xs>
              <Components.Paper p={3}>{chartElement}</Components.Paper>
            </Grid>
            <Components.ControlsGrid item height="100%">
              <Components.Paper>{props.controls}</Components.Paper>
            </Components.ControlsGrid>
          </Grid>
        </Components.ScaffoldingCtrDiv>
        {showFullScreen && (
          <Portal>
            <Components.FullScreenDiv>{cloneElement(chartElement, { ref: null })}</Components.FullScreenDiv>
          </Portal>
        )}
      </>
      <Modal open={showInfoCard} toggleOpen={toggleInfoCard}>
        <ChartingInfoCard onClose={toggleInfoCard} />
      </Modal>
    </>
  );
}

export default ChartingScaffolding;
