import { TextFieldProps } from "@mui/material";
import { useField } from "formik";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

/**
 * @deprecated In favour of {@link FormText src/components/Inputs/Form/FormText.tsx}
 */
function FormText({ name, ...props }: TextFieldProps & { name: string }) {
  const [field, meta, helpers] = useField(name);
  const { t } = useTranslation();

  const fieldProps = useMemo(
    () => ({
      name,
      onChange: (event: any) => helpers.setValue(event.target.value),
      value: field.value || "",
      onBlur: () => helpers.setTouched(true),
      error: !!Boolean(meta.touched && meta.error),
      helperText: meta.touched && meta.error ? t(meta.error) : props.helperText,
    }),
    [name, field.value, meta.touched, meta.error, t, props.helperText, helpers]
  );

  return <Mui5.TextField {...props} {...fieldProps} />;
}

export default FormText;
