import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export const DataGridComponents = {
  NoRowsOverlay: () => {
    const { t } = useTranslation();
    return (
      <Grid container height="400px" justifyContent="center" alignItems="center">
        <Grid minWidth={240} maxWidth={320}>
          <Typography variant="h3" textAlign="center" gutterBottom>
            {t("info.no_coaches_yet")}
          </Typography>
        </Grid>
      </Grid>
    );
  },
};
