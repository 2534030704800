import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { tableCells } from "../GroupsTable.config";
import { Group } from "types/groups";
import Mui5 from "components/mui5";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface GroupsTableControlsProps {
  groups: Group[];
}

function GroupsTableControls(props: GroupsTableControlsProps) {
  const navigate = useNavigate();

  const handleAddGroupClick = useCallback(() => {
    navigate("/groups/add");
  }, [navigate]);

  const { t } = useTranslation();

  return (
    <Grid container minHeight={64} alignItems="center">
      <Grid {...tableCells.checkbox}></Grid>
      <Grid item xs />
      <Grid pr={2}>
        <Mui5.Button color="primary" disableElevation variant="contained" onClick={handleAddGroupClick} size="medium">
          {t("group.all.add_new_group_button_text")}
        </Mui5.Button>
      </Grid>
    </Grid>
  );
}

export default GroupsTableControls;
