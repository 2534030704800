import * as Yup from "yup";

export const errorMessages = {
  exercise: "errors.missing_exercise",
  repetitionField: "errors.missing_repetition_field",
  setComparisonFilters: "errors.missing_set_comparison_filters",
};

export const schema = Yup.object()
  .shape({
    exerciseId: Yup.object().required(errorMessages.exercise),
    repetitionField: Yup.object().required(errorMessages.repetitionField),
    setComparisonFilters: Yup.array().of(Yup.object().required()).min(1, errorMessages.setComparisonFilters).required(),
  })
  .required();
