import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Box from "@mui/material/Box";
import AthleteName from "components/AthleteName";
import { FormOneRepMax } from "../hooks/useOneRepMaxFormInitialValues/useOneRepMaxFormInitialValues.types";
import { FieldArray, FieldArrayRenderProps } from "formik";
import { Fragment, memo, useCallback, useMemo } from "react";
import usePreferences from "hooks/usePreferences";
import InputAdornment from "@mui/material/InputAdornment";
import Divider from "@mui/material/Divider";
import { useAthlete } from "hooks";
import NumberFormat from "react-number-format";
import Mui5 from "components/mui5";
import AthleteHelpers from "helpers/AthleteHelpers";
import { useTranslation } from "react-i18next";

interface AthleteBulkEditRowProps {
  athlete1rmValue: FormOneRepMax;
  athlete1rmValueIndex: number;
  arrayHelpers: FieldArrayRenderProps;
  spacingX: number;
}

function AthleteBulkEditRow(props: AthleteBulkEditRowProps) {
  const { athlete1rmValue, athlete1rmValueIndex, arrayHelpers, spacingX } = props;
  const [{ imperialSystemEnabled }] = usePreferences();
  const { t } = useTranslation();

  const athlete = useAthlete(athlete1rmValue.athleteId);
  const athleteName = useMemo(() => (athlete ? AthleteHelpers.getFullName(athlete) : ""), [athlete]);

  return (
    <Grid container minHeight={56} alignItems="center">
      <Grid item pl={1.5} pr={1.5}>
        <Checkbox
          checkedIcon={<CloseOutlinedIcon />}
          inputProps={{ "aria-label": t("athlete.select_athlete_name_aria_label", { name: athleteName }) }}
          checked
          onClick={useCallback(() => arrayHelpers.remove(athlete1rmValueIndex), [arrayHelpers, athlete1rmValueIndex])}
        />
      </Grid>
      <Grid item xs pr={2}>
        <AthleteName athlete={athlete} id={athlete1rmValue.athleteId} />
      </Grid>
      <Grid item mr={spacingX}>
        <FieldArray
          name={`${arrayHelpers.name}.${athlete1rmValueIndex}.oneRepMaxes`}
          render={(weightValueArrayHelpers: FieldArrayRenderProps) =>
            athlete1rmValue.oneRepMaxes.map((oneRepMax, oneRepMaxIndex) => {
              const weightFieldProps = weightValueArrayHelpers.form.getFieldProps<number>(
                `${weightValueArrayHelpers.name}.${oneRepMaxIndex}.weight`
              );

              const weightFieldMetaProps = weightValueArrayHelpers.form.getFieldMeta<number>(
                `${weightValueArrayHelpers.name}.${oneRepMaxIndex}.weight`
              );
              return (
                <Fragment key={`${weightValueArrayHelpers.name}.${oneRepMaxIndex}.weight${oneRepMax.variant}`}>
                  {oneRepMaxIndex > 0 && <Box display="inline" p={1} />}
                  <NumberFormat
                    customInput={Mui5.TextField}
                    variant="outlined"
                    margin="dense"
                    size="small"
                    label={oneRepMax.variant as string | undefined}
                    {...weightFieldProps}
                    value={
                      typeof weightFieldProps.value == "number"
                        ? Math.round(weightFieldProps.value * 100) / 100
                        : weightFieldProps.value
                    }
                    fullWidth={false}
                    error={Boolean(weightFieldMetaProps.touched && weightFieldMetaProps.error)}
                    InputProps={{
                      sx: { width: "120px" },
                      endAdornment: (
                        <InputAdornment position="end">{imperialSystemEnabled ? "lbs" : "kg"}</InputAdornment>
                      ),
                      inputProps: {
                        "aria-label": `${weightFieldProps.name}.${oneRepMaxIndex}.weight`,
                        maxLength: 5,
                      },
                    }}
                  />
                </Fragment>
              );
            })
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}

export default memo(AthleteBulkEditRow);
