import { RangeType } from "components/ReportGeneration/CreateReport/ModuleModal.types";
import moment from "moment";
import { Exercise, ExerciseMetric } from "types";
import * as Yup from "yup";
import { AthleteKpiModuleFormInitialValues } from "./AthleteKpiModuleForm.types";

export const errorMessages = {
  exercise: "errors.missing_exercise",
  metricField: "errors.missing_metric",
  customDate: "errors.missing_date_required_when_custom_range",
  exercises: "errors.select_at_least_one_exercise",
  nullExercise: "errors.null_exercise",
};

const nullSchema = Yup.mixed<Exercise>()
  .nullable()
  .test("null-test", errorMessages.nullExercise, (val) => val === null);

export const schema = Yup.object<AthleteKpiModuleFormInitialValues>()
  .shape({
    moduleType: Yup.number(),
    startDate: Yup.string()
      .typeError(errorMessages.customDate)
      .when("rangeType", ([rangeType], schema: Yup.StringSchema) => {
        const periodName = ["day" as const, "week" as const, "month" as const, "year" as const][rangeType];

        if (periodName) {
          return schema.transform(() => moment().subtract(1, periodName).endOf("day").toISOString());
        }

        return schema.transform(
          (_, originalValue: moment.Moment) => originalValue && moment(originalValue).startOf("day").toISOString()
        );
      })
      .required(),
    endDate: Yup.string()
      .typeError(errorMessages.customDate)
      .when("rangeType", ([rangeType], schema: Yup.StringSchema) => {
        if (rangeType !== RangeType.Other) {
          return schema.transform(() => moment().endOf("day").toISOString()).required();
        }

        return schema
          .transform(
            (_, originalValue: moment.Moment) => originalValue && moment(originalValue).endOf("day").toISOString()
          )
          .required();
      })
      .required(),
    exercises: Yup.array()
      .of(
        Yup.object()
          .shape<any>({
            exerciseId: Yup.object<Exercise>()
              // .typeError(errorMessages.exercise)
              .required(errorMessages.exercise),
            metricField: Yup.object<ExerciseMetric>()
              .typeError(errorMessages.metricField)
              // .transform((_, metric: ExerciseMetric) => metric || null)
              .required(errorMessages.metricField),
          })
          .nullable()
          .required(errorMessages.exercises)
      )
      .required(errorMessages.exercises)
      .min(1, errorMessages.exercises),
    rangeType: Yup.number().required(),
    kpiType: Yup.number().required(),
    metricField: nullSchema,
    exercise: nullSchema,
  })
  .required();
