import Box from "@mui/material/Box";
import LeaderboardTableAbsolute from "components/Leaderboards/LeaderboardTableAbsolute";
import MessageWithLogo from "components/MessageWithLogo";
import MeasurementsFilterContext from "contexts/MeasurementsFilterContext";
import { useFormikContext } from "formik";
import useFilterMeasurementsByAthleteIds from "hooks/useFilterMeasurementsByAthleteIds";
import moment from "moment";
import { useMemo } from "react";
import { LeaderboardFormValues } from "../LeaderboardEditable.types";
import * as Components from "./LeaderboardEditableData.components";
import LeaderboardTablePercentage from "components/Leaderboards/LeaderboardTablePercentage";
import { DateTime, LeaderboardRankType } from "types";
import TickerNode from "components/Ticker/TickerNode";
import { useTranslation } from "react-i18next";

function LeaderboardEditableData() {
  const { t } = useTranslation();
  const { values } = useFormikContext<LeaderboardFormValues>();
  const { exercise, metric, type, variant, rankType, comparePeriodType, startDateComparePeriod } = values;

  const filterMeasurements = useFilterMeasurementsByAthleteIds(values.groups);

  const period = useMemo(() => {
    const endDate = moment().endOf("day");

    if (type.value === "All" || comparePeriodType.value === "All") {
      return {
        startDate: moment("2019-01-01").toISOString(),
        endDate: endDate.toISOString(),
      };
    }

    if (rankType === LeaderboardRankType.PercentageOfBest) {
      if (comparePeriodType.value === "Custom") {
        return {
          startDate: moment(startDateComparePeriod).toISOString(),
          endDate: endDate.toISOString(),
        };
      }
      const comparePeriodKey = comparePeriodType.value.toLowerCase() as "week" | "month" | "year";

      return {
        startDate: endDate.clone().subtract(1, comparePeriodKey).toISOString(),
        endDate: endDate.toISOString(),
      };
    }

    const timePeriodKey = type.value.toLowerCase() as "day" | "week" | "month";

    return {
      startDate: endDate.clone().subtract(1, timePeriodKey).toISOString(),
      endDate: endDate.toISOString(),
    };
  }, [comparePeriodType.value, startDateComparePeriod, rankType, type.value]);

  const timePeriodStartDate: DateTime | undefined = useMemo(() => {
    if (rankType !== LeaderboardRankType.PercentageOfBest) {
      return;
    }

    if (type.value === "All") {
      throw new Error(t("leaderboard.relative_all_time_error"));
    }

    const timePeriodKey = type.value.toLowerCase() as "day" | "week" | "month";

    return moment().endOf("day").subtract(1, timePeriodKey).toISOString();
  }, [rankType, type.value, t]);

  return (
    <MeasurementsFilterContext.Provider value={filterMeasurements}>
      <Box overflow="hidden" display="flex" flexDirection="column" flex={1} position="relative">
        <Components.OverflowBox flex={1}>
          {exercise && metric ? (
            <Box>
              {rankType !== LeaderboardRankType.PercentageOfBest ? (
                <LeaderboardTableAbsolute exercise={exercise} metric={metric} variant={variant} {...period} />
              ) : (
                <LeaderboardTablePercentage
                  exercise={exercise}
                  metric={metric}
                  variant={variant}
                  {...period}
                  comparePeriodType={comparePeriodType}
                  timePeriodStartDate={timePeriodStartDate}
                  startDateComparePeriod={startDateComparePeriod}
                />
              )}
            </Box>
          ) : (
            <Box px={2}>
              <MessageWithLogo>{t("leaderboard.no_exercise_selected_error")}</MessageWithLogo>
            </Box>
          )}
        </Components.OverflowBox>
        <Box position="absolute" bottom={0} left={0} right={0}>
          <TickerNode />
        </Box>
      </Box>
    </MeasurementsFilterContext.Provider>
  );
}

export default LeaderboardEditableData;
