import useSearchText from "providers/SearchTextProvider/useSearchText";
import useSetSearchText from "providers/SearchTextProvider/useSetSearchText";
import Mui5 from "components/mui5";
import { useCallback } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

interface TablesSearchTextInputProps {}

function TablesSearchTextInput(props: TablesSearchTextInputProps) {
  const text = useSearchText();
  const setText = useSetSearchText();

  const handleSearchTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setText(event.target.value);
    },
    [setText]
  );

  const handleClearSearch = useCallback(() => {
    setText("");
  }, [setText]);

  const handleEscapeKeypress: React.KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key !== "Escape") return;
      setText("");
    },
    [setText]
  );

  const { t } = useTranslation();

  return (
    <Mui5.TextField
      color="primary"
      onChange={handleSearchTextChange}
      value={text}
      onKeyDown={handleEscapeKeypress}
      InputProps={{
        endAdornment: text && (
          <InputAdornment position="end">
            <IconButton onClick={handleClearSearch}>
              <ClearIcon color="inherit" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      fullWidth
      placeholder={t("global.search_placeholder")}
      variant="standard"
      sx={{ "& .MuiInput-input": { fontSize: 18 } }}
    />
  );
}

export default TablesSearchTextInput;
