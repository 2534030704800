import { Component, Fragment } from "react";
import closeIcon from "../../assets/images/close-icon.svg";
import PropTypes from "prop-types";
import "./Confirmation.scss";
import Mui5 from "components/mui5";
import { t } from "i18next";

/**
 * @deprecated
 */
class Confirmation extends Component {
  state = {
    isConfirmationOpen: false,
  };

  handleOpenConfirmation = () => {
    this.setState({ isConfirmationOpen: true });
  };

  handleCloseConfirmation = () => {
    this.setState({ isConfirmationOpen: false });
    if (!this.props.hasOwnButton) {
      this.props.negativeHandler();
    }
  };

  handlePositiveAnswer = () => {
    this.setState({ isConfirmationOpen: false });
    this.props.positiveHandler();
  };

  render() {
    const {
      confirmationButtonText = t("global.delete_button_text"),
      hasOwnButton = true,
      isConfirmationOpen = false,
      openButtonProps = undefined,
    } = this.props;

    return (
      <Fragment>
        {Boolean(isConfirmationOpen || this.state.isConfirmationOpen) && (
          <div
            className={
              "oh-confirmation-overlay" +
              ((hasOwnButton && this.state.isConfirmationOpen) || (!hasOwnButton && isConfirmationOpen)
                ? " is-active"
                : "")
            }
          >
            <div className="oh-confirmation-popup">
              <button type="button" className="oh-confirmation-close" onClick={this.handleCloseConfirmation}>
                <img src={closeIcon} alt="close" />
              </button>
              <div className="oh-confirmation-inner">
                <div className="oh-confirmation-title">{this.props.confirmationMessage}</div>
                <Mui5.Button color="error" onClick={this.handlePositiveAnswer}>
                  {confirmationButtonText}
                </Mui5.Button>
                <Mui5.Button variant="outlined" onClick={this.handleCloseConfirmation}>
                  {t("global.cancel_button_text")}
                </Mui5.Button>
              </div>
            </div>
          </div>
        )}
        {hasOwnButton ? (
          <Mui5.Button onClick={this.handleOpenConfirmation} variant="outlined" color="error" {...openButtonProps}>
            {this.props.buttonText}
          </Mui5.Button>
        ) : (
          <Fragment>{this.props.children}</Fragment>
        )}
      </Fragment>
    );
  }
}

Confirmation.propTypes = {
  confirmationMessage: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  confirmationButtonText: PropTypes.string,
  hasOwnButton: PropTypes.bool,
  isConfirmationOpen: PropTypes.bool,
  positiveHandler: PropTypes.func, // used always on "Confirm" Click
  negativeHandler: PropTypes.func, // only required when hasOwnButton is false
  openButtonProps: PropTypes.object,
};

export default Confirmation;
