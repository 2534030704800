import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalPageLayout from "components/VerticalPageLayout";
import { useRequest } from "hooks";
import useAthleteActions from "providers/AthletesProvider/useAthleteActions";
import useAllAthletes from "hooks/useAllAthletes";
import PageContent from "components/PageContent";
import AthleteProfileDetails from "./AthleteProfileDetails";
import { useMatch } from "react-router-dom";
import LocalisedLoadingProvider, { LocalisedLoadingContext } from "components/LocalisedLoadingProvider";
import useErrorPages from "hooks/useErrorPages";
import AthleteProfileActivityFeed from "./AthleteProfileActivityFeed";
import logEvent from "utils/logEvent";
import AthleteProfileChart from "./AthleteProfileChart";
import ChartingProvidersV3 from "components/charting/providers/ChartingProvidersV3";
import ArchivedAthleteAlert from "components/AthleteHub/EditAthlete/ArchivedAthleteAlert";
import Mui5 from "components/mui5";
import { sxFullWidthMinus4px } from "./AthleteProfile.styles";
import * as Components from "./AthleteProfile.components";
import AthleteProfileInfoCard from "./AthleteProfileInfoCard";
import { useTranslation } from "react-i18next";

function AthleteProfile() {
  const { t } = useTranslation();
  const loadingContextValue = useState(false);
  const athletes = useAllAthletes();
  const { fetchById, toggleArchived } = useAthleteActions();
  const athleteId = useMatch("/athlete-hub/:athleteId")?.params?.athleteId;
  const [ErrorPage, setHttpError] = useErrorPages();
  const athlete = athletes.find((athlete) => athlete.id === athleteId);
  const handleToggleArchived = useRequest(toggleArchived, true);
  const heightCalc4px = "calc(100% - 4px)";

  useEffect(() => {
    if (typeof athleteId === "undefined") return;

    fetchById(athleteId).catch(setHttpError);
  }, [fetchById, athleteId, setHttpError]);

  useEffect(() => {
    logEvent("ATHLETES_VIEW_PROFILE");
  }, [athleteId]);

  if (ErrorPage) return <ErrorPage />;

  if (!athleteId || typeof athlete === "undefined") return null;

  return (
    <>
      <VerticalPageLayout
        top={
          <Box pt={2} pb={2} pl={2}>
            <PageContent
              pageHeading={t("athlete_profile.page_title")}
              pageHeadingLink="/athlete-hub"
              infoCardComponent={AthleteProfileInfoCard}
            />
          </Box>
        }
        title={
          <LocalisedLoadingContext.Provider value={loadingContextValue}>
            <Box pl={2}>
              <AthleteProfileDetails {...athlete} />
            </Box>
          </LocalisedLoadingContext.Provider>
        }
        main={
          <Components.ContainerMainContentBox width="100%" mt={3} mb={3} ml={2}>
            <Grid container height={heightCalc4px}>
              <Grid item xs height={heightCalc4px}>
                <Components.FullWidthMinus4pxBox pr={1}>
                  <Mui5.Paper elevation={8} sx={sxFullWidthMinus4px}>
                    <ChartingProvidersV3>
                      <AthleteProfileChart />
                    </ChartingProvidersV3>
                  </Mui5.Paper>
                </Components.FullWidthMinus4pxBox>
              </Grid>
              <Grid item height="calc(100% + 16px)" width="280px">
                <LocalisedLoadingProvider>
                  <AthleteProfileActivityFeed id={athleteId} />
                </LocalisedLoadingProvider>
              </Grid>
            </Grid>
          </Components.ContainerMainContentBox>
        }
      />
      <ArchivedAthleteAlert
        isArchived={athlete.isArchived}
        unarchiveAthlete={() => {
          handleToggleArchived(athlete.id, false);
        }}
      />
    </>
  );
}

export default AthleteProfile;
