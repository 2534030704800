import { autocompletePaperStyleOverrides, autocompletePopperStyleOverrides } from "components/mui5/Autocomplete";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import LinkUtilityComponent from "components/mui5/components/LinkUtilityComponent";

export const colors = {
  primary: "#0D1724",
  secondary: "#83E2C2",
  white: "#FFF",
  error: "#C0392B",
  warning: "#F2E148",
  success: "#1CE782",
};

export const muiTheme = {
  palette: {
    background: {
      default: "#FAFBFE",
    },
    primary: {
      main: colors.primary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      contrastText: "#000",
    },
    // TEMP
    secondary1: {
      // Added from newer theme which currently only wraps Dashboards
      60: "#92FFDD",
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
    },
    success: {
      main: colors.success,
    },
    info: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: ["'Roboto'", "-apple-system", "BlinkMacSystemFont", "sans-serif"].join(", "),
    h1: {
      fontSize: 22,
      fontWeight: 300,
    },
    h2: {
      fontSize: 20,
    },
    h3: {
      fontSize: 18,
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
    },
    button: {
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
    caption: {
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1,
    },
  },
  // V5 overrides
  components: {
    MuiButton: {
      defaultProps: Object.freeze({
        LinkComponent: LinkUtilityComponent,
        variant: "contained",
        size: "large",
        fullWidth: true,
        disableElevation: true,
      }),
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkUtilityComponent,
      },
    },
    MuiChip: {
      defaultProps: {
        deleteIcon: <ClearOutlinedIcon color="primary" viewBox="-6 -6 36 36" />,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: autocompletePaperStyleOverrides,
        popper: autocompletePopperStyleOverrides,
      },
    },
  },
};

export default muiTheme;
