import React from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormWrapper, Field } from "formik";
import Form from "../../Form";
import Grid from "@mui/material/Grid";
import CustomDateRangeFullWidthFix from "../CustomDateRangeFullWidthFix";
import DependantFormField from "../../Form/DependantFormField";
import CustomDateRange from "../../Form/CustomDateRange";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { schema } from "./ExportDataAthleteForm.schema";
import InputLabel from "@mui/material/InputLabel";
import FormAutoComplete from "components/Form/FormAutocomplete";
import { exerciseAutocompleteFilterOptions } from "config/autocomplete";
import Mui5 from "components/mui5";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ColorableChip from "components/ColorableChip";
import { useArchivedAthletes } from "providers/ArchivedAthletesProvider";
import CanDoAction from "components/CanDoAction";
import useCanDo from "hooks/useCanDo";
import { useTranslation } from "react-i18next";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";

function ExportDataAthleteForm(props) {
  const onCustomDateDependencyChange = React.useCallback(({ setFieldValue }) => setFieldValue("period", "custom"), []);
  const archivedAthletes = useArchivedAthletes();
  const canDo = useCanDo();
  const { t } = useTranslation();

  function handleSubmit(values, formikBag) {
    /**
     * schema.cast() is used to convert
     * values to expected output. */
    const archivedAthleteIds = archivedAthletes.map((athlete) => athlete.id);
    const castValues = schema.cast(values);

    return props.onSubmit(
      {
        ...castValues,
        athleteIds: castValues.athleteIds.filter((athleteId) => !archivedAthleteIds.includes(athleteId)),
      },
      formikBag
    );
  }

  const combinedAthletesAndGroups = React.useMemo(() => {
    return [
      ...props.athletes.map((athlete) => ({ ...athlete, type: "Athletes" })),
      ...props.groups.map((group) => ({ ...group, type: "Groups" })),
    ];
  }, [props.athletes, props.groups]);

  const allAthletes = React.useMemo(
    () => combinedAthletesAndGroups.filter((item) => item.type === "Athletes"),
    [combinedAthletesAndGroups]
  );
  const allGroups = React.useMemo(
    () => combinedAthletesAndGroups.filter((item) => item.type === "Groups"),
    [combinedAthletesAndGroups]
  );

  function getOptionLabel(option) {
    return [option.firstName, option.lastName].filter(Boolean).join(" ") || option.name;
  }

  return (
    <Formik
      initialValues={{
        athleteIds: [],
        exerciseIds: [],
        startDate: null,
        endDate: null,
        period: "month",
        splitExercises: false,
        includeHeight: false,
        includeWeight: false,
        includeWellness: false,
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      <FormWrapper>
        <Box>
          <Grid container spacing="40px">
            <Grid item xs={12}>
              <Typography variant="h1" align="center">
                {t("export.form_title")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Field name="athleteIds">
                {({ field, form }) => (
                  <Mui5.Autocomplete
                    {...field}
                    multiple
                    forcePopupIcon
                    autoHighlight
                    name="groups"
                    disableCloseOnSelect
                    options={combinedAthletesAndGroups}
                    groupBy={(option) => option.type}
                    filterSelectedOptions
                    getOptionLabel={(option) => getOptionLabel(option) || option.name}
                    onChange={(_, value) => form.setFieldValue("athleteIds", value)}
                    renderOption={(props, option) => (
                      <AutocompleteOptionColourable {...props} colour={option.colour} key={option.id}>
                        {getOptionLabel(option)}
                      </AutocompleteOptionColourable>
                    )}
                    renderInput={(params) => (
                      <Mui5.TextField
                        {...params}
                        name="groups"
                        color="secondary"
                        variant="standard"
                        label={t("export.select_athletes_or_groups_input_title", {
                          context: canDo("group.view") ? "groups" : undefined,
                        })}
                        error={Boolean(form.touched[field.name] && form.errors[field.name])}
                        helperText={form.touched[field.name] && form.errors[field.name] && t(form.errors[field.name])}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });

                        return (
                          <ColorableChip
                            key={option.id}
                            label={getOptionLabel(option)}
                            customColor={option.colour}
                            deleteIcon={
                              <ClearOutlinedIcon
                                title={t("export.remove_item_button_title_text")}
                                color={"primary"}
                                viewBox="-6 -6 36 36"
                              />
                            }
                            {...tagProps}
                          />
                        );
                      })
                    }
                  />
                )}
              </Field>
              <Grid container item spacing={0} p={0}>
                <Grid item xs>
                  <Field name="athleteIds">
                    {({ field }) => (
                      <Mui5.Button
                        variant="text"
                        fullWidth
                        aria-label={t("export.select_all_athletes_button_text")}
                        onClick={() => field.onChange({ target: { name: field.name, value: allAthletes } })}
                        disabled={field?.value === allAthletes}
                      >
                        {t("export.select_all_athletes_button_text")}
                      </Mui5.Button>
                    )}
                  </Field>
                </Grid>
                <CanDoAction action="group.view">
                  <Grid item xs>
                    <Field name="athleteIds">
                      {({ field }) => (
                        <Mui5.Button
                          variant="text"
                          fullWidth
                          aria-label={t("export.select_all_groups_button_text")}
                          onClick={() => field.onChange({ target: { name: field.name, value: allGroups } })}
                          disabled={field?.value === allGroups}
                        >
                          {t("export.select_all_groups_button_text")}
                        </Mui5.Button>
                      )}
                    </Field>
                  </Grid>
                </CanDoAction>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormAutoComplete
                multiple
                name="exerciseIds"
                limitTags={10}
                options={props.exercises}
                groupBy={(option) => option.category}
                getOptionLabel={(option) => option.name}
                disableCloseOnSelect
                filterSelectedOptions
                filterOptions={exerciseAutocompleteFilterOptions}
                renderInput={(params) => (
                  <Mui5.TextField {...params} label={t("export.select_exercises_input_title")} variant="standard" />
                )}
                ChipProps={{
                  variant: "outlined",
                  color: "primary",
                }}
              />
              <Field name="exerciseIds">
                {({ field }) => (
                  <Mui5.Button
                    variant="text"
                    fullWidth
                    aria-label={t("global.select_all_exercises_aria_label")}
                    onClick={() => field.onChange({ target: { name: field.name, value: props.exercises } })}
                    disabled={field?.value === props.exercises}
                  >
                    {t("export.select_exercises_button_text")}
                  </Mui5.Button>
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <InputLabel focused={false} shrink>
                {t("export.other_exports_input_title")}
              </InputLabel>
              <Form.Checkbox
                color="primary"
                label={t("export.other_exports_height_checkbox_label")}
                name="includeHeight"
              />
              <Form.Checkbox
                color="primary"
                label={t("export.other_exports_weight_checkbox_label")}
                name="includeWeight"
              />
              <Form.Checkbox
                color="primary"
                label={t("export.other_exports_wellness_checkbox_label")}
                name="includeWellness"
              />
            </Grid>

            <Grid item xs={12}>
              <Box>
                <Form.RadioGroup
                  label={t("export.date_range_input_title")}
                  name="period"
                  color="primary"
                  options={[
                    { label: t("global.time_label_today"), value: "day" },
                    { label: t("global.time_label_week"), value: "week" },
                    { label: t("global.time_label_month"), value: "month" },
                    { label: t("global.time_label_all_time"), value: "all" },
                    {
                      label: t("global.time_label_custom"),
                      value: "custom",
                      component: (
                        <CustomDateRangeFullWidthFix>
                          <DependantFormField
                            dependencyName="startDate"
                            onDependencyChange={onCustomDateDependencyChange}
                          />
                          <DependantFormField
                            dependencyName="endDate"
                            onDependencyChange={onCustomDateDependencyChange}
                          />
                          <CustomDateRange startInputName="startDate" endInputName="endDate" />
                        </CustomDateRangeFullWidthFix>
                      ),
                    },
                  ]}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InputLabel focused={false} shrink>
                {t("global.form_options_title")}
              </InputLabel>
              <Form.Checkbox color="primary" label={t("export.split_exercises_option_label")} name="splitExercises" />
            </Grid>
            <Grid item xs={12}>
              <Mui5.Button
                fullWidth
                type="submit"
                role="button"
                variant="contained"
                color="primary"
                disableElevation
                aria-label={t("export.export_data_button_text")}
              >
                {t("export.export_data_button_text")}
              </Mui5.Button>
            </Grid>
          </Grid>
        </Box>
      </FormWrapper>
    </Formik>
  );
}

ExportDataAthleteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  athletes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      colour: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default ExportDataAthleteForm;
