import * as Yup from "yup";

const errorMessages = {
  code: "errors.mfa_sms_code_size",
  codeRequired: "errors.mfa_sms_code_required",
};

const SmsCodeSchema = Yup.object()
  .shape({
    code: Yup.string().length(6, errorMessages.code).required(errorMessages.codeRequired),
  })
  .required();
export default SmsCodeSchema;
