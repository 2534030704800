import { useField } from "formik";
import useInfoMessage from "hooks/useInfoMessage";
import UserService from "services/UserService";
import { messages } from "./ForgotPasswordButton.config";
import * as Components from "./ForgotPasswordButton.components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function ForgotPasswordButton() {
  const [field] = useField("email");
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const setInfo = useInfoMessage({ type: "info" });
  const setError = useInfoMessage({ type: "error" });

  function onSuccess() {
    setInfo({ message: messages.success });
  }

  function onError() {
    setError({ message: messages.error });
  }

  function handleSendPasswordResetEmail() {
    setDisabled(true);
    // TODO: verify if the timeout is correct
    setTimeout(() => {
      setDisabled(false);
    }, 60000 * 5);

    UserService.sendPasswordResetEmail(field.value).then(onSuccess).catch(onError);
  }

  return (
    <Components.ForgotPasswordButton
      disabled={disabled}
      type="button"
      tabIndex={-1}
      onClick={handleSendPasswordResetEmail}
    >
      {t("login.forgot_password")}
    </Components.ForgotPasswordButton>
  );
}
export default ForgotPasswordButton;
