import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { LinePointData } from "components/charting/types/Chart.types";
import { useTranslation } from "react-i18next";

function TooltipCardNote(props: LinePointData) {
  const { t } = useTranslation();

  if (!("note" in props.measurement && props.measurement.note)) {
    return null;
  }

  return (
    <>
      <Divider />
      <Box p={1} pl={2}>
        <Typography lineHeight={1.2} color="grey.800" fontWeight={400} fontSize={12}>
          {t("charting.tooltip_notes_header")}
        </Typography>
        <Typography variant="h4" title={props.measurement.note}>
          {props.measurement.note}
        </Typography>
      </Box>
    </>
  );
}

export default TooltipCardNote;
