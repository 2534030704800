import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Form from "../../Form";
import { Field, Formik } from "formik";
import Button from "components/mui5/Button";
import Grid from "@mui/material/Grid";
import schema from "./GroupForm.schema";
import OhCustomColorPicker from "../../CustomColorPicker/CustomColorPicker";
import InputLabelWithOptional from "../../../components/Inputs/InputLabelWithOptional";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Mui5 from "components/mui5";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ColorableChip from "components/ColorableChip";
import { useTranslation } from "react-i18next";
import AutocompleteOptionColourable from "components/AutocompleteOptionColourable";

function GroupForm(props) {
  const { athleteOptions, initialValues = {}, onSubmit, submitText, ...formikProps } = props;

  function handleSubmit(values, formikBag) {
    onSubmit(schema.cast(values), formikBag);
  }

  function getAthleteName(athlete) {
    return [athlete.firstName, athlete.lastName].filter(Boolean).join(" ");
  }

  const fixedAthletes = useMemo(
    () => (initialValues.athleteIds || []).filter((athlete) => athlete.isArchived),
    [initialValues.athleteIds]
  );

  const allSelectableAthletes = useMemo(
    () => [...fixedAthletes, ...athleteOptions.filter((athlete) => !athlete.isArchived)],
    [athleteOptions, fixedAthletes]
  );

  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        colour: "",
        athleteIds: [],
        ...initialValues,
      }}
      onSubmit={handleSubmit}
      validationSchema={schema}
      enableReinitialize
      {...formikProps}
    >
      <Form.Wrapper>
        <Grid container spacing="24px">
          <Grid item xs={12}>
            <Form.Text
              name="name"
              label={t("group.overview.group_form_name_label")}
              placeholder={t("group.overview.group_form_name_placeholder")}
              variant="standard"
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Form.Text
              name="description"
              label={<InputLabelWithOptional optional label={t("group.overview.group_form_description_label")} />}
              placeholder={t("group.overview.group_form_description_placeholder")}
              variant="standard"
              color="secondary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field name="colour">
              {({ field, form }) => (
                <FormControl variant="standard">
                  <InputLabel shrink error={!!form.errors.colour} variant="standard">
                    <InputLabelWithOptional label={t("group.overview.group_form_colour_label")} />
                  </InputLabel>
                  <Box mt={4}>
                    <OhCustomColorPicker field={field} />
                  </Box>
                  <FormHelperText error>{t(form.errors.colour)}</FormHelperText>
                </FormControl>
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="athleteIds">
              {({ field, form }) => (
                <Mui5.Autocomplete
                  {...field}
                  multiple
                  forcePopupIcon
                  autoHighlight
                  name="groups"
                  disableCloseOnSelect
                  options={allSelectableAthletes}
                  filterSelectedOptions
                  getOptionLabel={getAthleteName}
                  onChange={(_, value) =>
                    form.setFieldValue("athleteIds", [
                      ...fixedAthletes,
                      ...value.filter((option) => fixedAthletes.indexOf(option) === -1),
                    ])
                  }
                  renderOption={(props, option) => (
                    <AutocompleteOptionColourable {...props} key={option.id}>
                      {getAthleteName(option)}
                    </AutocompleteOptionColourable>
                  )}
                  renderInput={(params) => (
                    <Mui5.TextField
                      {...params}
                      name="groups"
                      color="secondary"
                      variant="standard"
                      label={t("group.overview.group_form_athletes_label")}
                      placeholder={
                        Boolean(field.value?.length) ? undefined : t("group.overview.group_form_athletes_placeholder")
                      }
                    />
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });

                      return (
                        <ColorableChip
                          key={option.id}
                          label={getAthleteName(option)}
                          customColor={option.colour}
                          deleteIcon={
                            <ClearOutlinedIcon
                              titleAccess={
                                option.isArchived ? t("group.overview.group_form_athletes_remove_item") : undefined
                              }
                              color={"primary"}
                              viewBox="-6 -6 36 36"
                            />
                          }
                          {...tagProps}
                          title={option.isArchived ? t("group.overview.group_form_athletes_archived_text") : undefined}
                          onDelete={option.isArchived ? undefined : tagProps.onDelete}
                          disabled={option.isArchived}
                        />
                      );
                    })
                  }
                />
              )}
            </Field>
            <Field name="athleteIds">
              {({ field }) => (
                <Mui5.Button
                  size="medium"
                  variant="text"
                  fullWidth
                  aria-label={t("group.overview.group_form_athletes_select_all_text")}
                  onClick={() => field.onChange({ target: { name: field.name, value: allSelectableAthletes } })}
                  disabled={field?.value === allSelectableAthletes}
                >
                  {t("group.overview.group_form_athletes_select_all_text")}
                </Mui5.Button>
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              role="button"
              variant="contained"
              color="primary"
              disableElevation
              disabled={props.submitDisabled}
              fullWidth
              aria-label={submitText}
            >
              {submitText}
            </Button>
          </Grid>
        </Grid>
      </Form.Wrapper>
    </Formik>
  );
}

GroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    description: PropTypes.string,
    colour: PropTypes.string,
    athleteIds: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }).isRequired
    ),
  }),
  athleteOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default GroupForm;
