import Box from "@mui/material/Box";
import OutputLogo302x44 from "./OutputLogo_302x44.svg";
import Typography from "@mui/material/Typography";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { errorMessages } from "./ScreenSizeToSmall.config";
import * as Components from "./ScreenSizeToSmall.components";
import { useTranslation } from "react-i18next";

function ScreenSizeToSmall() {
  const [dimensions, setDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });
  const { t } = useTranslation();

  const onDimensionChange = () => {
    setDimensions({ height: window.innerHeight, width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", onDimensionChange);

    return () => window.removeEventListener("resize", onDimensionChange);
  }, []);

  const TextBox = useCallback(
    (props: { children: string }) => (
      <Box pb={3} maxWidth={600}>
        <Typography variant="h1" gutterBottom>
          <Box component="span" color="common.white" fontWeight="fontWeightRegular">
            {props.children}
          </Box>
        </Typography>
      </Box>
    ),
    []
  );

  if (!(dimensions.width <= 960)) {
    return null;
  }

  return (
    <Components.ContainerBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
    >
      <Box textAlign="center">
        <Box pb={4}>
          <img src={OutputLogo302x44} alt={t("global.logo_stripes_alt_text")} />
        </Box>
        <TextBox>{errorMessages.warning}</TextBox>
        <TextBox>{errorMessages.suggestion}</TextBox>
      </Box>
    </Components.ContainerBox>
  );
}

export default ScreenSizeToSmall;
