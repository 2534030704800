import usePreferences from "hooks/usePreferences";
import { Exercise, MeasurementSet } from "types";
import { useCallback, useEffect, useState } from "react";
import SetComparisonChartService from "services/SetComparisonChartService";
import { firstBy } from "thenby";
import { SetComparisonFilter } from "../../../../../ModuleModal.types";
import FormAutoComplete from "components/Form/FormAutocomplete";
import Mui5 from "components/mui5";
import moment from "moment";
import UnitHelper from "helpers/UnitHelper";
import { useTranslation } from "react-i18next";

interface AthleteSetComparisonChartModuleFormSetSelectorProps {
  name: string;
  athleteId: string;
  exercise: Exercise | null;
  sets: SetComparisonFilter[];
}

function AthleteSetComparisonChartModuleFormSetSelector(props: AthleteSetComparisonChartModuleFormSetSelectorProps) {
  const { athleteId, exercise, sets } = props;
  const [options, setOptions] = useState<SetComparisonFilter[]>([]);
  const { t } = useTranslation();
  const [{ imperialSystemEnabled }] = usePreferences();

  const makeLabel = useCallback(
    (set: MeasurementSet) => {
      const value =
        set.weightValue &&
        UnitHelper.process(set.weightValue, {
          from: UnitHelper.units.Kilogram,
          to: imperialSystemEnabled && UnitHelper.units.Pound,
          decimals: 0,
        });

      const translatedSting = t("reports.set_comparison_chart_name", {
        value,
        variant: set.variant,
        sets: set.setNumber,
        reps: set.repetitionCount,
        date: moment(set.completedDate).format("DD MMM YYYY"),
        context: set.variant ? "variant" : undefined,
      });

      return translatedSting;
    },
    [t, imperialSystemEnabled]
  );

  useEffect(() => {
    let completePromise = true;

    (async () => {
      if (!exercise?.id) return;

      const sets = await SetComparisonChartService.getSets(athleteId, { exerciseId: exercise?.id });

      if (!completePromise) {
        return;
      }

      const labelledSets = sets.map((set) => ({
        exerciseMeasurementId: set.measurementId,
        completedDate: set.completedDate,
        setNumber: set.setNumber,
        name: makeLabel(set),
      }));
      const sortedSets = [...labelledSets].sort(firstBy("completedDate", "desc"));
      setOptions(sortedSets);
    })();

    return () => {
      completePromise = false;
    };
  }, [athleteId, exercise?.id, makeLabel]);

  return (
    <FormAutoComplete
      name={props.name}
      multiple
      options={options}
      disabled={!options.length}
      defaultValue={[]}
      disableCloseOnSelect
      renderTags={(tagValue: SetComparisonFilter[], getTagProps: any) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Mui5.Chip label={option.name} {...tagProps} />;
        })
      }
      limitTags={4}
      getOptionDisabled={(opt: SetComparisonFilter) => {
        return sets.length >= 4 && !Boolean(sets.find((set) => set === opt));
      }}
      getOptionLabel={(option: SetComparisonFilter) => option.name || ""}
      renderInput={(params: any) => (
        <Mui5.TextField
          {...params}
          label={t("global.set_label_other")}
          placeholder={t("global.select_sets_placeholder")}
          variant="standard"
        />
      )}
      isOptionEqualToValue={(option: SetComparisonFilter, value: SetComparisonFilter) => option.name === value?.name}
    />
  );
}
export default AthleteSetComparisonChartModuleFormSetSelector;
