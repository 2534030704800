import Typography from "@mui/material/Typography";
import { Formik, Form } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Mui5 from "components/mui5";
import useRequest from "hooks/useRequest";
import { schema } from "./SettingsOrganisationEdit.schema";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";
import FormText from "components/Form/FormText";
import { useTranslation } from "react-i18next";

function SettingsOrganisationEdit() {
  const organisation = useOrganisation();
  const organisationActions = useOrganisationActions();
  const updateOrganisation = useRequest(organisationActions.update, true);
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{ name: organisation.name }}
      enableReinitialize
      onSubmit={updateOrganisation}
      validationSchema={schema}
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <Grid container spacing="24px" maxWidth={400}>
            <Grid item xs={12}>
              <Typography variant="h3">
                <Box component="span" fontWeight="fontWeightMedium">
                  {t("settings.edit_organisation_info")}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormText
                name="name"
                label={t("settings.edit_organisation_info_name")}
                id="user-organisation-input"
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Mui5.Button
                disabled={!dirty || isSubmitting}
                color="primary"
                fullWidth
                variant="contained"
                type="submit"
                aria-label={t("settings.save_organisation_info_changes_aria_label")}
              >
                {t("global.save_changes")}
              </Mui5.Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default SettingsOrganisationEdit;
