import useArchivedCustomExercises from "providers/ExercisesProvider/useArchivedCustomExercises";
import Error from "@mui/icons-material/Error";
import { useMemo } from "react";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import useCanDo from "hooks/useCanDo";
import { ExerciseType } from "types";

export interface ArchivedExerciseErrorIndicatorProps {}

function ArchivedExerciseErrorIndicator(props: { exerciseId?: string }) {
  const { t } = useTranslation();
  const customExercisesEnabled = useCanDo()("library.any");
  const archivedExercises = useArchivedCustomExercises();
  const exercise = useMemo(
    () => archivedExercises.find((exercise) => exercise.id === props.exerciseId),
    [archivedExercises, props.exerciseId]
  );
  const isArchived = useMemo(() => Boolean(props.exerciseId && exercise), [exercise, props.exerciseId]);

  const title = useMemo(() => {
    if (exercise?.type !== ExerciseType.Custom) {
      return null;
    }

    return customExercisesEnabled && isArchived
      ? t("library.archived_exercise_tooltip")
      : !customExercisesEnabled
        ? t("library.custom_exercises_feature_disabled_tooltip")
        : null;
  }, [t, customExercisesEnabled, exercise?.type, isArchived]);

  if (!title) return null;

  return (
    <Tooltip arrow placement="right" title={title}>
      <Error color="error" />
    </Tooltip>
  );
}

export default ArchivedExerciseErrorIndicator;
