import { v4 as uuid } from "uuid";
import { Exercise, ExerciseMetric } from "../types/Exercise.types";
import {
  WeightTypes,
  WorkoutStep,
  WorkoutTask,
  WorkoutTaskCustomExerciseType,
  WorkoutTaskSetMetadata,
  WorkoutTaskType,
} from "../../Workouts.types";
import { customExerciseConfigMetadata } from "../WorkoutStepsList/WorkoutStepPreviewExerciseSubTitle/WorkoutStepPreviewExerciseSubTitle.config";
import type { TFunction } from "i18next";

export function baseStep(): WorkoutStep {
  return {
    id: uuid(),
    tasks: [],
  };
}

export function baseTask({
  exercise,
  setMetadata,
}: {
  exercise: Exercise;
  setMetadata: WorkoutTaskSetMetadata[];
}): WorkoutTask {
  return {
    id: uuid(),
    note: "",
    type: WorkoutTaskType.EXERCISE,
    weightType: WeightTypes.ABSOLUTE,
    exercise: exercise,
    restPeriod: null,
    useSensor: null,
    setMetadata: setMetadata,
  };
}

export function createSingleMeasurementStep(exercise: Exercise): WorkoutStep {
  return {
    ...baseStep(),
    tasks: [
      baseTask({
        exercise,
        setMetadata: [
          {
            id: uuid(),
            repCount: null,
            amrap: false,
            weight: NaN,
            percentage1Rm: NaN,
            variant: null,
            configuration: null,
          },
        ],
      }),
    ],
  };
}

export function createCustomExerciseTask(): WorkoutTaskCustomExerciseType {
  return {
    id: uuid(),
    title: "",
    type: WorkoutTaskType.CUSTOM_EXERCISE,
    note: "",
    weightType: WeightTypes.ABSOLUTE,
    restPeriod: null,
    setMetadata: [
      {
        id: uuid(),
        repCount: null,
        amrap: false,
        weight: NaN,
        percentage1Rm: NaN,
        variant: null,
        configuration: null,
      },
    ],
    options: {
      withReps: false,
      withVariants: false,
    },
  };
}

/**
 * @description Check if all values in an array are equal to the first value
 * for example: [1, 1, 1] => true
 * for example: [1, 2, 1] => false
 */
function checkAllValuesMatch(allValues: (number | "∞" | null)[], firstValue: number | "∞" | null | undefined): boolean {
  return !allValues.filter((value) => value !== firstValue).length;
}

/**
 * @description Create a subtitle for an exercise based on the set metadata
 * At the moment we append to the i18nKey to get the correct translation, but it becomes hard to manage
 * we should consider creating a new translation and interpolated translated strings, like:
 *
 * const trans = {
 *  "workouts.exercise_subtitle_set": "{{count}} set",
 *  "workouts.exercise_subtitle_set_other": "{{count}} sets",
 *  "workouts.exercise_subtitle_rep": "{{count}} rep",
 *  "workouts.exercise_subtitle_rep_other": "{{count}} reps",
 *
 *  // 1 set of 1 rep @ 100% | 1 set of 10 reps @ 50% | 1 set of ∞ reps @ 50% | 2 sets of (10/8) reps @ 50/80 %
 *  "workouts.exercise_subtitle_set_of_reps_at_one_rep_max": "{{sets}} of {{reps}} @ {{percentage}}",
 *  // 1 set of 1 rep x 20kg | 1 set of 10 reps x 20kg | 1 set of ∞ reps x 20kg | 2 sets of (10/8) reps x 20/30kg
 *  "workouts.exercise_subtitle_set_of_reps_times_weight": "{{sets}} of {{reps}} x {{weight}}",   // 1 set of 10 reps x 20kg
 *  // 1 set of 1 rep | 1 set of 10 reps | 1 set of ∞ reps | 2 sets of (10/8) reps
 *  "workouts.exercise_subtitle_set_of_reps": "{{sets}} of {{reps}}",
 * };
 *
 */

// TODO: We will probably need to refactor this function to account for different translation rules

export function makeSubtitle(
  setMetadata: WorkoutTaskSetMetadata[],
  weightMetadata: ExerciseMetric | undefined,
  t: TFunction,
  formatUnit: (value: any, metricMetadata: any) => string | undefined
): string {
  const setCount = setMetadata.length;
  const allReps = setMetadata.map((set) => (set.amrap ? "∞" : set.repCount)).filter(Boolean) as (number | "∞")[];
  const firstRep = [...allReps].shift();
  const repCountsMatch = checkAllValuesMatch(allReps, firstRep);
  const allPerc1RMs = setMetadata.map((set) => Number(set.percentage1Rm) - Number.EPSILON);
  const firstPerc1RM = Number([...allPerc1RMs].shift());
  const perc1RMsMatch = checkAllValuesMatch(allPerc1RMs, firstPerc1RM);
  const allWeights = setMetadata.map((set) => set.weight);
  const firstWeight = Number([...allWeights].shift());
  const weightsMatch = checkAllValuesMatch(allWeights, firstWeight);

  const roundPercentage = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100;

  let i18nKey = "workouts.exercise_subtitle";

  if (setCount > 1) {
    i18nKey += "_set_other";
  } else {
    i18nKey += "_set_one";
  }

  if (allReps.length) {
    if (allReps.every((rep) => rep === "∞")) {
      i18nKey += "_rep_other";
    } else if (repCountsMatch && parseInt(String(firstRep)) === 1) {
      i18nKey += "_rep_one";
    } else {
      i18nKey += "_rep_other";
    }
  }

  if (!isNaN(firstPerc1RM)) {
    i18nKey += "_at_one_rep_max";
  } else if (!isNaN(firstWeight)) {
    i18nKey += "_of_times_weight";
  }

  const repsValue = repCountsMatch ? firstRep : `(${allReps.map((rep) => rep || "?").join("/")})`;

  /**
   * @description Power can either be a percentage or a weight value
   * If 1RM show percentage, otherwise show weight
   */
  const powerValue = !isNaN(firstPerc1RM)
    ? /**
       * @description If all percentages match, show the first percentage, otherwise show all percentages
       */
      perc1RMsMatch
      ? `${roundPercentage(firstPerc1RM)}%`
      : `${allPerc1RMs.map((perc) => roundPercentage(perc) || "-").join("/")} %`
    : /**
       * @description If all weights match, show the first weight, otherwise show all weights
       */
      weightsMatch
      ? formatUnit(firstWeight, weightMetadata ? weightMetadata : customExerciseConfigMetadata)
      : allWeights
          .map((weight) => formatUnit(weight, weightMetadata ? weightMetadata : customExerciseConfigMetadata) || "-")
          .join("/");

  const values = {
    sets: setCount,
    reps: repsValue,
    power: powerValue,
  };

  const translated = t(i18nKey, values);

  return translated;
}
