import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { Measurement } from "types";
import * as Components from "./ActivityCardTable.components";
import { Exercise } from "types";
import useUnitFormatter from "hooks/useUnitFormatter";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

interface ActivityCardTableProps {
  measurements: Measurement[];
  exercise: Exercise;
}

function ActivityCardTable(props: ActivityCardTableProps) {
  const { measurements, exercise } = props;
  const formatUnit = useUnitFormatter();
  const { t } = useTranslation();
  const defaultMetricMetadata = exercise.metrics.find((exerciseMetric) => exerciseMetric.isDefault);
  const weightMetadata = exercise.metrics.find((exerciseMetric) => exerciseMetric.field === "weight");

  const showWeight = Boolean(weightMetadata);
  const showReps = Boolean(exercise.repetitions.length);
  const showVariant = Boolean(exercise.variants.length);
  const showDefaultMetric = !showWeight;

  const gridRowProps = { item: true, xs: true };

  return (
    <Box pt={1}>
      <Grid container wrap="nowrap">
        <Grid {...gridRowProps} xs={false} width={48}>
          <Components.TableHead>{t("global.time_label_text")}</Components.TableHead>
        </Grid>
        {showReps && (
          <Grid {...gridRowProps}>
            <Components.TableHead>{t("global.reps_label_text")}</Components.TableHead>
          </Grid>
        )}
        {showWeight && (
          <Grid {...gridRowProps}>
            <Components.TableHead>{t("global.weight_label_text")}</Components.TableHead>
          </Grid>
        )}
        {showVariant && (
          <Grid {...gridRowProps}>
            <Components.TableHead>{t("global.variant_label_text")}</Components.TableHead>
          </Grid>
        )}
        {showDefaultMetric && defaultMetricMetadata && (
          <Grid {...gridRowProps}>
            <Components.TableHead title={defaultMetricMetadata.name}>{defaultMetricMetadata.name}</Components.TableHead>
          </Grid>
        )}
      </Grid>
      {measurements.map((measurement, measurementIndex) => {
        const weight = measurement.metrics.find((metric) => metric.field === "weight")?.value;
        const defaultMetricValue = measurement.metrics.find(
          (metric) => metric.field === defaultMetricMetadata?.field
        )?.value;

        return (
          <Fragment key={measurement.id}>
            <Box pt={1} pb={1}>
              <Grid container wrap="nowrap" alignContent="center" justifyContent="flex-start">
                <Grid {...gridRowProps} xs={false} width={48}>
                  <Components.TableData title={moment(measurement.completedDate).toLocaleString()}>
                    {moment(measurement.completedDate).format("HH:mm")}
                  </Components.TableData>
                </Grid>
                {showReps && (
                  <Grid {...gridRowProps}>
                    <Components.TableData>
                      {measurement.metrics.find((metric) => metric.field === "repCount")?.value}
                    </Components.TableData>
                  </Grid>
                )}
                {showWeight && (
                  <Grid {...gridRowProps}>
                    <Components.TableData>{formatUnit(weight, weightMetadata)}</Components.TableData>
                  </Grid>
                )}
                {showVariant && measurement.variant && (
                  <Grid {...gridRowProps}>
                    <Components.TableData>{measurement.variant.slice(0, 1)}</Components.TableData>
                  </Grid>
                )}
                {showDefaultMetric && defaultMetricMetadata && (
                  <Grid {...gridRowProps}>
                    <Components.TableData
                      title={`${defaultMetricMetadata.name} ${formatUnit(defaultMetricValue, defaultMetricMetadata)}`}
                    >
                      {formatUnit(defaultMetricValue, defaultMetricMetadata)}
                    </Components.TableData>
                  </Grid>
                )}
              </Grid>
            </Box>
            {measurementIndex < measurements.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </Box>
  );
}

export default ActivityCardTable;
