import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import useRole from "providers/RoleProvider/useRole";
import { useMemo } from "react";
import PageWarning from "components/PageWarning";
import Typography from "@mui/material/Typography";
import moment from "moment";
import useRemoteConfig from "providers/RemoteConfigProvider/useRemoteConfig";
import { Trans } from "react-i18next";

export interface FeatureTrialEndingBannerProps {
  enabled: boolean;
}

function FeatureTrialEndingBanner(props: FeatureTrialEndingBannerProps) {
  const organisation = useOrganisation();
  const role = useRole();
  const expiryDate = useMemo(() => moment("2024-05-31"), []);
  const today = useMemo(() => moment(), []);
  const HUB_WORKOUTS_ENABLED_ENFORCEMENT = useRemoteConfig("HUB_WORKOUTS_ENABLED_ENFORCEMENT");

  const show = useMemo(() => {
    return role === "OWNER" && !organisation?.featureConfig.isWorkoutsEnabled && today.isBefore(expiryDate);
  }, [role, organisation, today, expiryDate]);

  if (!props.enabled || HUB_WORKOUTS_ENABLED_ENFORCEMENT) return null;

  return (
    <PageWarning
      show={show}
      message={
        <Typography>
          <Trans
            i18nKey="components.feature_trial_end_message"
            values={{
              expiryDate: expiryDate.format("MMMM Do YYYY"),
              expirationTime: expiryDate.fromNow(),
            }}
          >
            Your free trial of the <strong>Workouts</strong> and <strong>Exercise Library</strong> features are due to
            expire {""}
            {expiryDate.fromNow()}. If you would like to continue to use them, please send a message to a member of the
            team at support@outputsports.com and they can outline the next steps. Should you choose not to upgrade, your
            account will automatically revert back to your current plan on{" "}
            <strong>{expiryDate.format("MMMM Do YYYY")}</strong>.
          </Trans>
        </Typography>
      }
    />
  );
}

export default FeatureTrialEndingBanner;
