class LocalStorageHelper {
  static keys = {
    advancedOptionsOpen: "OutputHub:chartOptions:advancedOptionsOpen",
    beginYAxesAtZero: "OutputHub:chartOptions:beginYAxesAtZero",
    drawerMenuOpen: "OutputHub:drawerMenu:open",
    showAverageValue: "OutputHub:chartOptions:showAverageValue",
    /** @deprecated
     * Key has already been used and should not be used again.
     */
    showDashboardWelcomeDialog: "OutputHub:showDashboardWelcomeDialog",
    showOneRepMax: "OutputHub:chartOptions:showOneRepMax",
    showPersonalBest: "OutputHub:chartOptions:showPersonalBest",
    showStandardDeviation: "OutputHub:chartOptions:showStandardDeviation",
    showGroupDashboardOnBoardingTooltip: "OutputHub:dashboards:showGroupDashboardOnBoardingTooltip",
    showPredefinedVelocityTargetsOnBoardingTooltip: "OutputHub:workouts:showPredefinedVelocityTargetsOnBoardingTooltip",
  };

  /**
   * @param {string} keyName
   * @param {null | boolean | undefined} defaultValue
   */
  static get(keyName, defaultValue = null) {
    return JSON.parse(localStorage.getItem(keyName) || defaultValue);
  }

  static set(keyName, keyValue) {
    return localStorage.setItem(keyName, keyValue ?? null);
  }
}
export default LocalStorageHelper;
