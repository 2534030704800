import useRequest from "hooks/useRequest";
import { useCallback, useMemo } from "react";
import { useNotifications, useUserContext } from "../";
import UserService from "../../services/UserService";
import { useTranslation } from "react-i18next";

/**
 * @returns {[
 *  {
 *    imperialSystemEnabled: boolean;
 *    defaultRagStatusTemplateId: string | null;
 *    defaultDashboardId: string | null;
 *    timeZone: string | null;
 *  }, {
 *    toggleImperialSystem(): Promise<void>;
 *    setDefaultRAGTemplate(defaultRagStatusTemplateId: string): Promise<void>;
 *    setDefaultDashboard(setDefaultDashboardId: string): Promise<void>;
 *    updateTimeZone(timeZone: string): Promise<void>;
 *  }]}
 */

function usePreferences() {
  const [{ profile }, { onUpdate }] = useUserContext();
  const { t } = useTranslation();
  const { onSuccess, onError } = useNotifications({
    error: t("errors.update_failed"),
    success: t("info.update_success"),
  });
  const apiToggleImperialSystem = useRequest(UserService.toggleImperialSystem, true);
  const apiSetDefaultRAGTemplate = useRequest(UserService.setDefaultRAGTemplate, true);
  const apiSetDefaultDashboardId = useRequest(UserService.setDefaultDashboard, true);
  const apiUpdateTimeZone = useRequest(UserService.updateTimeZone, true);

  const toggleImperialSystem = useCallback(async () => {
    const updatedImperialSystemEnabled = !profile?.preferences?.imperialSystemEnabled;
    try {
      await apiToggleImperialSystem(updatedImperialSystemEnabled);
      onUpdate({ preferences: { ...profile?.preferences, imperialSystemEnabled: updatedImperialSystemEnabled } });
      onSuccess();
    } catch (_) {
      onError();
    }
  }, [apiToggleImperialSystem, onError, onSuccess, onUpdate, profile?.preferences]);

  const setDefaultRAGTemplate = useCallback(
    async (defaultRagStatusTemplateId) => {
      try {
        await apiSetDefaultRAGTemplate(defaultRagStatusTemplateId);
        onUpdate({ preferences: { ...profile?.preferences, defaultRagStatusTemplateId } });
        onSuccess();
      } catch (_) {
        onError();
      }
    },
    [apiSetDefaultRAGTemplate, onError, onSuccess, onUpdate, profile?.preferences]
  );
  const setDefaultDashboard = useCallback(
    async (defaultDashboardId) => {
      try {
        await apiSetDefaultDashboardId(defaultDashboardId);
        onUpdate({ preferences: { ...profile?.preferences, defaultDashboardId } });
        onSuccess();
      } catch (_) {
        onError();
      }
    },
    [apiSetDefaultDashboardId, onError, onSuccess, onUpdate, profile?.preferences]
  );

  const updateTimeZone = useCallback(
    async (timeZone) => {
      try {
        await apiUpdateTimeZone(timeZone);
        onUpdate({ preferences: { ...profile?.preferences, timeZone } });
        onSuccess();
      } catch (e) {
        onError();
      }
    },
    [apiUpdateTimeZone, onError, onSuccess, onUpdate, profile?.preferences]
  );

  const actionHandlers = useMemo(
    () => ({
      toggleImperialSystem,
      setDefaultRAGTemplate,
      setDefaultDashboard,
      updateTimeZone,
    }),
    [toggleImperialSystem, setDefaultRAGTemplate, setDefaultDashboard, updateTimeZone]
  );

  return [profile?.preferences || {}, actionHandlers];
}
export default usePreferences;
