import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AthleteName from "components/AthleteName";
import MonitoringIndicatorsRow from "components/Monitoring/MonitoringIndicatorsRow";
import { Athlete } from "types/athlete";
import Checkbox from "components/mui5/Checkbox";
import { useMultiSelectIds, useSetMultiSelect } from "providers/MultiSelectProvider";
import Constants from "config/Constants";
import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";
import * as Components from "./AthleteTableRow.components";
import { memo, useCallback } from "react";
import GroupColorBlocks from "./GroupColorBlocks";
import { tableCells } from "../AthleteTable.config";
import CanDoAction from "components/CanDoAction";
import Tooltip from "components/Tooltip";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AthleteHelpers from "helpers/AthleteHelpers";

interface AthleteTableRowProps {
  athlete: Athlete;
}

function AthleteTableRow(props: AthleteTableRowProps) {
  const { athlete } = props;
  const selectedIds = useMultiSelectIds();
  const setSelectedIds = useSetMultiSelect();

  const toggleSelected = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      setSelectedIds({ [athlete.id]: !selectedIds.includes(athlete.id) });
    },
    [athlete.id, selectedIds, setSelectedIds]
  );

  const { t } = useTranslation();

  const athleteFullName = AthleteHelpers.getFullName(athlete);

  return (
    <Box
      key={athlete.id}
      component={Components.TableRowButton}
      variant="text"
      href={`${Constants.routes.athletes.route}/${athlete.id}`}
    >
      <Grid container width="100%" alignItems="center" minHeight={56}>
        <Grid {...tableCells.groupColorBlocks}>
          <CanDoAction action="group.view">
            <Components.ColorBlocksContainer>
              <GroupColorBlocks {...props.athlete} />
            </Components.ColorBlocksContainer>
          </CanDoAction>
        </Grid>
        <Grid {...tableCells.checkbox}>
          <Checkbox
            checkedIcon={<CheckBoxOutlined />}
            inputProps={{ "aria-label": t("athlete.select_athlete_name_aria_label", { name: athleteFullName }) }}
            checked={selectedIds.includes(athlete.id)}
            onClick={toggleSelected}
          />
        </Grid>
        <Tooltip
          title={
            <Box p={0.5}>
              <Typography fontSize={12} color="grey.800" textAlign="left">
                {t("athlete.last_measurement_tooltip_text")}
              </Typography>
              <Typography color="primary" textAlign="left">
                {athlete.lastTested
                  ? moment(athlete.lastTested).fromNow()
                  : t("athlete.last_measurement_tooltip_no_measurements_text")}
              </Typography>
            </Box>
          }
          placement="left"
          arrow
        >
          <Grid {...tableCells.name}>
            <AthleteName athlete={athlete} id={athlete.id} />
          </Grid>
        </Tooltip>
        <Grid {...tableCells.monitoringIndicators}>
          <Box height="100%" py={0}>
            <MonitoringIndicatorsRow athleteId={athlete.id} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(AthleteTableRow);
