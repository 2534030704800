import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useChartDataActions from "components/charting/hooks/useChartDataActions";
import { useRequest } from "hooks";
import TrashIcon2 from "components/Icons/TrashIcon2";
import { useCallback, useState } from "react";
import MeasurementService from "services/MeasurementService";
import { useTranslation } from "react-i18next";

interface TooltipCardActionsProps {
  measurementId: string;
}

function TooltipCardActions(props: TooltipCardActionsProps) {
  const { measurementId } = props;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const chartDataActions = useChartDataActions();
  const deleteMeasurementById = useRequest(MeasurementService.deleteById);

  const handleDelete = useCallback(() => {
    setShowConfirmDelete(true);
  }, []);
  const handleCancel = useCallback(() => {
    setShowConfirmDelete(false);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    try {
      await deleteMeasurementById(measurementId);
      chartDataActions.deleteMeasureById({ id: measurementId });
      handleCancel();
    } catch (e) {}
  }, [measurementId, deleteMeasurementById, chartDataActions, handleCancel]);

  const { t } = useTranslation();

  return (
    <Grid container height={34} alignItems="center">
      <Grid item xs />
      {showConfirmDelete ? (
        <>
          <Grid item>
            <Typography variant="caption">{t("charting.delete_confirmation_message")}</Typography>
          </Grid>
          <Grid item>&nbsp;</Grid>
          <Grid item>
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={handleConfirmDelete}
              disableElevation
              sx={{ textTransform: "capitalize" }}
            >
              {t("global.confirm_button_text")}
            </Button>
          </Grid>
          <Grid item>&nbsp;</Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              onClick={handleCancel}
              disableElevation
              sx={{ textTransform: "capitalize" }}
            >
              {t("global.cancel_button_text")}
            </Button>
          </Grid>
        </>
      ) : (
        <Grid item>
          <IconButton
            color="error"
            size="small"
            onClick={handleDelete}
            aria-label={t("charting.delete_exercise_measurement")}
          >
            <TrashIcon2 />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default TooltipCardActions;
