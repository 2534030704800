export type OutputErrorCodes =
  | "output_auth/account-type-not-allowed"
  | "output_auth/osRole-unset-on-user-claims"
  | "output_auth/organisationId-unset-on-user-claims"
  | "output_auth/athlete-role-not-allowed"
  | "output_auth/auth-token-claims-invalid";

class OutputError extends Error {
  code: OutputErrorCodes;

  constructor({ code, message }: { code: OutputErrorCodes; message?: string }) {
    super(message);

    this.code = code;
  }
}
export default OutputError;
