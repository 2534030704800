import ButtonGroup from "components/ButtonGroup";
import CalendarIcon from "components/IconsStandardized/CalendarIcon";
import moment from "moment";
import useChartOptions from "features/Charting/hooks/useChartOptions";
import useChartSelections from "components/charting/hooks/useChartSelections";
import useSetChartSelections from "features/Charting/hooks/useSetChartSelections";
import { ChartingSelections } from "components/charting/types";
import { useCallback, useMemo, useRef, useState } from "react";
import PopoverDateRangePicker from "components/Inputs/PopoverDateRangePicker";
import { useTranslation } from "react-i18next";

interface TimeSelectorProps {}

function TimeSelector(props: TimeSelectorProps) {
  const [pickerOpen, setPickerOpen] = useState(false);
  const buttonGroupRef = useRef<HTMLDivElement>(null);
  const options = useChartOptions();
  const selections = useChartSelections();
  const setSelections = useSetChartSelections();
  const { t } = useTranslation();

  const customPeriodOption = useMemo(
    () => ({
      label: <CalendarIcon fontSize="small" color="inherit" />,
      value: null,
    }),
    []
  );

  const periodOptions = useMemo<ChartingSelections["period"][]>(() => {
    const translatedPeriods = options.period.map((option) => ({
      ...option,
      label: typeof option.label === "string" ? t(option.label) : option.label,
    }));
    return [...translatedPeriods, customPeriodOption];
  }, [t, customPeriodOption, options.period]);

  const setPeriod = useCallback(
    (period: ChartingSelections["period"]) => {
      if (period.value === null) {
        setPickerOpen(true);
        return;
      }

      const endDate = moment().endOf("day");
      const startDate = moment().endOf("day").clone().subtract(1, period.value);

      setSelections({
        period,
        startDate,
        endDate,
      });
    },
    [setSelections]
  );

  return (
    <>
      <ButtonGroup ref={buttonGroupRef} setOption={setPeriod} value={selections.period} options={periodOptions} />
      <PopoverDateRangePicker
        anchorEl={buttonGroupRef.current}
        endDate={selections.endDate}
        open={pickerOpen}
        setPeriod={(startDate: moment.Moment, endDate: moment.Moment) => {
          setSelections({
            period: customPeriodOption,
            startDate,
            endDate,
          });
        }}
        setOpen={setPickerOpen}
        startDate={selections.startDate}
      />
    </>
  );
}

export default TimeSelector;
