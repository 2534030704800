import WorkoutHelpers from "helpers/WorkoutHelpers";
import { useRequest } from "hooks";
import { Workout } from "features/Workouts/Workouts.types";
import useRole from "providers/RoleProvider/useRole";
import { useCallback, useMemo } from "react";
import Tooltip from "components/Tooltip";
import Box from "@mui/material/Box";
import Tables from "components/Tables";
import allWorkoutsConfig from "features/Workouts/AllWorkouts/AllWorkouts.config";
import Alert from "@mui/material/Alert";
import PublishedStatusIconButton from "./components/PublishedStatusIconButton";
import useWorkoutsActions from "features/Workouts/providers/WorkoutsProvider/useWorkoutsActions";
import { useTranslation } from "react-i18next";

function WorkoutPublishSwitch(props: Workout) {
  const actions = useWorkoutsActions();
  const role = useRole();
  const toggleWorkoutPublishId = useRequest(actions.togglePublishById);
  const { t } = useTranslation();
  const handlePublish = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      toggleWorkoutPublishId(props.id, !props.isPublished);
    },
    [props.id, props.isPublished, toggleWorkoutPublishId]
  );

  const canTogglePublished = WorkoutHelpers.canTogglePublished(props, { role });
  const isChangeAffectingUnknownAthletesOrGroups = WorkoutHelpers.isChangeAffectingUnknownAthletesOrGroups(props);

  const hoverTooltipText = useMemo(() => {
    let tooltipText: React.ReactNode = (
      <Box>{props.isPublished ? t("global.published_text") : t("global.unpublished_text")}</Box>
    );

    if (role !== "OWNER" && !canTogglePublished) {
      return allWorkoutsConfig.publish.errors.orgOwnerOnly;
    }

    if (role !== "OWNER" && canTogglePublished && isChangeAffectingUnknownAthletesOrGroups) {
      return (
        <Alert sx={{ m: 0, py: 0 }} variant="filled" severity="warning">
          {allWorkoutsConfig.publish.warnings.isShared}
        </Alert>
      );
    }

    return tooltipText;
  }, [t, canTogglePublished, isChangeAffectingUnknownAthletesOrGroups, props.isPublished, role]);

  const PublishedStatusIcon = props.isPublished ? Tables.Icons.Visible : Tables.Icons.Hidden;

  return (
    <Tooltip title={hoverTooltipText} placement="left" arrow>
      <span>
        <PublishedStatusIconButton
          onClick={handlePublish}
          aria-label={t("workouts.published_workout_status_aria_label", { name: props.name })}
          disabled={!canTogglePublished}
          published={props.isPublished}
        >
          <PublishedStatusIcon />
        </PublishedStatusIconButton>
      </span>
    </Tooltip>
  );
}

export default WorkoutPublishSwitch;
