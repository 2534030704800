import Login from "../features/Login";
import AthleteHub from "../components/AthleteHub/AthleteHub";
import CreateAthlete from "../components/AthleteHub/CreateAthlete";
import EditAthlete from "../components/AthleteHub/EditAthlete/EditAthlete";
import CreateGroup from "../components/Groups/CreateGroup/CreateGroup";
import EditGroup from "../components/Groups/EditGroup/EditGroup";
import ReportGeneration from "../components/ReportGeneration/ReportGeneration";
import CreateReport from "../components/ReportGeneration/CreateReport/CreateReport";
import CreateAthleteReport from "../components/ReportGeneration/CreateReport/AthleteReport/AthleteReport";
import CreateGroupReport from "../components/ReportGeneration/CreateReport/GroupReport/GroupReport";
import DataExport from "../features/DataExport";

const Constants = {
  colors: {
    black: "#000",
    borderDark: "#4C4C4C",
    darkBg: "#0D1724",
    darkBgTransparent: "rgba(13, 23, 36, 0.85)",
    transparentBlack: "rgba(0, 0, 0, 0.12)",
    darkBgHover: "#202A37",
    linkBlue: "#39D0FF",
    globalBlue: "#2980B9",
    white: "#FFF",
    whiteFourty: "rgba(255, 255, 255, 0.4)",
    whiteFifty: "rgba(255, 255, 255, 0.5)",
    whiteSeventyFive: "rgba(255, 255, 255, 0.75)",
    buttonCyan: "#83E2C2",
    globalGreen: "#1CE782",
    globalYellow: "#F2E148",
    globalOrange: "#FFB039",
    globalRed: "#C0392B",
    lightGrey: "#F6F6F6",
    labelGrey: "#828282",
    plholderGrey: "#B1B1B1",
    valueGrey: "#4D4D4D",
    iconGrey: "#656565",
    titleGrey: "#9F9F9F",
    alto: "#D9D9D9",
    downy: "#6FCEAE",
  },
  shadows: {
    uiMain: "box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.12)",
  },
  routes: {
    login: {
      titleKey: "pages.login_title",
      route: "/login",
      component: Login,
    },
    home: {
      titleKey: "pages.home_title",
      route: "/",
      component: AthleteHub,
    },
    settings: {
      titleKey: "pages.settings_title",
      route: "/settings",
    },
    athletes: {
      titleKey: "pages.athletes_title",
      route: "/athlete-hub",
      component: AthleteHub,
    },
    athletesAdd: {
      titleKey: "pages.athletes_add_title",
      route: "/athlete-hub/add",
      component: CreateAthlete,
    },
    athletesBulkUpload: {
      titleKey: "pages.athletes_bulk_upload_title",
      route: "/athlete-hub/bulk-upload",
    },
    athletesEdit: {
      titleKey: "pages.athletes_edit_title",
      route: "/athlete-hub/:id/edit/",
      component: EditAthlete,
    },
    athletesSingle: {
      titleKey: "pages.athletes_profile_title",
      route: "/athlete-hub/:id",
    },
    bulkUpload: {
      route: "/bulk-upload",
    },
    groups: {
      titleKey: "group.all.page_heading_title",
      route: "/groups",
    },
    groupsAdd: {
      titleKey: "group.all.add_new_group_page_heading_title",
      route: "/groups/add",
      component: CreateGroup,
    },
    groupsEdit: {
      titleKey: "group.overview.edit_group_page_heading",
      route: "/groups/:id/edit/",
      component: EditGroup,
    },
    groupsSingle: {
      titleKey: "group.overview.group_header_text",
      route: "/groups/:id",
    },
    leaderBoard: {
      titleKey: "pages.leaderboards_title",
      route: "/leaderboards",
    },
    reportGeneration: {
      titleKey: "pages.reports_title",
      route: "/reports",
      component: ReportGeneration,
    },
    exportData: {
      titleKey: "pages.export_data_title",
      route: "/export",
      component: DataExport,
    },
    exerciseLibrary: {
      titleKey: "pages.exercise_library_title",
      route: "/exercise-library",
    },
    createReport: {
      titleKey: "pages.create_report_title",
      route: "/reports/add",
      component: CreateReport,
    },
    createAthleteReport: {
      titleKey: "pages.reports_create_athlete_report_title",
      route: "/reports/add/athlete-report",
      component: CreateAthleteReport,
    },
    useEditAthleteReport: {
      titleKey: "pages.reports_use_athlete_report_template_title",
      route: "/reports/use-athlete-report-tpl/:id",
      component: CreateAthleteReport,
    },
    createGroupReport: {
      titleKey: "pages.reports_create_group_report_title",
      route: "/reports/add/group-report",
      component: CreateGroupReport,
    },
    useEditGroupReport: {
      titleKey: "pages.reports_use_group_report_template_title",
      route: "/reports/use-group-report-tpl/:id",
      component: CreateGroupReport,
    },
  },
};

export default Constants;
