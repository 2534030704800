import { useState, useCallback, useMemo } from "react";
import Collapse from "@mui/material/Collapse";
import ThemeProvider2024 from "components/MuiThemeProvider/ThemeProvider2024";
import MfaCodeInput from "../MfaCodeInput";
import PasswordInput from "../PasswordInput";
import MfaService from "services/MfaService";
import UserService from "services/UserService";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import Div from "components/primitives/Div";

export interface ReauthenticationDialogContentProps {
  onSuccess: () => Promise<void> | void;
  onError?: (error?: any) => void;
  onCancel: () => void;
}

function ReauthenticationDialogContent(props: ReauthenticationDialogContentProps) {
  const { t } = useTranslation();
  const { onSuccess, onError, onCancel } = props;

  const [error, setError] = useState<null | Error>(null);
  const [showMfa, setShowMfa] = useState(false);
  const clearError = useCallback(() => setError(null), [setError]);

  const mfaService = useMemo(() => new MfaService("recaptcha-mfa-reauthentication-container"), []);

  const onReauthenticationComplete = useCallback(async () => {
    await onSuccess();
  }, [onSuccess]);

  /**
   * STEPS
   *
   * 1. Try password
   * 2. If MFA required an MFA error is thrown
   * 3. `init()` the MFAService and re-throw
   * 4. This skips the onSuccess callback until MFA has completed successfully.
   *
   * 4. If there is a throw but it is not and MFA error then set error
   * 5. On Password/MFA success call success callback
   */

  const onReauthenticateWithPassword = useCallback(
    async (password: string) => {
      clearError();

      try {
        await UserService.reauthenticateWithPassword(password);
        await onReauthenticationComplete();
      } catch (error: any) {
        if (error?.code === "auth/multi-factor-auth-required") {
          await mfaService.init(error);

          setShowMfa(true);
        } else {
          // Only set error if it is not an MFA request
          setError(error as Error);
        }

        onError?.(error);
      }
    },
    [clearError, mfaService, onError, onReauthenticationComplete]
  );

  const onRequestVerificationCode = useCallback(async () => {
    await mfaService.requestVerificationCode();
  }, [mfaService]);

  const onVerifyCode = useCallback(
    async (smsCode: string) => {
      clearError();

      try {
        await mfaService.verifyCode(smsCode);
        await onReauthenticationComplete();
      } catch (error) {
        setError(error as Error);
        onError?.(error);
      }
    },
    [clearError, mfaService, onError, onReauthenticationComplete]
  );

  return (
    <ThemeProvider2024>
      <Div id="reauthentication-dialog">
        <Div sx={{ mb: 1 }}>
          <Typography variant="TextM" fontWeight={500}>
            {t("reauthentication_provider.title")}
          </Typography>
        </Div>
        <Collapse in={!showMfa} unmountOnExit={false}>
          <PasswordInput
            onReauthenticate={onReauthenticateWithPassword}
            onCancel={onCancel}
            errorCode={(error as any)?.code}
          />
        </Collapse>
        <Collapse in={showMfa} unmountOnExit>
          <MfaCodeInput
            verifyCode={onVerifyCode}
            resendCode={onRequestVerificationCode}
            onCancel={onCancel}
            hint={mfaService.hint}
            errorCode={(error as any)?.code as string}
          />
        </Collapse>
      </Div>
      <Div id="recaptcha-mfa-reauthentication-container" sx={{ display: "none" }} />
    </ThemeProvider2024>
  );
}

export default ReauthenticationDialogContent;
