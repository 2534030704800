import Grid from "@mui/material/Grid";
import WellnessScore from "components/WellnessScore";
import { DateTime, Wellness } from "types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { DataVisualizationType } from "components/charting/config/chartTypes";
import { useMemo } from "react";
import MeasureHelpers from "features/Charting/helpers/MeasureHelpers";
import { useTranslation } from "react-i18next";
import useWellnessMetadata from "providers/WellnessMetadataProvider/useWellnessMetadata";

function AthleteProfileActivityFeedWellness(props: { wellness: Partial<Wellness>; id: string; date: DateTime }) {
  const { id, date } = props;
  const { t } = useTranslation();
  const wellnesses = useWellnessMetadata();

  const options = useMemo(
    () =>
      wellnesses.map((wellnessMetadata) => {
        return {
          ...wellnessMetadata,
          score: props.wellness[wellnessMetadata.field as keyof typeof props.wellness] as number,
          comment: props.wellness[wellnessMetadata.commentField as keyof typeof props.wellness] as string,
          label: {
            READINESS_TO_TRAIN: "Readiness",
            PHYSICAL_HEALTH: "Physical",
            PSYCHOLOGICAL_HEALTH: "Psych",
            SLEEP: "Sleep",
          }[wellnessMetadata.id] as string,
        };
      }),
    [wellnesses, props]
  );

  if (!Object.values(props.wellness).length) return null;

  return (
    <Box>
      <Box pt={1} pb={2}>
        <Typography variant="h3">
          <Box component="span" fontWeight="fontWeightBold">
            {t("athlete_profile_activity_feed_wellness.title")}
          </Box>
        </Typography>
      </Box>
      <Grid container wrap="nowrap" justifyContent="space-between">
        {options.map((option) => (
          <Grid key={option.id} item title={option.comment || undefined}>
            <RouterLink
              style={{ color: "inherit" }}
              to={MeasureHelpers.getAthleteProfileChartUrl(id, {
                visType: DataVisualizationType.LINE_CHART,
                metadataId: option.id,
                interval: "custom",
                start: moment(date).toISOString(),
              })}
            >
              <WellnessScore label={option.label} value={option.score} />
            </RouterLink>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default AthleteProfileActivityFeedWellness;
