import useCoaches from "providers/CoachesProvider/useCoaches";
import { useCallback, useMemo } from "react";
import * as Components from "./CoachesTable.components";
import { getColumnConfig } from "./CoachesTable.config";
import useGroupsContext from "hooks/useGroupsContext";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { Coach } from "types/coach";
import { useNavigate } from "react-router-dom";
import { firstBy } from "thenby";
import DataGrid from "components/DataGrid";
import { useTranslation } from "react-i18next";

interface CoachesTableProps {}

function CoachesTable(props: CoachesTableProps) {
  const coaches = useCoaches();
  const [groups] = useGroupsContext();
  const navigate = useNavigate();
  const getRowHeight = useCallback(() => "auto" as const, []);
  const isCellEditable = useCallback(() => false, []);
  const onRowClick = useCallback((params: GridRowParams<Coach>) => navigate(`${params.row.id}/edit`), [navigate]);
  const { t } = useTranslation();

  const sortedCoaches = useMemo(() => coaches.slice().sort(firstBy("name")), [coaches]);

  if (sortedCoaches.length === 0) {
    return <Components.DataGridComponents.NoRowsOverlay />;
  }

  return (
    <DataGrid
      rows={sortedCoaches}
      pagination
      getRowHeight={getRowHeight}
      onRowClick={onRowClick}
      density="comfortable"
      isCellEditable={isCellEditable}
      columns={getColumnConfig(groups, t)}
    />
  );
}

export default CoachesTable;
