import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useAthleteActions from "providers/AthletesProvider/useAthleteActions";
import Mui5 from "components/mui5";
import Alert from "@mui/material/Alert";
import AthleteName from "components/AthleteName";
import { useArchivedAthletes } from "providers/ArchivedAthletesProvider";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { firstBy } from "thenby";
import { sxAlertUnarchiveDisabled, sxPaperAthleteRow } from "./SettingsArchivedAthletesList.components";
import * as Components from "./SettingsArchivedAthletesList.components";
import useRole from "providers/RoleProvider/useRole";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";
import useAthleteLimits from "hooks/useAthleteLimits";
import DeleteIcon from "components/Tables/icons/DeleteIcon";
import EventHelpers from "helpers/EventHelpers";
import ConfirmActionIconButton from "components/ConfirmActionIconButton";
import { useTranslation } from "react-i18next";

interface SettingsArchivedAthletesListProps {}

function SettingsArchivedAthletesList(props: SettingsArchivedAthletesListProps) {
  const athleteActions = useAthleteActions();
  const archivedAthletes = useArchivedAthletes();
  const role = useRole();
  const athleteLimits = useAthleteLimits();
  const organisationActions = useOrganisationActions();
  const { t } = useTranslation();

  useEffect(() => {
    organisationActions.get();
  }, [organisationActions]);

  const unarchiveDisabled = athleteLimits.active.isFull;

  const unarchiveHandler = useCallback(
    (athleteId: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      athleteActions.toggleArchived(athleteId, false);
    },
    [athleteActions]
  );
  const handleDelete = useCallback(
    (athleteId: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();
      event.preventDefault();
      athleteActions.delete(athleteId);
    },
    [athleteActions]
  );

  const activeUsage = `${athleteLimits.active.count}/${athleteLimits.active.limit}`;
  const archivedUsage = `${athleteLimits.archived.count}/${athleteLimits.archived.limit}`;
  const alertMessage = useMemo(() => {
    if (role === "OWNER") {
      return t("settings.unarchive_athletes_owner_message", { activeUsage, archivedUsage });
    }
    return t("settings.unarchive_athletes_athlete_message", { activeUsage, archivedUsage });
  }, [activeUsage, archivedUsage, role, t]);

  return (
    <Grid container spacing="24px" maxWidth={400}>
      <Grid item xs={12}>
        {unarchiveDisabled && (
          <Alert icon={<ArchiveOutlinedIcon />} variant="filled" color="warning" sx={sxAlertUnarchiveDisabled}>
            {alertMessage}
          </Alert>
        )}
        {archivedAthletes.sort(firstBy("firstName").thenBy("lastName").thenBy("id")).map((archivedAthlete) => (
          <Mui5.Paper
            key={archivedAthlete.id}
            square
            sx={sxPaperAthleteRow}
            elevation={unarchiveDisabled ? 0 : undefined}
          >
            <Components.ArchivedAthleteButtonBase component={Link} to={`/athlete-hub/${archivedAthlete.id}`}>
              <Box px={2} py={1}>
                <Grid container alignItems="center" flexWrap="nowrap">
                  <Grid item xs zeroMinWidth>
                    <AthleteName athlete={archivedAthlete} id={archivedAthlete.id} />
                  </Grid>
                  <Grid item>
                    <Mui5.Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      disabled={unarchiveDisabled}
                      onClick={unarchiveHandler(archivedAthlete.id)}
                    >
                      {t("global.unarchive")}
                    </Mui5.Button>
                  </Grid>
                  <Grid onClick={EventHelpers.preventDefault} onMouseDown={EventHelpers.stopPropagation}>
                    <ConfirmActionIconButton
                      icon={<DeleteIcon color="primary" />}
                      onConfirm={handleDelete(archivedAthlete.id)}
                      confirmColor="error"
                      body={t("settings.unarchive_athletes_confirmation_text")}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Components.ArchivedAthleteButtonBase>
          </Mui5.Paper>
        ))}
      </Grid>
    </Grid>
  );
}

export default SettingsArchivedAthletesList;
