import { TextFieldProps } from "@mui/material";
import { useField } from "formik";
import TextField from "../TextField";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

function FormText({ name, ...props }: TextFieldProps & { name: string }) {
  const [field, meta, helpers] = useField(name);
  const { t } = useTranslation();

  const fieldProps = useMemo(
    () => ({
      name,
      onChange: (event: any) => helpers.setValue(event.target.value),
      value: field.value || "",
      onBlur: () => helpers.setTouched(true),
      error: !!Boolean(meta.touched && meta.error) || props.error,
      helperText: meta.touched && meta.error ? t(meta.error) : props.helperText,
    }),
    [t, name, field.value, meta.touched, meta.error, props.error, props.helperText, helpers]
  );

  return <TextField {...props} {...fieldProps} />;
}

export default FormText;
