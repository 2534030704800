import { WorkoutStep, WorkoutTaskType } from "features/Workouts/Workouts.types";
import Mui5 from "components/mui5";
import WorkoutOverviewStepPreview from "./WorkoutOverviewStepPreview";
import WorkoutStepPreviewExerciseTitle from "features/Workouts/WorkoutForm/WorkoutStepsList/WorkoutStepPreviewExerciseTitle";
import WorkoutStepPreviewExerciseSubTitle from "features/Workouts/WorkoutForm/WorkoutStepsList/WorkoutStepPreviewExerciseSubTitle";
import WorkoutOverviewStepPreviewNoteSubTitle from "./WorkoutOverviewStepPreview/WorkoutOveviewStepPreviewNoteSubTitle/WorkoutOverviewStepPreviewNoteSubTitle";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ArchivedExerciseErrorIndicator from "features/Workouts/components/ArchivedExerciseErrorIndicator";
import { useTranslation } from "react-i18next";

type WorkoutOverviewPreviewStepFactoryProps = WorkoutStep & {
  stepIndex: number;
  superset: boolean;
};

function WorkoutOverviewPreviewStepFactory(props: WorkoutOverviewPreviewStepFactoryProps) {
  const { tasks, stepIndex, superset } = props;

  const { t } = useTranslation();

  const taskList = tasks.map((task, taskIndex) => {
    if (task.type === WorkoutTaskType.EXERCISE) {
      return (
        <WorkoutOverviewStepPreview
          key={task.id}
          superset={superset}
          stepIndex={stepIndex}
          taskIndex={taskIndex}
          title={<WorkoutStepPreviewExerciseTitle exerciseId={task.exerciseId} />}
          subTitle={<WorkoutStepPreviewExerciseSubTitle setMetadata={task.setMetadata} exerciseId={task.exerciseId} />}
          type={task.type}
          icon={<ArchivedExerciseErrorIndicator exerciseId={task.exerciseId} />}
        />
      );
    } else if (task.type === WorkoutTaskType.CUSTOM_EXERCISE) {
      return (
        <WorkoutOverviewStepPreview
          key={task.id}
          superset={superset}
          stepIndex={stepIndex}
          taskIndex={taskIndex}
          title={task.title || t("global.custom_exercise_text")}
          subTitle={<WorkoutStepPreviewExerciseSubTitle setMetadata={task.setMetadata} />}
          type={task.type}
        />
      );
    } else if (task.type === WorkoutTaskType.VIDEO) {
      return (
        <WorkoutOverviewStepPreview
          key={task.id}
          superset={superset}
          stepIndex={stepIndex}
          taskIndex={taskIndex}
          title={task.title || t("global.video_label")}
          subTitle={<WorkoutOverviewStepPreviewNoteSubTitle note={task.note} />}
          type={task.type}
          icon={<PlayCircleOutlineIcon />}
        />
      );
    } else if (task.type === WorkoutTaskType.NOTE) {
      return (
        <WorkoutOverviewStepPreview
          key={task.id}
          superset={superset}
          stepIndex={stepIndex}
          taskIndex={taskIndex}
          title={task.title || t("global.note_label")}
          subTitle={<WorkoutOverviewStepPreviewNoteSubTitle note={task.note} />}
          type={task.type}
          icon={<DescriptionOutlinedIcon />}
        />
      );
    }
    return null;
  });

  return <Mui5.Paper elevation={0}>{taskList}</Mui5.Paper>;
}

export default WorkoutOverviewPreviewStepFactory;
