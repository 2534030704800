import { useNavigate } from "react-router-dom";
import Constants from "../../../config/Constants";
import PageContent from "components/PageContent";
import Paper from "components/mui5/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GroupForm from "../../Forms/GroupForm";
import { useInfoMessage } from "../../../hooks";
import useAthleteStore from "providers/AthletesProvider/useAthleteStore";
import useGroupsContext from "hooks/useGroupsContext";
import { useTranslation } from "react-i18next";
import logEvent from "utils/logEvent";

function CreateGroup() {
  const [, groupActions] = useGroupsContext();
  const athletes = useAthleteStore();
  const infoMessage = useInfoMessage({ type: "error" });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGroupCreate = (group) => {
    return groupActions
      .create(group)
      .then((newGroup) => {
        navigate(`${Constants.routes.groups.route}/${newGroup.id}`);
        logEvent("GROUP_CREATE", { athleteCount: group.athleteIds.length });
      })
      .catch(infoMessage);
  };

  return (
    <PageContent pageHeadingLink={Constants.routes.groups.route} pageHeading={t(Constants.routes.groups.titleKey)}>
      <Grid container justifyContent="center">
        <Grid item sm={10} md={6} lg={5}>
          <Paper>
            <Box p={3}>
              <GroupForm
                submitText={t("group.all.add_group_form_submit_text")}
                onSubmit={handleGroupCreate}
                athleteOptions={athletes}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </PageContent>
  );
}

export default CreateGroup;
