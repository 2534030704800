import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import * as LoginSchema from "./LoginForm.schema";
import Typography from "@mui/material/Typography";
import ForgotPassword from "../ForgotPasswordButton";
import eyeIconDark from "assets/images/eye-icon-dark.svg";
import ohLogo from "assets/images/Logo.svg";
import Mui5 from "components/mui5";
import * as LoginComponents from "../../Login.components";
import Box from "@mui/material/Box";
import useTogglePasswordInputType from "hooks/useTogglePasswordInputType";
import { useTranslation } from "react-i18next";

const initialValues = {
  email: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_DEVELOPMENT_EMAIL_ACCOUNT}` : "",
  password: process.env.NODE_ENV === "development" ? `${process.env.REACT_APP_DEVELOPMENT_PASSWORD}` : "",
};

interface LoginFormProps {
  onSignIn(values: typeof initialValues): Promise<void>;
  errorMessage?: string;
  clearErrors(): void;
  errorCode?: string;
}

function LoginForm(props: LoginFormProps) {
  const { t } = useTranslation();
  const { onSignIn, errorMessage, clearErrors, errorCode } = props;
  const { type, toggleType } = useTogglePasswordInputType();

  const error = (errorCode && t(errorCode)) || errorMessage;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSignIn}
      validationSchema={LoginSchema.schema}
      onReset={clearErrors}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing="24px" maxWidth={480}>
            <Grid item xs={12} justifyContent="center">
              <img src={ohLogo} className="oh-logo" alt={t("global.logo_stripes_alt_text")} width={240} />
            </Grid>
            <Grid item xs={12}>
              <LoginComponents.LoginFormText
                id="login-email-address-input"
                name="email"
                label={t("global.email_address_text")}
                placeholder={t("global.email_address_placeholder")}
                variant="standard"
                color="secondary"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Box position="relative">
                <LoginComponents.LoginFormText
                  id="login-password-input"
                  type={type}
                  name="password"
                  label={t("global.password_input_label")}
                  placeholder={t("global.password_input_placeholder")}
                  variant="standard"
                  color="secondary"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <img src={eyeIconDark} onClick={toggleType} alt={t("components.show_password_alt_text")} />
                    ),
                  }}
                />
                <ForgotPassword />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Mui5.Button
                type="submit"
                color="secondary"
                fullWidth
                size="large"
                variant="contained"
                disabled={isSubmitting}
                disableElevation
              >
                {t("global.submit_button_text")}
              </Mui5.Button>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography variant="caption" color="error">
                  {t(error)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default LoginForm;
