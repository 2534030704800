import Typography from "@mui/material/Typography";
import LocalisedLoadingProvider from "components/LocalisedLoadingProvider";
import Grid from "@mui/material/Grid";
import Mui5 from "components/mui5";
import Paper from "components/mui5/Paper";
import RAGSettingsForm from "./RAGSettingsForm";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import RAGTemplatesList from "./RAGTemplatesList";
import useMonitoringTemplates from "hooks/useMonitoringTemplates";
import usePreferences from "hooks/usePreferences";
import useRequest from "hooks/useRequest";
import { RAGSettings } from "contexts/RAGSettingsContext/RAGSettingsContext";
import { useTranslation } from "react-i18next";

function RAGSettingsV2() {
  const [showNew, setShowNew] = useState<boolean>(false);
  const [ragTemplates, ragTemplateActions] = useMonitoringTemplates();
  const [, userPreferenceActions] = usePreferences();
  const createRagTemplate = useRequest(ragTemplateActions.create, true);
  const setDefaultRAGTemplate = useRequest(userPreferenceActions.setDefaultRAGTemplate, true);
  const { t } = useTranslation();

  function handleClickNewTemplate() {
    setShowNew(true);
  }

  async function handleCreateTemplate(values: RAGSettings & { default: boolean }) {
    try {
      const isFirstTemplate = Boolean(!ragTemplates.length);

      const result = await createRagTemplate(values);

      if (result && (values.default || isFirstTemplate)) {
        await setDefaultRAGTemplate(result.id);
      }

      setShowNew(false);
    } catch (_) {}
  }

  function handleCancelTemplate() {
    setShowNew(false);
  }

  return (
    <LocalisedLoadingProvider>
      <Grid container maxWidth={980}>
        <Grid item xs>
          <Box p={6} pb={0}>
            <Typography variant="h1">
              <Box component="span" fontWeight="fontWeightMedium">
                {t("settings.athlete_monitoring_page")}
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box pt={5} pr={5}>
            <Mui5.Button
              color="primary"
              variant="contained"
              aria-label={t("settings.rag_create_new_template")}
              disableElevation
              onClick={handleClickNewTemplate}
            >
              {t("settings.rag_create_new_template")}
            </Mui5.Button>
          </Box>
        </Grid>
        <Collapse in={showNew} timeout="auto" unmountOnExit>
          <Grid item xs={12}>
            <Box p={6} pt={3} pb={0}>
              <Paper square>
                <Box p={3}>
                  <RAGSettingsForm onSubmit={handleCreateTemplate} onCancel={handleCancelTemplate} />
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Collapse>
      </Grid>
      <Box p={6} pt={3} maxWidth="980px">
        <RAGTemplatesList />
      </Box>
    </LocalisedLoadingProvider>
  );
}

export default RAGSettingsV2;
