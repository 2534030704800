import { GridProps } from "@mui/material/Grid";
import Tables from "components/Tables";

type CellNames = "groupColorBlocks" | "checkbox" | "name" | "monitoringIndicators" | "actions";

export const tableCells: { [id in CellNames]: GridProps } = {
  groupColorBlocks: {
    width: 40,
    item: true,
  },
  checkbox: { item: true }, // Tables.controlCells.checkbox,
  name: { item: true, xs: 4, pl: 1 },
  monitoringIndicators: { item: true, xs: true },
  actions: Tables.controlCells.actions,
};

export const columnLabels = {
  checkbox: null,
  name: "global.name_text",
  groups: "global.groups_label",
  duration: null,
  published: "global.published_text",
  created: "global.created_text",
  actions: null,
};
