import { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import PageContent from "components/PageContent";
import Button from "../Button_deprecated/Button";
import Loader from "../Loader/Loader";
import Constants from "../../config/Constants";
import Confirmation from "../Confirmation_deprecated/Confirmation";
import athleteLogo from "../../assets/images/athlete-logo.svg";
import groupLogo from "../../assets/images/group-logo.svg";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DustbinIcon from "components/IconsStandardized/DustbinIcon";
import logEvent from "utils/logEvent";
import ReportsContext from "contexts/ReportsContext";
import withNavigate from "utils/withNavigate";
import "./ReportGeneration.scss";
import { t } from "i18next";

class ReportGeneration extends Component {
  static contextType = ReportsContext;

  state = {
    isConfirmationOpen: false,
    templateToDelete: null,
    loading: true,
  };

  /** @returns {import("contexts/ReportsContext").ReportsContextActions} */
  get reportActions() {
    if (typeof this.context === "undefined") {
      throw new Error("ReportsContext.Provider is not defined");
    }

    return this.context[1];
  }
  get reportTemplates() {
    if (typeof this.context === "undefined") {
      throw new Error("ReportsContext.Provider is not defined");
    }

    return this.context[0];
  }

  handleOpenDeleteConfirmation = (template) => {
    this.setState({
      isConfirmationOpen: true,
      templateToDelete: template,
    });
  };

  useTemplateHandler = (template) => {
    if (template.reportType) {
      this.props.navigate(`${Constants.routes.reportGeneration.route}/use-group-report-tpl/${template.id}`);
    } else {
      this.props.navigate(`${Constants.routes.reportGeneration.route}/use-athlete-report-tpl/${template.id}`);
    }
  };

  confirmTemplateDeletionHandler = async () => {
    try {
      this.setState({ loading: true });
      await this.reportActions.delete(this.state.templateToDelete.id);
    } catch (e) {
      // TODO: Add error handler, surface error to UI
    }

    this.setState({ loading: false });
    this.handleCloseDeleteConfirmation();
  };

  handleCloseDeleteConfirmation = () => {
    this.setState({ isConfirmationOpen: false });
  };

  async getAll() {
    try {
      await this.reportActions.getAll();
    } catch (e) {
      // TODO: Add error handler, surface error to UI
    }
    this.setState({ loading: false });
  }

  componentDidMount() {
    logEvent("REPORTS_VIEW_ALL");
    this.getAll();
  }

  render() {
    return (
      <div className="oh-report">
        <PageContent pageHeading={t(Constants.routes.reportGeneration.title)}>
          {this.state.loading && <Loader />}
          <Grid container className="oh-section-title-wrapper" alignItems="center" pt={2}>
            <Grid item sm={6} xl={8}>
              <div className="oh-report-section-title">{t("reports.my_report_templates_title")}</div>
            </Grid>
            <Grid item sm={6} xl={4}>
              <Link to={Constants.routes.createReport.route}>
                <Button classNames="btn-cyan">{t("reports.create_report")}</Button>
              </Link>
            </Grid>
          </Grid>
          <div className="oh-report-list-wrapper">
            {(this.reportTemplates || []).map((template, index) => (
              <Grid container className="report-template" key={index} onClick={() => this.useTemplateHandler(template)}>
                <Grid item sm={4}>
                  <div className="report-name">
                    <img
                      className="type-icon"
                      src={template.reportType ? groupLogo : athleteLogo}
                      alt={t("reports.template_type_icon_alt_img")}
                    />
                    {template.name}
                  </div>
                </Grid>
                <Grid item sm lg>
                  <div className="report-data">
                    <div className="created-date">
                      {t("reports.template_created_at", {
                        createdAt: moment(template.createdAt).format("DD MMMM YYYY"),
                      })}
                    </div>
                    <div className="last-used">
                      {t("reports.template_last_used_at", {
                        lastUsedAt: moment(template.lastUsedAt).format("DD MMMM YYYY"),
                      })}
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label={t("reports.delete_report_template_name", {
                      templateName: template.name,
                    })}
                    color="primary"
                    onClick={(event) => {
                      event.stopPropagation();
                      this.handleOpenDeleteConfirmation(template);
                    }}
                  >
                    <DustbinIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Confirmation
              confirmationMessage={t("reports.delete_report_template_confirmation", {
                templateName: this.state.templateToDelete && this.state.templateToDelete.name,
              })}
              positiveHandler={this.confirmTemplateDeletionHandler}
              negativeHandler={this.handleCloseDeleteConfirmation}
              buttonText={t("reports.delete_module")}
              hasOwnButton={false}
              isConfirmationOpen={this.state.isConfirmationOpen}
            />
            {this.reportTemplates.length === 0 && (
              <h1 className="no-templates">{t("reports.no_saved_report_templates")}</h1>
            )}
          </div>
        </PageContent>
      </div>
    );
  }
}

export default withNavigate(ReportGeneration);
