import MessageCard from "components/MessageCard";
import * as Components from "./OneRepMaxChartNoData.components";
import useChartSelections from "components/charting/hooks/useChartSelections";
import { Trans, useTranslation } from "react-i18next";

interface OneRepMaxChartNoDataProps {}

function OneRepMaxChartNoData(props: OneRepMaxChartNoDataProps) {
  const selections = useChartSelections();
  const { t } = useTranslation();
  return (
    <MessageCard title={t("global.one_rep_max_abbr_label")}>
      <Trans
        i18nKey="charting.one_rep_max_no_data_to_display"
        context={selections.period.value || undefined}
        components={[<Components.ImportantText />]}
      >
        No data to display. Please check you have selected a
        <Components.ImportantText>VBT exercise</Components.ImportantText>.
      </Trans>
    </MessageCard>
  );
}

export default OneRepMaxChartNoData;
