import AthleteKpiModuleForm from "./Forms/AthleteKpiModuleForm";
import moment from "moment";
import ModuleModalWrapper from "../../ModuleModalWrapper";
import { useTranslation } from "react-i18next";

function AthleteMetrics(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;
  const { t } = useTranslation();

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title={t("reports.add_athlete_kpi_module_button_text")} close={closeModal}>
      <AthleteKpiModuleForm
        onSubmit={submitModule_V2}
        onCancel={closeModal}
        initialValues={{
          ...(moduleToEdit || {}),
          startDate: moduleToEdit?.startDate ? moment(moduleToEdit?.startDate).toISOString() : null,
          endDate: moduleToEdit?.endDate ? moment(moduleToEdit?.endDate).toISOString() : null,
        }}
      />
    </ModuleModalWrapper>
  );
}
export default AthleteMetrics;
