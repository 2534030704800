import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export const MinHeightBox = styled(Box)(({ theme }) => ({
  minHeight: theme.spacing(6),
}));

export const OverflowHiddenGrid = styled(Grid)(({ theme }) => ({
  overflow: "hidden",
  padding: theme.spacing(2),
}));

export const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  color: theme.palette.primary.main,
  backgroundColor: "#FFF",
  lineHeight: "48px",
  listStyle: "none",
  fontWeight: 500,
  padding: "0 16px",
  top: "-8px",
  zIndex: 1,
}));

export const GroupItems = styled("ul")({
  padding: 0,
});
