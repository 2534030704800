import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import outputLogoStripes from "components/DrawerMenu/outputLogoStripes.svg";
import { useTranslation } from "react-i18next";

interface MessageWithLogoProps {
  children: string;
}

function MessageWithLogo(props: MessageWithLogoProps) {
  const { t } = useTranslation();
  return (
    <Box pt={4} textAlign="center">
      <Typography variant="h2">{props.children}</Typography>
      <Box pt={2}>
        <img src={outputLogoStripes} alt={t("global.logo_stripes_alt_text")} />
      </Box>
    </Box>
  );
}

export default MessageWithLogo;
