import LineChartIcon from "components/Icons/charts/LineChartIcon";
import MeasurementsTableIcon from "components/Icons/charts/MeasurementsTableIcon";
import ProfilingChartIcon from "components/Icons/charts/ProfilingChartIcon";
import SetComparisonChartIcon from "components/Icons/charts/SetComparisonChartIcon";
import Tables from "components/Tables";

export enum DataVisualizationType {
  LINE_CHART = "LINE_CHART_V3",
  MEASUREMENTS_TABLE = "MEASUREMENTS_TABLE_V3",
  SET_COMPARISON_CHART = "SET_COMPARISON_CHART_V3",
  PROFILING_CHART = "PROFILING_CHART_V3",
  ONE_REP_MAX_CHART = "ONE_REP_MAX_CHART_V3",
}

export const dataVisualizationOptions = [
  {
    type: DataVisualizationType.LINE_CHART,
    titleKey: "chart_types.line_chart",
    icon: <LineChartIcon color="inherit" />,
    descriptionKey: "chart_types.line_chart_description",
  },
  {
    type: DataVisualizationType.MEASUREMENTS_TABLE,
    titleKey: "chart_types.measurements_table",
    icon: <MeasurementsTableIcon color="inherit" />,
    descriptionKey: "chart_types.measurements_table_description",
  },
  {
    type: DataVisualizationType.SET_COMPARISON_CHART,
    titleKey: "chart_types.set_comparison",
    icon: <SetComparisonChartIcon color="inherit" />,
    descriptionKey: "chart_types.set_comparison_description",
  },
  {
    type: DataVisualizationType.PROFILING_CHART,
    titleKey: "chart_types.profiling_chart",
    icon: <ProfilingChartIcon color="inherit" />,
    descriptionKey: "chart_types.profiling_chart_description",
  },
  {
    type: DataVisualizationType.ONE_REP_MAX_CHART,
    titleKey: "chart_types.1rm_chart",
    icon: <Tables.Icons.OneRepMaxNoOutline />,
    descriptionKey: "chart_types.1rm_chart_description",
  },
];
