import * as Yup from "yup";
import CommonSchemas from "schemas/CommonSchemas";
import GenderHelpers from "helpers/GenderHelpers";

export const errorMessages = {
  firstName: {
    max: "athlete.form_name_max_length_error",
    required: "athlete.form_name_required_error",
  },
  lastName: {
    max: "athlete.form_name_max_length_error",
    required: "athlete.form_name_required_error",
  },
  sex: {
    required: "athlete.form_sex_required_error",
  },
  height: {
    max: "athlete.form_height_max_error",
  },
  groups: {
    min: "athlete.form_min_group_error",
  },
  weight: {
    max: "athlete.form_max_weight_error",
  },
};

export const getSchema = ({ groups = { min: 1 } } = {}) =>
  Yup.object().shape({
    profilePhotoName: CommonSchemas.profilePhoto,
    firstName: Yup.string()
      .transform((v) => v?.trim() || "")
      .max(100, errorMessages.firstName.max)
      .required(errorMessages.firstName.required),
    lastName: Yup.string()
      .transform((v) => v?.trim() || "")
      .max(100, errorMessages.lastName.max)
      .required(errorMessages.lastName.required),
    externalId: Yup.string()
      .max(100)
      .nullable()
      .transform((v) => v || null),
    sex: Yup.mixed().oneOf(GenderHelpers.genders, errorMessages.sex.required).required(errorMessages.sex.required),
    height: Yup.number()
      .nullable()
      /**
       * @author Carden
       * Max is 3 which counteracts the transform / 100 happening on this property.
       * The initial value is in Metre while we display in Centimetre
       */
      .max(3, errorMessages.height.max)
      .positive()
      .transform((v) => (v ? parseInt(v) / 100 : null)),
    groups: Yup.array().min(groups.min, errorMessages.groups.min),
    weight: Yup.number()
      .nullable()
      .max(500, errorMessages.weight.max)
      .positive()
      .transform((v) => v || null),
    email: Yup.string()
      .email()
      .nullable()
      .transform((v) => v || null),
  });
