import ExportService from "../../services/ExportService";
import { successMessageKey } from "./useExportAthletes.config";
import { useLoadingContext } from "../../components/LocalisedLoadingProvider";
import useInfoMessage from "hooks/useInfoMessage";
import AnalyticsService from "../../services/AnalyticsService/AnalyticsService";
import { useTranslation } from "react-i18next";

function useExportAthletes() {
  const [, setLoading] = useLoadingContext();
  const onErrorMessage = useInfoMessage({ type: "error" });
  const onSuccessMessage = useInfoMessage({ type: "success" });
  const { t } = useTranslation();

  async function exportAthletes(options) {
    try {
      setLoading(true);
      await ExportService.exportAthletes(options);

      AnalyticsService.logEvent(AnalyticsService.EVENTS.ATHLETE_DATA_EXPORT);

      setLoading(false);
      return onSuccessMessage({ message: t(successMessageKey) });
    } catch (error) {
      setLoading(false);
      const { detail: message } = JSON.parse(Buffer.from(error.response.data).toString("utf8"));
      return onErrorMessage({ message: message });
    }
  }

  return exportAthletes;
}
export default useExportAthletes;
