import Grid from "@mui/material/Grid";
import { Formik, Form } from "formik";
import { useCallback } from "react";
import FormText from "components/Inputs/Form/FormText";
import { useTranslation } from "react-i18next";
import eyeIconDark from "assets/images/eye-icon-dark.svg";
import { PasswordInputSchema } from "./PasswordInput.schema";
import useTogglePasswordInputType from "hooks/useTogglePasswordInputType";
import SubmitAndCancelButtons from "../SubmitAndCancelButtons";

export interface PasswordInputProps {
  onReauthenticate(password: string): void | Promise<void>;
  onCancel(): void;
  errorCode?: string;
}

function PasswordInput(props: PasswordInputProps) {
  const { t } = useTranslation();
  const { onCancel, onReauthenticate, errorCode } = props;
  const { type, toggleType } = useTogglePasswordInputType();

  const handleSubmit = useCallback(
    (values: { password: string }) => onReauthenticate(values.password),
    [onReauthenticate]
  );

  return (
    <Formik initialValues={{ password: "" }} onSubmit={handleSubmit} validationSchema={PasswordInputSchema}>
      {({ isSubmitting, dirty }) => (
        <Form>
          <Grid container spacing="24px" maxWidth={480}>
            <Grid item xs={12}>
              <FormText
                id="password-input"
                type={type}
                label={t("reauthentication_provider.password_input_label")}
                name="password"
                placeholder={t("reauthentication_provider.password_input_placeholder")}
                variant="standard"
                color="secondary"
                fullWidth
                error={!!errorCode}
                helperText={errorCode && t(errorCode, { context: "passwordOnly" })}
                InputProps={{
                  endAdornment: (
                    <img src={eyeIconDark} onClick={toggleType} alt={t("components.show_password_alt_text")} />
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SubmitAndCancelButtons
                submitText={t("global.next_button_text")}
                submitDisabled={!dirty || isSubmitting}
                onCancel={onCancel}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default PasswordInput;
