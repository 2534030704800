import AthleteMonitoringIndicatorsForm from "./Forms/AthleteMonitoringIndicatorsForm";
import ModuleModalWrapper from "../../ModuleModalWrapper";
import { useTranslation } from "react-i18next";

function AthleteMonitoringIndicators(props) {
  const { moduleToEdit, submitModule_V2, handleCloseModal } = props;
  const { t } = useTranslation();

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <ModuleModalWrapper title={t("reports.select_athlete_monitoring_template_title")} close={closeModal}>
      <AthleteMonitoringIndicatorsForm onSubmit={submitModule_V2} onCancel={closeModal} initialValues={moduleToEdit} />
    </ModuleModalWrapper>
  );
}
export default AthleteMonitoringIndicators;
