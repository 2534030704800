import MessageCard from "components/MessageCard";
import { ImportantText } from "./AthleteProfileMeasurementsNoData.components";
import { Trans, useTranslation } from "react-i18next";

interface AthleteProfileMeasurementsNoDataProps {}

function AthleteProfileMeasurementsNoData(props: AthleteProfileMeasurementsNoDataProps) {
  const { t } = useTranslation();
  return (
    <MessageCard title={t("charting.measurements_table_label")}>
      <Trans i18nKey="athlete_profile_measurements_no_data.no_data_message">
        No data to display. Please update <ImportantText> Measurement</ImportantText> selection.
      </Trans>
    </MessageCard>
  );
}

export default AthleteProfileMeasurementsNoData;
