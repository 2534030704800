import IconButton from "@mui/material/IconButton";
import DustbinIcon from "components/IconsStandardized/DustbinIcon";
import { Component } from "react";
import "./ModuleItemMenu.scss";
import { t } from "i18next";

class ModuleItemMenu extends Component {
  handleDeleteClick = (event) => {
    event?.stopPropagation();
    this.props.deleteHandler(this.props.template);
  };

  render() {
    return (
      <div className="module-menu">
        <IconButton
          size="small"
          color="primary"
          onClick={this.handleDeleteClick}
          aria-label={t("settings.rag_delete_template_action_aria_label")}
        >
          <DustbinIcon />
        </IconButton>
      </div>
    );
  }
}

export default ModuleItemMenu;
