import { DateTime, Exercise, ExerciseMetric, FireStoreMeasurement, Variants } from "types";
import * as Components from "./LeaderboardTablePercentage.components";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import useAllowedAthleteMeasurementFilter from "hooks/useAllowedAthleteMeasurementFilter";
import useMeasurementsFilter from "features/Live/hooks/useMeasurementsFilter";
import { useLoadingContext } from "components/LocalisedLoadingProvider";
import LeaderboardService from "services/LeaderboardService";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import Loader from "components/Loader/Loader";
import MessageWithLogo from "components/MessageWithLogo";
import { LeaderboardComparePeriod } from "features/Leaderboards/LeaderboardTables/LeaderboardEditable/LeaderboardEditable.types";
import useTickerQueue from "components/Ticker/TickerProvider/useTickerQueue";
import usePercentageRankings from "./hooks/usePercentageRankings";
import LeaderboardHelpers from "features/Leaderboards/helpers/LeaderboardHelpers";
import LeaderboardTickerPortal from "../LeaderboardTickerPortal";
import useUpdateLeaderboardStore from "../hooks/useUpdateLeaderboardStore";
import { useTranslation } from "react-i18next";
interface LeaderboardTablePercentageProps {
  exercise: Exercise;
  metric: ExerciseMetric;
  variant: Variants | null;
  timePeriodStartDate?: string;
  startDate?: string;
  endDate?: string;
  comparePeriodType: LeaderboardComparePeriod;
  startDateComparePeriod: DateTime | null;
}

const LeaderboardTablePercentage = memo(function LeaderboardTablePercentage(props: LeaderboardTablePercentageProps) {
  const { t } = useTranslation();
  const { timePeriodStartDate, startDate, endDate, exercise, metric, variant } = props;
  const organisation = useOrganisation();
  const [loading, setLoading] = useLoadingContext();
  const [measurements, setMeasurements] = useState<{ [id: string]: FireStoreMeasurement | undefined }>({});
  const [queue, setQueue] = useTickerQueue<FireStoreMeasurement>();
  const exerciseId = exercise.id;
  const metricField = metric.field;
  const sortOrder = metric.sortType;

  const initialMeasurementsRef = useRef<boolean>(true);
  const tickerMeasurement: FireStoreMeasurement | undefined = queue[0];

  const updateMeasurementStore = useUpdateLeaderboardStore({
    initialMeasurementsRef,
    setMeasurements,
    setQueue,
  });

  useEffect(() => {
    setLoading(true);

    const unsubscribe = LeaderboardService.subscribe(
      {
        userId: organisation.id,
        exerciseId,
        startDate,
        endDate,
        sortOrder,
      },
      (snapshot) => {
        LeaderboardHelpers.onSnapshot(snapshot, updateMeasurementStore);

        if (!snapshot.metadata.fromCache) {
          initialMeasurementsRef.current = false;
        }

        setLoading(false);
      }
    );

    return () => {
      initialMeasurementsRef.current = true;
      setMeasurements({});
      setQueue([]);
      unsubscribe();
      setLoading(false);
    };
  }, [endDate, exerciseId, organisation.id, setLoading, setQueue, sortOrder, startDate, updateMeasurementStore]);

  const percentageRankings = usePercentageRankings(
    measurements,
    metricField,
    sortOrder,
    variant,
    timePeriodStartDate || null
  );
  const allowedAthleteMeasurements = useAllowedAthleteMeasurementFilter(percentageRankings.measurements);
  const filteredMeasurements = useMeasurementsFilter(allowedAthleteMeasurements);

  const allowedTickerMeasurements = useAllowedAthleteMeasurementFilter(
    useMemo(() => [tickerMeasurement].filter(Boolean), [tickerMeasurement])
  );
  const filteredTickerMeasurements = useMeasurementsFilter(allowedTickerMeasurements);
  const filteredTickerMeasurement = useMemo(() => filteredTickerMeasurements.shift(), [filteredTickerMeasurements]);

  useEffect(() => {
    // if a ticker measurement exists but should not be shown bump the queue up 1
    if (tickerMeasurement && typeof filteredTickerMeasurement === "undefined") {
      setQueue((prevQueue) => prevQueue.slice(1));
    }
  }, [filteredTickerMeasurement, setQueue, tickerMeasurement]);

  if (loading && filteredMeasurements.length === 0) {
    return <Loader isSmall />;
  }

  if (filteredMeasurements.length === 0) {
    return <MessageWithLogo>{t("leaderboard.no_measurements_error")}</MessageWithLogo>;
  }

  return (
    <>
      {filteredMeasurements.map((rankedMeasurement, index) => (
        <Components.LeaderboardTablePercentageRow
          key={rankedMeasurement.id}
          position={index + 1}
          measurement={rankedMeasurement}
          comparativeMeasurement={percentageRankings.getComparative(rankedMeasurement.athleteId)}
          metric={metric}
          comparePeriodType={props.comparePeriodType}
          startDateComparePeriod={props.startDateComparePeriod}
        />
      ))}
      <LeaderboardTickerPortal measurementId={filteredTickerMeasurement?.id}>
        {filteredTickerMeasurement && (
          <Components.LeaderboardTablePercentageRow
            key={filteredTickerMeasurement.id}
            position={filteredMeasurements.indexOf(filteredTickerMeasurement) + 1 || null}
            measurement={filteredTickerMeasurement}
            comparativeMeasurement={percentageRankings.getComparative(filteredTickerMeasurement.athleteId)}
            metric={metric}
            comparePeriodType={props.comparePeriodType}
            startDateComparePeriod={props.startDateComparePeriod}
          />
        )}
      </LeaderboardTickerPortal>
    </>
  );
});

export default LeaderboardTablePercentage;
