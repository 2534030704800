import InfoCard from "components/InfoCard";
import ExportIcon from "components/Icons/ExportIcon";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";

export interface DataExportAthleteInfoCardProps {
  onClose(): void;
}

function DataExportAthleteInfoCard(props: DataExportAthleteInfoCardProps) {
  const { t } = useTranslation();
  return (
    <InfoCard title={t("export.page_title")} icon={<ExportIcon />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h4">
            {t("export.info_card_description1")} {t("export.info_card_description2")}{" "}
            {t("export.info_card_description3")}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center" p={2}>
          <Mui5.Button
            fullWidth={false}
            sx={{ width: 320 }}
            size="medium"
            color="primary"
            LinkComponent="a"
            // @ts-ignore
            rel="noopener noreferrer"
            // @ts-ignore
            target="_blank"
            href="https://25970650.hs-sites-eu1.com/knowledge/how-to-export-data-from-the-hub"
          >
            {t("global.more_info_button_text")}
          </Mui5.Button>
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default DataExportAthleteInfoCard;
