import Tables from "..";
import Tooltip from "components/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useCallback, useState } from "react";
import ConfirmationDialog, { ConfirmationDialogProps } from "components/ConfirmationDialog";
import { useTranslation } from "react-i18next";

interface BulkDeleteControlProps {
  ids: string[];
  onDelete(id: string): PromiseLike<any>;
  alert?: React.ReactNode;
  tooltipTitle: string;
}

function BulkDeleteControl(props: BulkDeleteControlProps) {
  const { onDelete, ids } = props;
  const [dialogProps, setDialogProps] = useState<Omit<ConfirmationDialogProps, "open" | "setOpen"> | false>(false);

  const handleClose = useCallback(() => {
    setDialogProps(false);
  }, []);
  const { t } = useTranslation();

  const handleDelete = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      handleClose();

      setDialogProps({
        title: t("global.bulk_delete_dialog_title"),
        cancelText: t("global.cancel_button_text"),
        confirmText: t("global.bulk_delete_dialog_confirm_text"),
        color: "error",
        alert: props.alert,
        body: [
          t("global.bulk_delete_body_text1", { count: ids.length <= 100 ? ids.length : 100 }),
          t("global.bulk_delete_body_text2"),
          ids.length > 100 ? t("global.bulk_delete_body_text_limit_explainer") : "",
        ]
          .filter(Boolean)
          .join(" "),
        onConfirm: () => Promise.all(ids.slice(0, 100).map((id) => onDelete(id))),
      });
    },
    [handleClose, ids, onDelete, props.alert, t]
  );

  const confirmationDialog = Boolean(dialogProps) && (
    <ConfirmationDialog open={Boolean(dialogProps)} setOpen={setDialogProps} {...dialogProps} />
  );

  return (
    <>
      <Tooltip title={props.tooltipTitle} arrow placement="top">
        <span>
          <IconButton
            color="primary"
            size="small"
            onClick={handleDelete}
            disabled={!ids.length}
            aria-label={t("athlete.bulk_delete_athletes_aria_label")}
          >
            <Tables.Icons.Delete />
          </IconButton>
        </span>
      </Tooltip>
      {confirmationDialog}
    </>
  );
}

export default BulkDeleteControl;
