import * as Yup from "yup";

class CommonSchemas {
  static readonly profilePhoto = Yup.mixed<File>()
    .test("fileSize", "errors.profile_photo_file_size_error", (value) =>
      value !== null && value !== undefined && typeof value !== "string" ? value.size <= 10000000 : true
    )
    .test("fileNameLength", "errors.profile_photo_file_name_error", (value) =>
      value !== null && value !== undefined && typeof value !== "string" ? value.name.length < 100 : true
    )
    .transform((v) => v || null)
    .nullable();
}

export default CommonSchemas;
