import { useEffect, useMemo, useCallback } from "react";
import PageContent from "components/PageContent";
import Loader from "../../Loader/Loader";
import Constants from "../../../config/Constants";
import Confirmation from "../../Confirmation_deprecated/Confirmation";
import AthleteForm from "../../Forms/AthleteForm";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useGroupsContext from "hooks/useGroupsContext";
import { useInfoMessage, useLoadingContext, useRequest } from "hooks";
import useAllAthletes from "hooks/useAllAthletes";
import useAthleteActions from "providers/AthletesProvider/useAthleteActions";
import { useMatch, useNavigate } from "react-router-dom";
import { style } from "config";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import TrashIcon2 from "components/Icons/TrashIcon2";
import Mui5 from "components/mui5";
import ArchivedAthleteAlert from "./ArchivedAthleteAlert";
import useOrganisation from "contexts/OrganisationContext/useOrganisation";
import CanDoAction from "components/CanDoAction";
import useCanDo from "hooks/useCanDo";
import useOrganisationActions from "providers/OrganisationProvider/useOrganisationActions";
import AthleteHelpers from "helpers/AthleteHelpers";
import useAthleteProfilePhotoURL from "hooks/useAthleteProfilePhotoURL";
import { useTranslation } from "react-i18next";
import useAthleteLimits from "hooks/useAthleteLimits";
import Tooltip from "components/Tooltip";
import AthleteArchiveDisabledAlert from "../components/AthleteArchiveDisabledAlert";
import GenderHelpers from "helpers/GenderHelpers";

function EditAthlete() {
  const canDo = useCanDo();
  const onError = useInfoMessage({ type: "error" });
  const athletes = useAllAthletes();
  const athleteActions = useAthleteActions();
  const [groups, groupActions] = useGroupsContext();
  const handleUpdateAthlete = useRequest(athleteActions.update, true);
  const handleArchiveAthlete = useRequest(athleteActions.toggleArchived, true);
  const [loading] = useLoadingContext();
  const match = useMatch("/athlete-hub/:id/edit");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const athleteLimits = useAthleteLimits();

  // TODO: (0S-4710) Replace with AccountInfoProvider
  const organisation = useOrganisation();
  const organisationActions = useOrganisationActions();
  // END TODO

  const athleteId = match.params.id;

  if (typeof athleteId === "undefined") {
    throw new Error("No Athlete ID provided");
  }

  const confirmAthleteDeletion = useCallback(async () => {
    await athleteActions.delete(athleteId);
    navigate(Constants.routes.athletes.route);
  }, [athleteActions, athleteId, navigate]);

  useEffect(() => {
    groupActions.fetchAll();
    // TODO: (0S-4710) Replace with AccountInfoProvider
    organisationActions.get();
    // END TODO
  }, [groupActions, organisationActions]);

  const { groupIds = [], ...athlete } = useMemo(() => {
    const athlete = athletes.find((athlete) => athlete.id === athleteId);

    if (!athlete) return {};

    return {
      externalId: athlete?.externalId || "",
      email: athlete?.email || "",
      ...athlete,
    };
  }, [athleteId, athletes]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const existingGroupIds = groups
          .filter((group) =>
            values.groups.find(
              (g) => g.id === group.id || (g?.toLowerCase && g.toLowerCase()) === group.name.toLowerCase()
            )
          )
          .map((group) => group.id);

        const newGroupNames = values.groups
          .filter(
            (g) =>
              typeof g === "string" &&
              Boolean(g) &&
              !groups.find((group) => group.name.toLowerCase() === g.toLowerCase())
          )
          .filter(
            (groupName, index, arr) =>
              !arr.slice(0, index).find((newGroupName) => newGroupName.toLowerCase() === groupName.toLowerCase())
          );

        let newGroups = [];

        if (canDo("group.any")) {
          newGroups = await Promise.all(
            newGroupNames.map((name) => {
              const randomColour = style.colors.options[Math.floor(Math.random() * style.colors.options.length)];
              return groupActions.create({ name, colour: randomColour, athleteIds: [], description: "" });
            })
          );
        }

        const newGroupIds = newGroups.map((group) => group.id);

        /**
         * Since we only accept Male or Female, we need to
         * make sure that we check for the correct value
         */
        const athleteSex = GenderHelpers.gendersAccepted.find(
          (gender) => gender.toLowerCase() === values.sex.toLowerCase()
        );

        await handleUpdateAthlete({
          id: athleteId,
          firstName: values.firstName,
          lastName: values.lastName,
          externalId: values.externalId,
          dateOfBirth: values.dateOfBirth,
          weight: values.weight,
          height: values.height,
          sex: athleteSex || null,
          profilePhotoName: values.profilePhotoName,
          groupIds: organisation.featureConfig.groupsEnabled ? existingGroupIds.concat(newGroupIds) : groupIds,
          email: values.email,
        });

        navigate(`${Constants.routes.athletes.route}/${athleteId}`);
      } catch (error) {
        const customErrorMessages = {
          409: {
            message: t("errors.email_already_in_use"),
          },
        };

        const errorObject = customErrorMessages[error?.response?.status] || error;

        onError(errorObject);
      }
    },
    [
      t,
      athleteId,
      canDo,
      groupActions,
      groupIds,
      groups,
      handleUpdateAthlete,
      navigate,
      onError,
      organisation.featureConfig.groupsEnabled,
    ]
  );

  const disableArchiveButton = athleteLimits.archived.isFull;

  const allowMaxTwoDecimals = useCallback((value) => Math.round((value + Number.EPSILON) * 100) / 100, []);

  const avatar = useAthleteProfilePhotoURL(athlete);

  const handleToggleArchived = useCallback(async () => {
    await handleArchiveAthlete(athleteId, !athlete.isArchived);
  }, [athlete.isArchived, athleteId, handleArchiveAthlete]);

  const buttonText = useMemo(() => {
    if (athlete.isArchived) return t("edit_athlete.unarchive_button_text");
    return t("edit_athlete.archive_button_text");
  }, [athlete.isArchived, t]);

  return (
    <PageContent
      pageHeadingLink={`${Constants.routes.athletes.route}/${athleteId}`}
      pageHeading={t("edit_athlete.page_heading")}
    >
      <Grid container mt={2}>
        <Grid item xs={false} sm={1} md={2} lg={3} xl={4} />
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Mui5.Paper elevation={athlete.isArchived ? 0 : undefined}>
            <Box p={3} position="relative">
              {!Object.keys(athlete).length ? (
                <Loader />
              ) : (
                <>
                  <AthleteForm
                    enableReinitialize
                    initialValues={{
                      ...athlete,
                      profilePhotoName: avatar.src,
                      /**
                       * @author Carden
                       * @property {athlete.height}
                       * @description Height is record
                       * in Metre in DB but need to
                       * convert to CM for display
                       * and editing purposes.
                       */
                      sex: t("global.gender_picker", { context: athlete.sex }),
                      height: athlete.height ? allowMaxTwoDecimals(athlete.height * 100) : null,
                      weight: athlete.weight ? allowMaxTwoDecimals(athlete.weight) : null,
                      groups: groups.filter((groupOption) => groupIds.includes(groupOption.id)),
                    }}
                    groupOptions={groups}
                    submitText={t("edit_athlete.submit_text")}
                    photoChangeButtonText={t("edit_athlete.update_photo_button")}
                    onSubmit={handleSubmit}
                  />
                  <Grid container spacing="8px" pt={0.5}>
                    <Grid item xs={12} />
                    <Tooltip
                      arrow
                      placement="top"
                      severity="warning"
                      title={disableArchiveButton && <AthleteArchiveDisabledAlert />}
                    >
                      <Grid item xs>
                        <Confirmation
                          confirmationMessage={t("edit_athlete.archive_confirmation_message", {
                            name: AthleteHelpers.getFullName(athlete),
                          })}
                          positiveHandler={handleToggleArchived}
                          buttonText={buttonText}
                          confirmationButtonText={t("edit_athlete.archive_button_text")}
                          openButtonProps={{
                            color: "primary",
                            startIcon: <ArchiveOutlinedIcon />,
                            disabled: disableArchiveButton,
                          }}
                        />
                      </Grid>
                    </Tooltip>
                    <CanDoAction action="athlete.delete">
                      <Grid item xs={6}>
                        <Confirmation
                          confirmationMessage={t("edit_athlete.delete_confirmation_message", {
                            name: AthleteHelpers.getFullName(athlete),
                          })}
                          positiveHandler={confirmAthleteDeletion}
                          buttonText={t("edit_athlete.delete_button_text")}
                          openButtonProps={{
                            startIcon: <TrashIcon2 />,
                          }}
                        />
                      </Grid>
                    </CanDoAction>
                  </Grid>
                  <ArchivedAthleteAlert isArchived={athlete.isArchived} unarchiveAthlete={handleToggleArchived} />
                </>
              )}
            </Box>
          </Mui5.Paper>
        </Grid>
      </Grid>
      {loading && <Loader />}
    </PageContent>
  );
}

export default EditAthlete;
