import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import longLeftArrow from "../../../assets/images/long-left-arrow.svg";
import { useTranslation } from "react-i18next";

function PageContentTitle(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (props.titleLink) {
    return (
      <Link to="" onClick={() => navigate(-1)}>
        <Typography sx={{ textDecoration: "none" }} variant="h1" gutterBottom={false} color="primary.main">
          <img src={longLeftArrow} alt={t("global.left_arrow_icon_alt")} /> {props.title}
        </Typography>
      </Link>
    );
  }

  return (
    <Typography color="primary.main" variant="h1">
      {props.title}
    </Typography>
  );
}

PageContentTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.string.isRequired]).isRequired,
  titleLink: PropTypes.string,
};
export default PageContentTitle;
