import * as Yup from "yup";

export const errorMessages = {
  passwordTooShort: "errors.password_too_short",
  passwordTooLong: "errors.password_too_long",
  newPassword: "errors.new_password",
  confirmNewPassword: {
    required: "errors.confirm_new_password",
    noMatch: "errors.password_no_match",
  },
};

export const schema = Yup.object().shape({
  newPassword: Yup.string()
    .max(50, errorMessages.passwordTooLong)
    .min(6, errorMessages.passwordTooShort)
    .required(errorMessages.newPassword),
  confirmNewPassword: Yup.string()
    .max(50, errorMessages.passwordTooLong)
    .min(6, errorMessages.passwordTooShort)
    .oneOf([Yup.ref("newPassword"), undefined], errorMessages.confirmNewPassword.noMatch)
    .required(errorMessages.confirmNewPassword.required),
});
