import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import usePreferences from "hooks/usePreferences";
import GroupedOptionButtons from "components/GroupedOptionButtons";
import { useTranslation } from "react-i18next";

function SettingsMeasurementSystem() {
  const [preferences, handlers] = usePreferences();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">
          <Box component="span" fontWeight="fontWeightMedium">
            {t("settings.measurement_system_page")}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} width="100%" paddingTop={2}>
        <Box fontWeight="fontWeightLight">
          <Typography variant="subtitle2" gutterBottom fontWeight={300}>
            {t("settings.measurement_system_measure_all_units")}
          </Typography>
        </Box>
        <GroupedOptionButtons
          value={preferences?.imperialSystemEnabled}
          onClick={handlers.toggleImperialSystem}
          ButtonProps={{ size: "large" }}
          options={[
            {
              label: t("settings.select_metric_system_label"),
              "aria-label": t("settings.select_metric_system_aria_label"),
              value: false,
            },
            {
              label: t("settings.select_imperial_system_label"),
              "aria-label": t("settings.select_imperial_system_aria_label"),
              value: true,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default SettingsMeasurementSystem;
