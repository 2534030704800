import { Link } from "react-router-dom";
import Constants from "../../config/Constants";
import addReportIcon from "../../assets/images/add-report-icon.svg";
import addAthleteIcon from "../../assets/images/add-athlete-icon.svg";
import addGroupIcon from "../../assets/images/add-group-icon.svg";
import { FABImgIconStyled, FABWorkoutIconStyled } from "./useQuickCreateActions.components";
import useCanDo from "hooks/useCanDo";
import { useTranslation } from "react-i18next";

const withLink = (to, children) => <Link to={to}>{children}</Link>;

function useSpeedDialActions() {
  const canDo = useCanDo();
  const { t } = useTranslation();

  return [
    {
      icon: withLink(
        Constants.routes.athletesAdd.route,
        <FABImgIconStyled src={addAthleteIcon} alt={t("global.add_athlete_icon_alt")} />
      ),
      name: t("global.athlete_label"),
    },
    canDo("group.any") && {
      icon: withLink(
        Constants.routes.groupsAdd.route,
        <FABImgIconStyled src={addGroupIcon} alt={t("global.add_group_icon_alt")} />
      ),
      name: t("global.group_label"),
    },
    canDo("report.any") && {
      icon: withLink(
        Constants.routes.createReport.route,
        <FABImgIconStyled src={addReportIcon} alt={t("global.add_report_icon_alt")} />
      ),
      name: t("global.report_label"),
    },
    {
      icon: withLink("/workouts/create", <FABWorkoutIconStyled alt={t("global.add_workout_icon_alt")} />),
      name: t("global.workout_label"),
    },
  ].filter(Boolean);
}
export default useSpeedDialActions;
