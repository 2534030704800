import * as Components from "./GroupsTableHeader.components";
import Grid from "@mui/material/Grid";
import { columnLabels, tableCells } from "../GroupsTable.config";
import SortIcon from "components/Icons/SortIcon";
import { useCallback } from "react";
import { grey } from "@mui/material/colors";
import { Group } from "contexts/GroupsContext/GroupsContext";
import { useTranslation } from "react-i18next";

export interface GroupsTableHeaderProps {
  sortBy: { key: keyof Group; direction: "desc" | "asc" };
  onSort: React.Dispatch<
    React.SetStateAction<{
      key: keyof Group;
      direction: "desc" | "asc";
    }>
  >;
  groups: Group[];
}

function GroupsTableHeader(props: GroupsTableHeaderProps) {
  const { onSort } = props;
  const { t } = useTranslation();

  const currySort = useCallback(
    (key: keyof Group) => () => {
      onSort((prev) => ({
        ...prev,
        key,
        direction: prev.key === key ? (prev.direction === "asc" ? "desc" : "asc") : prev.direction,
      }));
    },
    [onSort]
  );

  return (
    <Grid
      container
      component={Components.TableHeader}
      alignItems="center"
      minHeight={48}
      fontSize={14} // controls all header font sizings
      pr="5px"
    >
      <Grid item {...tableCells.checkbox} />
      <Grid item {...tableCells.name} lineHeight={0}>
        <Components.SortButton
          variant="text"
          size="small"
          color="inherit"
          onClick={currySort("name")}
          fullWidth={false}
          showSort={props.sortBy.key === "name"}
          endIcon={
            <SortIcon
              htmlColor={props.sortBy.key === "name" ? "currentColor" : grey[500]}
              direction={props.sortBy.direction}
            />
          }
        >
          {t(columnLabels.name)}
        </Components.SortButton>
      </Grid>
      <Grid {...tableCells.actions} />
    </Grid>
  );
}

export default GroupsTableHeader;
