import OneRepMaxTooltipCardActions from "./OneRepMaxTooltipCardActions";
import { LinePointData } from "components/charting/types/Chart.types";
import { TooltipColorStrip, TooltipDataRow, TooltipPaper } from "components/TooltipCard/TooltipCard.components";
import { OneRepMaxValue } from "types";
import Divider from "@mui/material/Divider";
import useColorRegistry from "features/Charting/hooks/useColorRegistry";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import moment from "moment";
import useAthlete from "hooks/useAthlete";
import AthleteName from "components/AthleteName";
import { useExercise, useUnitFormatter } from "hooks";
import { useTranslation } from "react-i18next";

function OneRepMaxTooltipCard(props: LinePointData) {
  const athleteId = props.source.id;
  const measurement = props.measurement as OneRepMaxValue;
  const entityColor = useColorRegistry()(props.entityId);
  const athlete = useAthlete(athleteId);
  const exercise = useExercise(measurement.exerciseId);
  const weightMetric = exercise?.metrics.find((metric) => metric.field === "weight");
  const letter = props.variant?.charAt(0);
  const unitFormatter = useUnitFormatter();
  const { t } = useTranslation();

  return (
    <TooltipPaper>
      <TooltipColorStrip entityColor={entityColor} letter={letter} />
      <Divider />
      <TooltipDataRow>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <AthleteName athlete={athlete} id={athleteId} avatarColor={entityColor} />
          </Grid>
          <Grid item>
            <Typography gutterBottom={false}>{moment(measurement.createdDate).format("DD MMM YYYY")}</Typography>
          </Grid>
        </Grid>
      </TooltipDataRow>
      <Divider />
      <TooltipDataRow>
        <Grid container alignItems="center" columns={12}>
          <Grid item xs={3} key={measurement.id}>
            <Box p={0.5} bgcolor={alpha(entityColor, 0.1)} borderRadius={1}>
              <Typography align="center" style={{ lineHeight: 1.2 }}>
                <Box component="span" color="grey.800" fontWeight={400} fontSize={12}>
                  {t("global.one_rep_max_abbr_label")}
                </Box>
              </Typography>
              <Typography variant="h4" title={`${measurement.weight}`} align="center">
                {unitFormatter(measurement.weight, weightMetric)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TooltipDataRow>
      <Divider />
      <TooltipDataRow>
        <OneRepMaxTooltipCardActions athleteId={athleteId} oneRepMaxId={measurement.id} />
      </TooltipDataRow>
    </TooltipPaper>
  );
}

export default OneRepMaxTooltipCard;
