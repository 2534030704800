import InfoCard from "components/InfoCard";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Mui5 from "components/mui5";
import LeaderboardIcon from "components/Icons/LeaderboardsIcon";
import FullScreenIcon from "components/Icons/FullScreenIcon";
import SettingsIcon from "components/Icons/SettingsIcon";
import TrashIcon2 from "components/Icons/TrashIcon2";
import { useTranslation } from "react-i18next";
import InfoCardControlDescriptor from "components/InfoCard/InfoCardControlDescriptor";

interface LeaderboardsInfoCardProps {
  onClose?(): void;
}

function LeaderboardsInfoCard(props: LeaderboardsInfoCardProps) {
  const { t } = useTranslation();

  return (
    <InfoCard title={t("leaderboard.info_card_title")} icon={<LeaderboardIcon />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0}>
          <Typography gutterBottom variant="h4">
            {t("leaderboard.info_card_description_p1")}
          </Typography>
          <Typography gutterBottom variant="h4">
            {t("leaderboard.info_card_description_p2")}
          </Typography>
          <Typography gutterBottom variant="h4">
            {t("leaderboard.info_card_description_p3")}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center" p={2}>
          <Mui5.Button
            fullWidth={false}
            sx={{ width: 320 }}
            size="medium"
            color="primary"
            LinkComponent="a"
            // @ts-ignore
            rel="noopener noreferrer"
            // @ts-ignore
            target="_blank"
            href="https://25970650.hs-sites-eu1.com/knowledge/how-to-create-leaderboards-on-hub"
          >
            {t("global.more_info_button_text")}
          </Mui5.Button>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("global.fullscreen_label")}
            control={<FullScreenIcon />}
            label={t("leaderboard.info_card_controls_fullscreen_label")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("global.configuration_label")}
            control={<SettingsIcon />}
            label={t("leaderboard.info_card_controls_config_label")}
          />
        </Grid>
        <Grid item xs={12} pb={0}>
          <InfoCardControlDescriptor
            title={t("global.delete_button_text")}
            control={<TrashIcon2 />}
            label={t("leaderboard.info_card_controls_delete_label")}
          />
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default LeaderboardsInfoCard;
