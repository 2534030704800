import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InfoCard from "components/InfoCard";
import InfoCardControlDescriptor from "components/InfoCard/InfoCardControlDescriptor";
import Tables from "components/Tables";
import GroupIcon from "components/Icons/GroupsIcon";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";
import NotePadIcon from "components/IconsStandardized/NotePadIcon/NotePadIcon";
import RagStatusIndicator from "components/RagStatusIndicator";

export interface GroupOverviewInfoCardProps {
  onClose(): void;
}

function GroupOverviewInfoCard(props: GroupOverviewInfoCardProps) {
  const { t } = useTranslation();

  return (
    <InfoCard title={t("group.overview.info_card_header_text")} icon={<GroupIcon />} onClose={props.onClose}>
      <Grid container>
        <Grid item xs={12} pb={0.5}>
          <InfoCardControlDescriptor
            title={t("group.overview.info_card_title1")}
            control={<NotePadIcon fontSize="small" />}
            label={t("group.overview.info_card_label1")}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoCardControlDescriptor
            title={t("group.overview.info_card_title2")}
            control={
              <Mui5.Button size="small" sx={{ pointerEvents: "none" }}>
                {t("group.overview.info_card_chart_group_button_text")}
              </Mui5.Button>
            }
            label={t("group.overview.info_card_label2")}
          />
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <InfoCardControlDescriptor
            title={t("group.overview.info_card_title3")}
            control={
              <>
                <Tables.Icons.Filter />
                <Box pr={1} display="inline-block" />
                <Tables.Icons.Sort />
              </>
            }
            label={t("group.overview.info_card_label3")}
          />
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <InfoCardControlDescriptor
            title={t("group.overview.info_card_title4")}
            control={
              <>
                <Tables.Icons.OneRepMax />
                <Box pr={1} display="inline-block" />
                <Tables.Icons.AssignGroups />
              </>
            }
            label={t("group.overview.info_card_label4")}
          />
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <InfoCardControlDescriptor
            title={t("group.overview.info_card_title5")}
            control={<RagStatusIndicator values={[{ variant: null, progressArrow: 1 }]} />}
            label={t("group.overview.info_card_label5")}
          />
        </Grid>
        <Grid item xs={12} pb={0.5}>
          <InfoCardControlDescriptor
            title={t("group.overview.info_card_title6")}
            control={
              <>
                <Tables.Icons.Delete />
                <Box pr={1} display="inline-block" />
                <Tables.Icons.Archive />
              </>
            }
            label={t("group.overview.info_card_label6")}
          />
        </Grid>
      </Grid>
    </InfoCard>
  );
}

export default GroupOverviewInfoCard;
