import Grid from "@mui/material/Grid";
import Tables from "components/Tables";
import { columnLabels, tableCells } from "../CompletedWorkoutsTable.config";
import { useTranslation } from "react-i18next";

interface CompletedWorkoutsTableHeaderProps {}

function CompletedWorkoutsTableHeader(props: CompletedWorkoutsTableHeaderProps) {
  const { t } = useTranslation();

  return (
    <Grid {...Tables.headerContainerProps} flexWrap="nowrap">
      <Grid item {...tableCells.name}>
        {t(columnLabels.name)}
      </Grid>
      <Grid item {...tableCells.startTime} minWidth={0}>
        {t(columnLabels.startTime)}
      </Grid>
      <Grid item {...tableCells.rpe}>
        {t(columnLabels.rpe)}
      </Grid>
    </Grid>
  );
}

export default CompletedWorkoutsTableHeader;
