import { useCallback } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import Grid from "@mui/material/Grid";
import Mui5 from "components/mui5";
import { schema } from "./SettingsAccountPasswordChange.schema";
import UserService from "services/UserService";
import { useInfoMessage, useLoadingContext } from "hooks";
import useRequest from "hooks/useRequest";
import useTogglePasswordInputType from "hooks/useTogglePasswordInputType";
import eyeIconDark from "assets/images/eye-icon-dark.svg";
import CircularProgress from "@mui/material/CircularProgress";
import FormText from "components/Inputs/Form/FormText";
import useReauthentication from "components/ReauthenticationProvider/useReauthentication";
import { useTranslation } from "react-i18next";

function SettingsAccountPasswordChange() {
  const updatePassword = useRequest(UserService.updatePassword, true);
  const { type, toggleType } = useTogglePasswordInputType();
  const onInfoMessage = useInfoMessage({ type: "success" });
  const [loading] = useLoadingContext();
  const reauthActions = useReauthentication();
  const { t } = useTranslation();

  const onUpdate = useCallback(
    async ({ newPassword }: { newPassword: string }) => {
      try {
        await updatePassword({ newPassword });
        onInfoMessage({ message: t("info.password_change_success") });
      } catch (e) {
        console.error(e);
      }
    },
    [onInfoMessage, updatePassword, t]
  );

  const handleUpdate = useCallback(
    (values: { newPassword: string }, formikHelpers: FormikHelpers<any>) => {
      reauthActions.onStart({
        onSuccess: async () => {
          await onUpdate(values);
          formikHelpers.resetForm();
          formikHelpers.setErrors({});
          formikHelpers.setTouched({});
          formikHelpers.setSubmitting(false);
        },
        onCancel: () => {
          formikHelpers.setSubmitting(false);
        },
      });
    },
    [onUpdate, reauthActions]
  );

  const endAdornment = loading ? (
    <CircularProgress color="secondary" size={24} />
  ) : (
    <img src={eyeIconDark} onClick={toggleType} alt={t("components.show_password_alt_text")} />
  );

  return (
    <Formik
      initialValues={{ newPassword: "", confirmNewPassword: "" }}
      enableReinitialize
      onSubmit={handleUpdate}
      validationSchema={schema}
    >
      {({ dirty, isSubmitting }) => (
        <Form>
          <Grid container spacing="24px" maxWidth={400}>
            <Grid item xs={12}>
              <FormText
                name="newPassword"
                type={type}
                label={t("settings.account_new_password")}
                id="user-new-password"
                InputProps={{ endAdornment }}
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormText
                name="confirmNewPassword"
                type={type}
                label={t("settings.account_confirm_password")}
                id="user-confirm-new-password"
                InputProps={{ endAdornment }}
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Mui5.Button
                disabled={!dirty || isSubmitting}
                color="primary"
                fullWidth
                variant="contained"
                type="submit"
                aria-label={t("settings.account_save_new_password_aria_label")}
                sx={{ py: 2 }}
              >
                {t("global.save_changes")}
              </Mui5.Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default SettingsAccountPasswordChange;
