import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CalendarIcon from "components/Icons/CalendarIcon";
import { useRef, useState } from "react";
import moment from "moment";
import Mui5 from "components/mui5";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import * as Components from "./DateNavigator.components";
import DateTimePickers from "components/mui5/DateTimePickers";
import { useTranslation } from "react-i18next";

export interface DateNavigatorProps<T = moment.Moment | null> {
  onChange(value: T): void;
  value: T;
  highlightedDays: {
    [date: string]: object | undefined;
  };
  disabled?: boolean;
  onMonthChange?(date: Exclude<T, null>): void;
  onOpen?(): void;
  onPreviousDayClick?: () => void;
  onNextDayClick?: () => void;
}

function DateNavigator(props: DateNavigatorProps) {
  const calendarIconRef = useRef<HTMLDivElement>(null);
  const {
    value,
    onChange,
    disabled = false,
    highlightedDays,
    onMonthChange,
    onOpen,
    onPreviousDayClick,
    onNextDayClick,
  } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const toggleOpen = () => {
    onOpen && onOpen();
    setOpen((prev) => !prev);
  };
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Grid container wrap="nowrap">
        <Grid item>
          <IconButton
            color="primary"
            size="small"
            onClick={onPreviousDayClick}
            disabled={disabled || !onPreviousDayClick}
            aria-label={t("global.go_to_previous_day")}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
        <Grid container item xs>
          <Mui5.Button
            fullWidth
            size="small"
            variant="text"
            onClick={toggleOpen}
            disabled={disabled}
            aria-label={t("global.open_calendar_picker")}
          >
            <Box component="span" fontSize="h4.fontSize" lineHeight={1}>
              {value ? moment(value).format("DD MMMM YYYY") : t("global.select_date_label")}
            </Box>
          </Mui5.Button>
        </Grid>
        <Grid item>
          <IconButton
            color="primary"
            size="small"
            onClick={onNextDayClick}
            aria-label={t("global.go_to_next_day")}
            disabled={disabled || !onNextDayClick}
          >
            <ChevronRightIcon />
          </IconButton>
        </Grid>
        <Grid ref={calendarIconRef} item>
          <Box pl={0.5}>
            <IconButton
              color="primary"
              size="small"
              onClick={toggleOpen}
              aria-label={t("global.open_calendar_picker")}
              disabled={disabled}
            >
              <CalendarIcon />
            </IconButton>
            <Popper anchorEl={calendarIconRef.current} open={open} placement="top-end">
              <ClickAwayListener onClickAway={handleClose}>
                <Mui5.Paper>
                  <DateTimePickers.DateCalendar
                    value={value}
                    onChange={onChange}
                    onMonthChange={onMonthChange}
                    slots={{ day: Components.HighlightedDay }}
                    slotProps={{ day: { highlightedDays } as any }}
                    disableFuture
                  />
                </Mui5.Paper>
              </ClickAwayListener>
            </Popper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DateNavigator;
