import * as Yup from "yup";

export const errorMessages = {
  name: "group.all.form_name_error",
  colour: "group.all.form_colour_error",
};

export const groupSchema = Yup.object().shape({
  name: Yup.string().min(1).required(errorMessages.name),
  colour: Yup.string().min(7).required(errorMessages.colour),
  athleteIds: Yup.array()
    .min(0)
    .transform((athletes) => athletes.map((athlete) => athlete.id)),
});

export default groupSchema;
