import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment-timezone";
import { useMemo, useState } from "react";
import usePreferences from "hooks/usePreferences";
import CircularProgress from "@mui/material/CircularProgress";
import Mui5 from "components/mui5";
import { useTranslation } from "react-i18next";

function SettingsTimeZone() {
  const [loading, setLoading] = useState(false);
  const [{ timeZone }, { updateTimeZone }] = usePreferences();
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      moment.tz.names().map((tzName) => {
        const now = moment().tz(tzName);
        const offset = now.format("Z");
        const abbrZone = now.format("z");
        return {
          label: `${offset} - ${tzName} (${abbrZone})`,
          value: tzName,
        };
      }),
    []
  );

  async function handleChange(_: any, timeZone: { label: string; value: string }) {
    setLoading(true);
    try {
      await updateTimeZone(timeZone.value);
    } catch (e) {}
    setLoading(false);
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">
          <Box component="span" fontWeight="fontWeightMedium">
            {t("settings.time_zone_page")}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Mui5.Autocomplete
          id="user-timeZone-input"
          options={options}
          style={{ width: 300 }}
          getOptionLabel={(opt: { label: string }) => opt.label}
          value={options.find((opt) => opt.value === timeZone)}
          onChange={handleChange}
          disableClearable
          renderInput={(params: any) => (
            <Mui5.TextField
              margin="normal"
              color="secondary"
              {...params}
              label={t("settings.time_zone_select_label")}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading && (
                      <CircularProgress
                        aria-label={t("global.loading_indicator_aria_label")}
                        color="secondary"
                        size={24}
                      />
                    )}
                    {params.InputProps?.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default SettingsTimeZone;
